import { useLazyQuery } from '@apollo/client';
import { Download, PlayCircleSharp, RefreshOutlined, StopCircleSharp } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React, { useEffect, useMemo, useState } from 'react';
import { GET_CALL_POLLING_LOG, GET_CALL_RECORDING_BY_PHONE_NUMBER, GET_TWILIO_CALL_LOG } from '../../../graphql/queries/log';
import { fDateTime } from '../../../utils/formatTime';

export const CallPollingRecordRow = ({ row }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audio] = useState(new Audio(row?.callRecordingUrl));

    const handlePlay = () => {
        if (!isPlaying) {
            audio.play();
            setIsPlaying(true);
        }
    };

    const handleStop = () => {
        if (isPlaying) {
            audio.pause();
            setIsPlaying(false);
        }
    };

    return (
        <TableRow>
            <TableCell>
                <Typography variant='body2'>
                    {row.callSid}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2'>
                    {row.from}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2'>
                    {row.to}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2'>
                    {row?.direction?.startsWith('outbound') ? 'Outbound' : row?.direction}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2'>
                    {fDateTime(row.createdAt)}
                </Typography>
            </TableCell>
            <TableCell>
                
                <Typography variant='body2'  sx={{ textTransform: 'capitalize' }}>
                    {row.status}
                </Typography>
            </TableCell>
               <TableCell>
                      {row?.callRecordingUrl ? (
                         <>
                         {isPlaying ?
                             <IconButton onClick={handleStop}>
                                 <StopCircleSharp />
                             </IconButton>
                             :
                             <IconButton onClick={handlePlay}>
                                 <PlayCircleSharp />
                             </IconButton>
                         }
                         <a href={row?.callRecordingUrl} download>
                             <IconButton>
                                 <Download />
                             </IconButton>
                         </a>
                     </>
                      ) : (
                        <Typography variant="body2">No recording available</Typography>
                      )}
                    </TableCell>
        </TableRow>
    );
};

export default function CallPollingLogView() {

    const [state, setState] = React.useState({
        page: 0,
        limit: 100,
        skip: 0,
        search: "",
        filter: {},
        sort: {
            field: "_id",
            order: "desc",
        },
    });

    const [getTwilioCallLogs, { loading, data, error }] = useLazyQuery(GET_TWILIO_CALL_LOG);
   
    React.useEffect(() => {
        getTwilioCallLogs({
            variables: {
                limit: state.limit,
                skip: state.skip,
                // search: state.search,
                // filter: state.filter,
                // sort: state.sort,
            },
        });
    }
        , [state]);

    const {
        limit,
        skip,
        // search,
        // filter,
        // sort,
    } = state;

    const dataCount = useMemo(() => data?.getTwilioCallLogs?.length || 0, [data]);

    return (
        <Grid item container xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {`Page -${(skip / 100) + 1}`}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <TextField size='small' placeholder='Search' variant='outlined' />
                        <IconButton
                            disabled={(skip < 100) || loading}
                            onClick={() => {
                                setState({ ...state, skip: skip - 100 });
                            }}
                            aria-label="refresh">
                            <ArrowBackIosIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setState({ ...state, skip: skip + 100 });
                            }}
                            disabled={(dataCount < 100) || loading}
                            aria-label="refresh">
                            <ArrowForwardIosIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                getTwilioCallLogs({
                                    variables: {
                                        limit,
                                        skip
                                    },
                                });
                            }}
                            aria-label="refresh"
                        >
                            <RefreshOutlined />
                        </IconButton>
                        {loading && <CircularProgress />}
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Conversation Sid</TableCell>
                                <TableCell>From</TableCell>
                                <TableCell>To</TableCell>
                                <TableCell>Direction</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Recording</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {data?.getTwilioCallLogs?.map((row, index) => (
                                <CallPollingRecordRow key={index} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}