// components
import { Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
// sections
import CompaniesTable from '../company/CompaniesTable';

function SequenceCompanies({ leads, totalLeads, onLoadMore, loading, page, setPage }) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Divider/>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CompaniesTable leads={leads} onLoadMore={onLoadMore} loading={loading} page={page} setPage={setPage} totalCount={totalLeads}/>
        </Grid>
      </Grid>
    </>
  );
}

SequenceCompanies.propTypes = {
  leads: PropTypes.array,
};

export default SequenceCompanies;
