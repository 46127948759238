import merge from 'lodash/merge';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, TextField, CircularProgress } from '@mui/material';
// components
import { BaseOptionChart } from '../../../components/call-analytics/chart';
import useCallConnectionStats from '../../../hooks/call-analytics/use-call-connection-stats';

export default function AnalyticsMostConnections() {
  const [range, setRange] = useState('daily');
  const [chartData, setChartData] = useState({
    labels: [],
    connectedCalls: [],
    voicemailCalls: [],
  });

  const { data: connectedCallsData, loading: connectedCallsLoading } = useCallConnectionStats(range);

  useEffect(() => {
    if (!connectedCallsLoading && connectedCallsData) {
      const stats = connectedCallsData.stats;
      const labels = stats.map((stat) => stat.label);
      const connectedCalls = stats.map((stat) => stat.connectedCalls);
      const voicemailCalls = stats.map((stat) => stat.voicemailCalls);

      setChartData({
        labels,
        connectedCalls,
        voicemailCalls,
      });
    }
  }, [connectedCallsData, connectedCallsLoading]);

  const handleChangeRange = (event) => {
    setRange(event.target.value);
  };

  const CHART_DATA = [
    {
      name: 'Connected Calls',
      type: 'column',
      data: chartData.connectedCalls,
    },
    {
      name: 'VoiceMail Calls',
      type: 'line',
      data: chartData.voicemailCalls,
    },
  ];

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%', borderRadius: 4 } },
    fill: { type: CHART_DATA.map((i) => i.fill) },
    labels: chartData.labels,
    xaxis: { type: 'string' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} calls`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader
        title="Most Connections"
        subheader=""
        action={
          <TextField
            select
            fullWidth
            value={range}
            SelectProps={{ native: true }}
            onChange={handleChangeRange}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': {
                pl: 1,
                py: 0.5,
                pr: '24px !important',
                typography: 'subtitle2',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: 0.75,
                bgcolor: 'background.neutral',
              },
              '& .MuiNativeSelect-icon': {
                top: 4,
                right: 0,
                width: 20,
                height: 20,
              },
            }}
          >
            <option value="daily">Daily</option>
            <option value="prevDay">Previous Day</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </TextField>
        }
      />
      <Box sx={{ p: 3, pb: 1, position: 'relative' }} dir="ltr">
        {connectedCallsLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
