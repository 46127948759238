import { gql } from '@apollo/client';
import { USER } from '../fragments/user';

export const UPDATE_PROFILE = gql`
  mutation updateUserProfile($input: UpdateUserInput!, $userThumbnail: Upload!) {
    updateUserProfile(input: $input, userThumbnail: $userThumbnail) {
      _id
      thumbnail
    }
  }
`;

export const UPDATE_PROFILE_NO_FILE = gql`
  ${USER}
    mutation updateUserProfileWithoutImage($input: UpdateUserInput!) {
      updateUserProfileWithoutImage(input: $input) {
        ...UserFields
      }
    }
`;

export const CHANGE_PASSWORD = gql`
  mutation changeUserPassword($input: ChangePasswordInput!) {
    changeUserPassword(input: $input) {
      username
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(userId: $userId) {
      _id
    }
  }
`;
