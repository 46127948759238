import { useMutation } from '@apollo/client';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Paper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { useMemo, useState } from 'react';
import { CREATE_OR_UPDATE_LEAD_MULTIPLE } from '../../../graphql/mutations/lead';
import Step1 from './import/Step1';
import Step2 from './import/Step2';
import Step3 from './import/Step3';

const steps = ['Upload file and setup how you want to import', 'Map columns to properties', 'Complete import'];

export default function CompanyImportStepper() {
  const [createLeadMultiple, {loading, error}] = useMutation(CREATE_OR_UPDATE_LEAD_MULTIPLE);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [agreeToSelectedColumns, setAgreeToSelectedColumns] = useState(false);
  const [columnKeys, setColumnKeys] = useState({});
  const totalSteps = useMemo(() => steps.length, []);
  const completedSteps = useMemo(() => Object.keys(completed).length, [completed]);
  const isLastStep = useMemo(() => activeStep === totalSteps - 1, [activeStep, totalSteps]);
  const allStepsCompleted = useMemo(() => completedSteps === totalSteps, [completedSteps, totalSteps]);
  const convertToCamelCase = (str) =>
    str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
      .replace(/[^a-zA-Z0-9]/g, '');

  const handleNext = () => {
    if (isLastStep) {
       processDataForImport();
    } else {
      const newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const markAllStepsAsCompleted = () => {
    const newCompleted = {};
    steps.forEach((step, index) => {
      newCompleted[index] = true;
    });
    setCompleted(newCompleted);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const processDataForImport = () => {
    markAllStepsAsCompleted();
    const data = csvData?.slice(1);
    const dataForSelectedColumns = data.map(
      (row) => {
        const obj = {};
        columns.forEach((column, index) => {
          if (columnKeys[column] && columnKeys[column] !== '') {
            const cusKey = convertToCamelCase(columnKeys[column]);
            obj[cusKey] = row[index];
          }
        });
        return obj;
      },
      [data, columns, columnKeys]
    );
    const chunkSize = 10;
    const chunkedData = [];
    for (let i = 0; i < dataForSelectedColumns.length; i += chunkSize) {
      chunkedData.push(dataForSelectedColumns.slice(i, i + chunkSize));
    }
    chunkedData.forEach((chunk) => {
      createLeadMultiple({variables: {input: chunk}});
    });
  };

  const stepperComponent = useMemo(() => {
    switch (activeStep) {
      case 0:
        return <Step1 setColumns={setColumns} csvData={csvData} setCsvData={setCsvData} />;
      case 1:
        return (
          <Step2
            columns={columns}
            setColumnKeys={setColumnKeys}
            agreeToSelectedColumns={agreeToSelectedColumns}
            setAgreeToSelectedColumns={setAgreeToSelectedColumns}
          />
        );
      case 2:
        return <Step3 columns={columns} columnKeys={columnKeys} csvData={csvData} />;
      default:
        return null;
    }
  }, [activeStep, columns, columnKeys, csvData, agreeToSelectedColumns]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted ? (
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column',justifyContent:'center', alignItems:'center', pt: 2 }}>
              {loading? <Typography variant="h6" color="primary">Importing...</Typography>: error? <Typography color='error'>Data import failed! kindly check the uploaded csv upload and try again. </Typography> : <Typography variant="h6" color="primary">You have successfully imported contacts to your workflows.</Typography>}
              <img src="/assets/illustrations/illustrations_completed_imports.svg" width={300} alt="success" />
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </Stack>
        ) : (
          <>
            {stepperComponent}
            <Paper
              sx={{
                backgroundColor: 'background.paper',
                position: 'fixed',
                borderTop: '1px solid rgba(145, 158, 171, 0.24)',
                bottom: 0,
                left: 0,
                right: 0,
                height: 100,
              }}
              elevation={1}
            >
              <Box
                sx={{
                  m: {
                    md: 2,
                    sm: 0,
                  },
                  mt: 2,
                  mx: {
                    md: 4,
                    sm: 0,
                  },
                  p: {
                    md: 2,
                    sm: 2,
                  },
                  display: 'flex',
                  marginLeft: {
                    md: 40,
                    sm: 0,
                  },
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  color="inherit"
                  variant="outlined"
                  startIcon={<ArrowBack />}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </Button>
                <Stack direction="row" spacing={2} alignItems="center">
                  {activeStep === 1 && (
                    <Typography variant="body2">
                      <b>Note: Unmapped columns will not be imported, Scroll to see all and agree</b>
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    disabled={!csvData.length || (activeStep === 1 && !agreeToSelectedColumns)}
                    endIcon={<ArrowForward />}
                    onClick={handleNext}
                    sx={{ mr: 1 }}
                  >
                    {isLastStep ? 'Finish' : 'Next'}
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </>
        )}
      </div>
    </Box>
  );
}
