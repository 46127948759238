import { createSlice } from '@reduxjs/toolkit';
import { getTodayUTC } from '../utils/helperFunctions';

const { startDate, endDate } = getTodayUTC();

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    activeDateSelection: 'today',
    dateInterval: { from: startDate, to: endDate },
  },
  reducers: {
    setDateInterval: (state, action) => {
      state.dateInterval = action.payload;
    },
    setActiveDateSelection: (state, action) => {
      state.activeDateSelection = action.payload;
    },
  },
});

export const analyticsReducer = analyticsSlice.reducer;

export const { setDateInterval, setActiveDateSelection } = analyticsSlice.actions;
