import { Stack, Typography, Link, IconButton, Tooltip, Button } from '@mui/material';
import { CallOutlined, EmailRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { fToNow } from '../../utils/formatTime';
import { INTERRACTION_TYPES } from '../../utils/constants';

function CompanyContactCard({
  activeLead,
  callLogsData,
  emailLogsData,
  handleCreateOrUpdateDefaultContact,
  activeCompanyDefaultContact,
  setInteractionTarget,
  setInteractionType,
}) {
  const lastContactedViaEmailOrPhone = useMemo(() => {

    if (!activeLead) {
      return null;
    }

    const lastContactedViaEmail = emailLogsData?.find((emailLog) => emailLog?.email === activeLead?.companyEmail);

    const lastContactedViaPhone = callLogsData?.find(
      (callLog) => callLog?.phoneNumber === activeLead?.companyPhoneNumber
    );

    if (lastContactedViaEmail?.emailSent === 'Yes' && !lastContactedViaPhone) {
      return lastContactedViaEmail;
    }

    if (lastContactedViaPhone?.callDone === 'Yes' && !lastContactedViaEmail) {
      return lastContactedViaPhone;
    }

    if (lastContactedViaEmail?.emailSent === 'Yes' && lastContactedViaPhone?.callDone === 'Yes') {
      return lastContactedViaEmail?.createdAt > lastContactedViaPhone?.createdAt
        ? lastContactedViaEmail
        : lastContactedViaPhone;
    }

    if (lastContactedViaEmail?.emailSent === 'Yes' && lastContactedViaPhone?.callDone === 'No') {
      return lastContactedViaEmail;
    }

    if (lastContactedViaEmail?.emailSent === 'No' && lastContactedViaPhone?.callDone === 'Yes') {
      return lastContactedViaPhone;
    }

    return null;
  }, [callLogsData, emailLogsData, activeLead]);

  const initConv = () =>{
    setInteractionTarget({
      name: activeLead?.simplifiedCompanyName,
      email: activeLead?.companyEmail,
      phone: activeLead?.companyPhoneNumber,
      linkedin: activeLead?.companyLinkedinProfile,
    });
  }

  const handleSendSms = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.TEXT);
  };

  const handlePhoneCall = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.CALL);
  };

  const handleSendEmail = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.EMAIL);
  };

  const handleSendLinkedinMessage = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.LINKEDINMESSAGE);

  };
   

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <Stack direction="column" spacing={1}>
        <Typography>{activeLead?.simplifiedCompanyName} (Company)</Typography>
        <Typography variant="caption">
          {lastContactedViaEmailOrPhone
            ? `Last contacted ${fToNow(lastContactedViaEmailOrPhone?.createdAt)}`
            : 'Not contacted'}
        </Typography>
        {activeCompanyDefaultContact &&
        (activeCompanyDefaultContact?.phone === activeLead?.companyPhoneNumber ||
          activeCompanyDefaultContact?.email === activeLead?.companyEmail) ? (
          <Typography>Default</Typography>
        ) : (
          <Button
            startIcon={<img src="/assets/icons/ic_make_default.svg" width={20} alt="plus" />}
            color='inherit'
            size='small'
            sx={{width: 'max-content'}}
            onClick={(e) => {
              e.preventDefault();
              handleCreateOrUpdateDefaultContact(
                activeLead?.simplifiedCompanyName,
                activeLead?.companyEmail,
                activeLead?.companyPhoneNumber,
                "Company"
              );
            }}
          >
          Make default
          </Button>
        )}
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        {!activeLead?.companyPhoneNumber ? (
          <Tooltip title="The Lead does not have a phone number" arrow>
            <IconButton
              sx={{ border: '1px solid rgba(8, 7, 8, 0.20)', borderRadius: '20%' }}
            >
              <CallOutlined />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            sx={{ border: '1px solid rgba(8, 7, 8, 0.20)', borderRadius: '20%' }}
            onClick={handlePhoneCall}
          >
            <CallOutlined />
          </IconButton>
        )}

        {!activeLead?.companyEmail ? (
          <Tooltip title="The Lead does not have an email" arrow>
            <IconButton
              sx={{ border: '1px solid rgba(8, 7, 8, 0.20)', borderRadius: '20%' }}
            >
              <EmailRounded />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            sx={{ border: '1px solid rgba(8, 7, 8, 0.20)', borderRadius: '20%' }}
            disabled={!activeLead?.companyEmail}
            onClick={handleSendEmail}
          >
            <EmailRounded />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}

CompanyContactCard.propTypes = {
  activeLead: PropTypes.shape({
    simplifiedCompanyName: PropTypes.string,
    companyPhoneNumber: PropTypes.string,
    companyEmail: PropTypes.string,
  }),
  handleCreateOrUpdateDefaultContact: PropTypes.func,
  setInteractionTarget: PropTypes.func,
  setInteractionType: PropTypes.func,
  activeCompanyDefaultContact: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    _id: PropTypes.string,
  }),
};

export default CompanyContactCard;
