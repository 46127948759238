import { useLazyQuery } from '@apollo/client';
import { ExpandMoreOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { GET_PAGINATED_CALL_LOGS } from '../../../graphql/queries/log';
import { fDate, fDateTime } from '../../../utils/formatTime';

export default function SequenceCallLogs() {
  const limit = 100;
  const [skip, setSkip] = useState(0);
  const [getCallLogsPaginate, { data: paginatedData, loading }] = useLazyQuery(GET_PAGINATED_CALL_LOGS);
  useEffect(() => {
    getCallLogsPaginate({ variables: { input: {limit, skip} } });
  }, [getCallLogsPaginate, limit, skip]);

  const dataCount = useMemo(() => paginatedData?.getCallLogsPaginate?.length || 0, [paginatedData?.getCallLogsPaginate]);
  const groupedCallData = useMemo(() => {
    const groupedData = {};
    paginatedData?.getCallLogsPaginate?.forEach((item) => {
      const createdAtDate = item.createdAt.split('T')[0]; // Extract the date part
      if (!groupedData[createdAtDate]) {
        groupedData[createdAtDate] = [item];
      } else {
        groupedData[createdAtDate].push(item);
      }
    });

    return groupedData;
  }, [paginatedData?.getCallLogsPaginate]);

  return (
    <Card>
      <CardContent>
        <Stack direction="column" alignItems="flex-start" spacing={1}>
          <Typography variant="h5">Call Logs Details</Typography>
          <Typography variant="caption">All the calls made through out this workflow</Typography>
        </Stack>
        <Divider sx={{ my: 2 }} />

        <Stack direction="column" spacing={2}>
          {loading && <CircularProgress />}
          {Object.keys(groupedCallData).map((stage) => (
              <Fragment key={stage}>
                <Divider>{fDate(stage)}</Divider>
                {groupedCallData[stage]?.map((log) => (
                  <Accordion key={log._id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreOutlined />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <Typography>
                          {`Connected by ${log?.user?.firstName} ${log?.user?.lastName} to ${log?.name} on `}
                        </Typography>
                        <Link href="#">{log?.phoneNumber}</Link>
                        <Typography>{`at ${fDateTime(log?.createdAt)}`}</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="flex-start">
                        <Typography variant="body1">Name: {log?.name}</Typography>
                        <Typography variant="body1">Phone Number: {log?.phoneNumber}</Typography>
                        <Typography variant="body1">Status: {log?.callDone}</Typography>
                        <Typography variant="body1">Total Call Time: {log?.talkDone} sec</Typography>
                        <Typography variant="body1">Call ID: {log?.voicemailDone}</Typography>
                        {log?.recordingUrl && (
                          <audio controls>
                            <source src={log.recordingUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                        )}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Fragment>
            ))}
             <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
            <Typography variant="body1">
              Showing {dataCount} of Many{' '}
              entries
            </Typography>
            <Button
              disabled={(skip < 100) || loading}
              onClick={() => {
                setSkip(skip - 100);
              }}
            >
              Previous
            </Button>
            <Button
              onClick={() => {
                setSkip(skip + 100);
              }}
              disabled={(dataCount < 100) || loading}
            >
              Next
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
