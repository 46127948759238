
import { S3Client } from '@aws-sdk/client-s3';


export const s3 = new S3Client({
    region: 'us-east-1',
    credentials: {
        accessKeyId: 'AKIAZ4JCCD46IANDCM4Q',
        secretAccessKey: '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',
    },
});

export const generateUniqueFileName = (file) => {
    const timestamp = Date.now();
    const filename = `${timestamp}-${file.name?file.name:''}`;
    return filename;
};

export const createParams = (file) => {
    const fileName = generateUniqueFileName(file);
    return {
        Bucket: 'punch-sales-app',
        Key: fileName,
        Body: file,
        ContentType: file.type,
    };
};

export const filePath = (fileName) => `https://punch-sales-app.s3.amazonaws.com/${fileName}`;