// @mui
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
// sections
import { useLazyQuery, useMutation } from '@apollo/client';
import { Edit, PlusOne } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import Iconify from '../../../components/iconify/Iconify';
import { DELETE_TRAINING, DELETE_TRAINING_MULTIPLE, UPDATE_ORGANIZATION_AI_SETTINGS, UPDATE_TRAINING } from '../../../graphql/mutations/training';
import { GET_ORGANIZATION } from '../../../graphql/queries/organization';
import { GET_TRAINING_ANALYTICS, GET_TRAINING_MODEL_MULTIPLE, GET_TRAINING_PAGINATED, GET_TRAINING_SEARCH, GET_UPLOAD_TRAINING } from '../../../graphql/queries/training';
import TrainingCampRow from './TrainingCampRow';

export default function TrainingCamp({ value, handleEditChat }) {

    const [openPreContext, setOpenPreContext] = useState(false);
    const timeoutId = useRef(null);
    const [search, setSearch] = useState('');
    const [loadTrainingPagination, { data: trainingData, loading }] = useLazyQuery(GET_TRAINING_PAGINATED);
    const [searchModelTraining, { data: searchTrainingData, loading: searchTrainingLoading }] = useLazyQuery(GET_TRAINING_SEARCH);
    const [getOrganization, { loading: loadingOrganization, data: organization }] = useLazyQuery(GET_ORGANIZATION);
    const [trainingAnalytics, { data: trainingAnalyticsData, loading: trainingAnalyticsLoading }] = useLazyQuery(GET_TRAINING_ANALYTICS);
    const [loadTrainingMultiple, { data: trainingDataMultiple, loading: loadingTrainingMultiple }] = useLazyQuery(GET_TRAINING_MODEL_MULTIPLE);
    const [updateTrainingData, { data: trainingUploadResponse, loading: loadingUpdateTraining }] = useLazyQuery(GET_UPLOAD_TRAINING);
    const [updateModelTrainingMultiple, { loading: updateLoading, data: updatedTraining }] = useMutation(UPDATE_TRAINING);
    const [deleteModelTrainingMultiple, { loading: deleteLoading, data: deletedTrainingMultiple }] = useMutation(DELETE_TRAINING_MULTIPLE);
    const [deleteTraining, { loading: deleteTrainingLoading, data: deletedTraining }] = useMutation(DELETE_TRAINING);
    const [updateOrganizationTemperatureAndSystem, { data: organizationTemperatureAndSystem, loading: loadingOrganizationTemperatureAndSystem }] = useMutation(UPDATE_ORGANIZATION_AI_SETTINGS);
    const { enqueueSnackbar } = useSnackbar();
    const [pagination, setPagination] = useState({ limit: 100, skip: 0 });

    const [data, setData] = useState(
        {
            preContext: 'Marv is a factual chatbot that is also sarcastic.',
            temperature: 0.5,
            objectionAndResponse: []
        }
    );

    const [newRecords, setNewRecords] = useState([]);

    const handlePreContext = (e) => {
        setData({
            ...data,
            preContext: e.target.value
        });
    };

    const handleTemperature = (e) => {
        setData({
            ...data,
            temperature: e.target.value
        });
    };

    const handleEdit = (index, field, value) => {
        const newData = [...newRecords];
        newData[index][field] = value;
        setNewRecords(newData);
    };

    const handleAddRow = () => {
        const newRow = { _id: String(data.length + 1), objection: '', response: '' };
        setNewRecords([
            ...newRecords,
            newRow
        ])
    };

    const handleDeleteRow = (index) => {
        const newData = [...newRecords];
        newData.splice(index, 1);
        setNewRecords(newData);
    };

    const handleDeleteRowMultiple = (row) => {
        deleteModelTrainingMultiple({
            variables: {
                _id: row?._id
            }
        });
    };

    const handleDeleteTraining = useCallback((row) => {
        deleteTraining({
            variables: {
                _id: row?.id
            }
        });
    }, [deleteTraining]);

    useEffect(() => {
        if (search && search !== '') {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }

            timeoutId.current = setTimeout(() => {
                searchModelTraining({
                    variables: {
                        search
                    }
                });
            }, 1000);
        } else {
            loadTrainingPagination({
                variables: {
                    limit: pagination.limit,
                    skip: pagination.skip
                }
            });
        }

    }, [search, pagination, loadTrainingPagination, searchModelTraining]);

    useEffect(() => {
        if (organizationTemperatureAndSystem?.updateOrganizationTemperatureAndSystem) {
            enqueueSnackbar('AI settings updated successfully', { variant: 'success' });
            setData({
                ...data,
                temperature: organizationTemperatureAndSystem?.updateOrganizationTemperatureAndSystem?.temperature,
                preContext: organizationTemperatureAndSystem?.updateOrganizationTemperatureAndSystem?.system
            });
        }
    }, [organizationTemperatureAndSystem]);

    useEffect(() => {
        if (updatedTraining) {
            enqueueSnackbar('Training updated successfully', { variant: 'success' });
            setNewRecords([]);
            // loadTraining();
            loadTrainingPagination({
                variables: {
                    limit: pagination.limit,
                    skip: pagination.skip
                }
            });
        }
    }, [updatedTraining, loadTrainingPagination]);

    useEffect(() => {
        if (trainingUploadResponse) {
            enqueueSnackbar('Training uploaded successfully', { variant: 'success' });
        }
    }, [trainingUploadResponse]);

    useEffect(() => {
        // loadTraining();
        loadTrainingPagination({
            variables: {
                limit: pagination.limit,
                skip: pagination.skip
            }
        });
        loadTrainingMultiple();
        getOrganization();
        trainingAnalytics();

    }, [value, pagination]);

    useEffect(() => {
        if (deletedTrainingMultiple) {
            loadTrainingMultiple();
        }
    }, [deletedTrainingMultiple]);

    useEffect(() => {
        if (deletedTraining) {
            loadTrainingPagination({
                variables: {
                    limit: pagination.limit,
                    skip: pagination.skip
                }
            });
        }
    }, [deletedTraining, pagination]);

    useEffect(() => {
        if (organization) {
            setData(prevData => ({
                ...prevData,
                temperature: organization?.getOrganization?.temperature || 0.4,
                preContext: organization?.getOrganization?.system || ''
            }));
        }
    }, [organization]);

    useEffect(() => {
        if (trainingData?.getModelTrainingPagination) {
            setData({
                ...data,
                objectionAndResponse: trainingData?.getModelTrainingPagination?.map((item) => (
                    {
                        id: item._id,
                        _id: item._id,
                        preContext: item?.preContext,
                        objection: item?.objection,
                        response: item?.response
                    }))
            });
        }
    }, [trainingData?.getModelTrainingPagination]);

    useEffect(() => {
        if (searchTrainingData?.searchModelTraining) {
            setData({
                ...data,
                objectionAndResponse: searchTrainingData?.searchModelTraining?.map((item) => (
                    {
                        id: item._id,
                        _id: item._id,
                        preContext: item?.preContext,
                        objection: item?.objection,
                        response: item?.response
                    }))
            });
        }
    }, [searchTrainingData?.searchModelTraining]);


    const handleSubmit = () => {
        const dataToUpdate = newRecords?.map((item) => ({
            _id: typeof item.id === 'string' ? item.id : '',
            objection: item.objection,
            response: item.response,
            preContext: data?.preContext
        }));

        updateModelTrainingMultiple({
            variables: {
                input: dataToUpdate
            }
        });
    };

    const handleModelTraining = () => {
        if (data?.objectionAndResponse?.length < 10) {
            enqueueSnackbar('Please add at least 10 objection and response', { variant: 'error' });
        } else {
            updateTrainingData();
        }
    }

    const handleUpdateAiSettings = useCallback(() => {
        updateOrganizationTemperatureAndSystem({
            variables: {
                system: data?.preContext,
                temperature: parseFloat(data?.temperature)
            }
        });
    }, [data, updateOrganizationTemperatureAndSystem]);

    const truncateText = (text, length) => {
        if (text.length <= length) {
            return text;
        }
        return `${text.slice(0, length)}...`;
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" mt={2}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            Multi Training
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} alignItems="center">
                        <TextField
                            label="Search"
                            name='search'
                            size='small'
                            type='text'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder='Search.......'
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<img src="/assets/icons/navbar/ic_training.svg" alt="import leads" width={25} />}
                            onClick={handleModelTraining}
                            sx={{ textTransform: 'none' }}
                        >
                            {loadingUpdateTraining ? 'Loading...' : 'Train Model'}
                        </Button>
                        <Button
                            startIcon={<img src="/assets/icons/ic_leads.svg" alt="import leads" />}
                            onClick={() => setOpenPreContext(!openPreContext)}
                            sx={{
                                color: 'text.primary',
                                textTransform: 'none',
                                border: '1px solid rgba(145, 158, 171, 0.24)',
                            }}
                        >
                            {openPreContext ? "Close Pre-Context" : "Open Pre-Context"}
                        </Button>
                        <Box>
                            <IconButton>
                                <Iconify icon="bi:arrow-left" />
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton>
                                <Iconify icon="bi:arrow-right" />
                            </IconButton>
                        </Box>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Stack direction="column" spacing={0} alignItems="flex-start">
                    <Typography variant="caption">Today</Typography>
                    <Typography variant="h5">{trainingAnalyticsData?.getModelTrainingAnalytics?.today || 0}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Stack direction="column" spacing={0} alignItems="flex-start">
                    <Typography variant="caption">Week</Typography>
                    <Typography variant="h5">{trainingAnalyticsData?.getModelTrainingAnalytics?.week || 0}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Stack direction="column" spacing={0} alignItems="flex-start">
                    <Typography variant="caption">Month</Typography>
                    <Typography variant="h5">
                        {trainingAnalyticsData?.getModelTrainingAnalytics?.month || 0}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Divider />
            </Grid>
            {(loading || loadingTrainingMultiple || deleteLoading || deleteTrainingLoading || loadingOrganization || loadingOrganizationTemperatureAndSystem || trainingAnalyticsLoading || searchTrainingLoading) &&
                <Grid item xs={12} sm={12} md={12}>
                    <LinearProgress />
                </Grid>
            }
            <Grid item xs={12} sm={12} md={openPreContext ? 8 : 12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Pre-Context</TableCell>
                                <TableCell>Objection</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            color="primary"
                                            onClick={() => handleEditChat(null)}
                                            startIcon={<PlusOne />}
                                        >
                                            Add Row
                                        </Button>
                                    </Stack>

                                </TableCell>
                            </TableRow>
                            {trainingDataMultiple?.getModelTrainingMultiple?.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <Typography variant='body2'>
                                            {truncateText(row.preContext, 200)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='body2'>
                                            {row.objection[0]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditChat(row)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteRowMultiple(row)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={openPreContext ? 8 : 12}>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" mt={2}>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Mono Training
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box>
                            <IconButton
                                disabled={(pagination.skip < 100) || loading}
                                onClick={() => {
                                    setPagination({
                                        ...pagination,
                                        skip: pagination.skip - 100
                                    });
                                }}>
                                <Iconify icon="bi:arrow-left" />
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    setPagination({
                                        ...pagination,
                                        skip: pagination.skip + 100
                                    });
                                }}
                                disabled={(data.objectionAndResponse.length < 100) || loading}
                            >
                                <Iconify icon="bi:arrow-right" />
                            </IconButton>
                        </Box>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={openPreContext ? 8 : 12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Objection</TableCell>
                                <TableCell>Responses</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            color="primary"
                                            onClick={handleAddRow}
                                            startIcon={<PlusOne />}
                                        >
                                            Add Row
                                        </Button>
                                    </Stack>

                                </TableCell>
                            </TableRow>
                            {newRecords?.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <TextField
                                            value={row.objection}
                                            onChange={(e) => handleEdit(index, 'objection', e.target.value)}
                                            fullWidth
                                            multiline
                                            rows={3}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.response}
                                            onChange={(e) => handleEdit(index, 'response', e.target.value)}
                                            fullWidth
                                            multiline
                                            rows={3}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteRow(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {newRecords.length > 0 &&
                                (<TableRow>
                                    <TableCell colSpan={3}>
                                        <Stack direction="row" spacing={2}>
                                            <Button
                                                size='small'
                                                variant='outlined'
                                                color="primary"
                                                onClick={handleAddRow}
                                            >
                                                Delete All
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                                sx={{ textTransform: 'none' }}
                                                loading={updateLoading}
                                            >
                                                {(updateLoading) ? 'Saving...' : 'Save'}
                                            </Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>)}
                            {data?.objectionAndResponse?.map((row, index) => (
                                <TrainingCampRow key={row.id} row={row} handleDeleteTraining={handleDeleteTraining} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {openPreContext && (
                <Grid item xs={12} sm={12} md={4}>
                    <Stack direction="column" spacing={2} justifyContent="center" mt={2}>
                        <TextField multiline rows={12} value={data?.preContext} onChange={handlePreContext} name='precontext' placeholder='Pre Context' fullWidth />
                        <TextField type='number' value={data?.temperature} onChange={handleTemperature} name='temperature' placeholder='Temperature' fullWidth />
                        <Button variant="contained" color="primary" onClick={handleUpdateAiSettings} sx={{ textTransform: 'none' }}>
                            Save Changes
                        </Button>
                    </Stack>

                </Grid>
            )}
        </Grid>

    );
}
