import { sentenceCase } from 'change-case';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
// components
import { useMutation, useQuery } from '@apollo/client';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSnackbar } from 'notistack';
import Iconify from '../components/iconify';
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import Loader from '../components/loader';
import { DELETE_USER } from '../graphql/mutations/user';
import { GET_ALL_USERS } from '../graphql/queries/user';
import AddUserDialog from '../sections/@dashboard/user/AddUserDialog';
import DeleteConfirmationDialog from '../sections/@dashboard/user/DeleteConfirmationDialog';
import { LABELS } from '../utils/constants';
import { fDate } from '../utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'hubspotId', label: 'Contact Owner ID', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Created at', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user._id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const { data, loading } = useQuery(GET_ALL_USERS);

  const [deleteUser, { loading: deleteLoading, data: deletedUser }] = useMutation(DELETE_USER);

  const [userList, setUserList] = useState([]);

  const [openAddUser, setOpenAddUser] = useState(false);

  const handleClickOpenAddUser = () => {
    setOpenAddUser(true);
  };

  const handleClose = () => {
    setOpenAddUser(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const [openDelete, setOpenDelete] = useState(false);

  const [targetUser, setTargetUser] = useState(null);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('_id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleDeleteUserConfirm = () => {
    deleteUser({ variables: { userId: targetUser } });
    setOpenDelete(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  useEffect(() => {
    if (data) {
      setUserList(data.getAllUsers);
    }
  }, [data]);

  useEffect(() => {
    if (deletedUser?.deleteUser) {
      setUserList(userList.filter((user) => user._id !== deletedUser.deleteUser._id));
      enqueueSnackbar('User deleted successfully.', { variant: 'success' });
    }
  }, [deletedUser?.deleteUser, enqueueSnackbar]);

  const breadcrumbs = [
    <Typography key="1" variant="h6" color="inherit">
      User
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title> User | Auto Caller UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="h3">{LABELS.Users}</Typography>
                <Typography variant="caption">{LABELS.ManageUsers}</Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  variant="outlined"
                  startIcon={<img src="/assets/icons/ic_user.svg" alt="add" width="20" height="20" />}
                  sx={{borderColor: '#08070833', color: '#080708'}}
                  onClick={handleClickOpenAddUser}
                >
                 Add New User
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {(loading || deleteLoading) && <Loader />}
            {!loading && data && (
              <Card>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={userList.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          const {
                            _id,
                            firstName,
                            lastName,
                            hubSpotId,
                            email,
                            role,
                            status,
                            created_at: createdAt,
                          } = row;
                          const selectedUser = selected.indexOf(_id) !== -1;

                          return (
                            <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                              <TableCell padding="checkbox">
                                <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                              </TableCell>

                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {firstName} {lastName}
                                  </Typography>
                                </Stack>
                              </TableCell>

                              <TableCell align="left">{hubSpotId}</TableCell>

                              <TableCell align="left">{email}</TableCell>

                              <TableCell align="left">{role}</TableCell>

                              <TableCell align="left">
                                <Label color={(status === 'INACTIVE' && 'error') || 'success'}>
                                  {sentenceCase(status)}
                                </Label>
                              </TableCell>

                              <TableCell align="left">{fDate(createdAt)}</TableCell>

                              <TableCell align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(e) => {
                                    handleOpenMenu(e);
                                    setTargetUser(row._id);
                                  }}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={userList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDelete}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <AddUserDialog open={openAddUser} handleClose={handleClose} userList={userList} setUserList={setUserList} />
      <DeleteConfirmationDialog
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteUserConfirm={handleDeleteUserConfirm}
      />
    </>
  );
}
