import { gql } from '@apollo/client';

export const GET_CAMPAIGNS = gql`
    query {
        getCampaign{
            _id
            title
            type
            description
            start
            end
            step
            status
            callbackDuration
            callbackTimeOfDay
            attempts
            sleepInterval
            simultaneousCalls
            callbackDuration
            callbackTimeOfDay
            createdAt
        }
    }
`;