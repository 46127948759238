import { Helmet } from 'react-helmet-async';
// @mui
import {
  Stack,
  Button,
  Container,
  Typography,
} from '@mui/material';


import Iconify from '../components/iconify';


// ----------------------------------------------------------------------


export default function LeadPage() {

  return (
    <>
      <Helmet>
        <title> Lead | Auto Caller UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Lead
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Lead
          </Button>
        </Stack>
      </Container>
    </>
  );
}
