import { gql } from '@apollo/client';

export const SEND_EMAIL = gql`
    mutation sendEmail($input:EmailInput! ){
        sendEmail(input: $input){
            _id
            subject
            recipients
            attachments
        }
    }
`;

export const GET_EMAILS = gql`
    query{
        getEmails{
        _id
        subject
        attachments
        recipients
        message
        createdAt
        names
        companies
        user{
            firstName
            lastName
            email
        }
        }
    }
`;