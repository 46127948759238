import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Typography, Stack, Skeleton } from '@mui/material';
import { fNumber, fPercent } from '../../../utils/formatNumber';
import Iconify from '../../../components/iconify/Iconify';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

CallWidgetSummary.propTypes = {
  chartColor: PropTypes.string.isRequired,
  chartData: PropTypes.arrayOf(PropTypes.number).isRequired,
  percent: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  status: PropTypes.string,
};

const getStatusStyles = (status, theme) => {
  switch (status) {
    case 'success':
      return {
        color: theme.palette.success.main,
        bgcolor: alpha(theme.palette.success.main, 0.16),
      };
    case 'average':
      return {
        color: theme.palette.warning.main,
        bgcolor: alpha(theme.palette.warning.main, 0.16),
      };
    case 'poor':
    default:
      return {
        color: theme.palette.error.main,
        bgcolor: alpha(theme.palette.error.main, 0.16),
      };
  }
};

export default function CallWidgetSummary({ title, percent, total, status, loading, chartColor, chartData }) {
  const theme = useTheme();

  const icon =
    status === 'success'
      ? 'eva:trending-up-fill'
      : status === 'average'
      ? 'eva:trending-up-fill'
      : 'eva:trending-down-fill';

  const chartOptions = {
    colors: [chartColor],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: '68%', borderRadius: 2 } },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
      marker: { show: false },
    },
  };

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>

        {loading ? (
          <Skeleton variant="circular" width={40} height={40} />
        ) : (
          <>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2, mb: 1 }}>
              <IconWrapperStyle
                sx={{
                  ...getStatusStyles(status, theme),
                }}
              >
                <Iconify width={16} height={16} icon={icon} />
              </IconWrapperStyle>
              {/* <Typography component="span" variant="subtitle2">
            {percent > 0 && '+'}
            {fPercent(percent)}
          </Typography> */}
            </Stack>
            <Typography variant="h3">{fNumber(total)}</Typography>
          </>
        )}
      </Box>

      <ReactApexChart type="bar" series={[{ data: chartData }]} options={chartOptions} width={60} height={36} />
    </Card>
  );
}
