import React from 'react';
import moment from 'moment';

function DateDisplay({ targetDate }) {
  // Calculate the difference between the target date and the current date
  const now = moment();
  const difference = moment(targetDate).diff(now, 'days');

  // Determine the text to display based on the difference
  let text = '';
  if (difference === 0) {
    text = 'Today';
  } else if (difference === 1) {
    text = '1 day to';
  } else if (difference > 1) {
    text = `${difference} days to`;
  } else if (difference === -1) {
    text = '1 day ago';
  } else if (difference < -1) {
    text = `${-difference} days ago`;
  } else {
    text = 'Invalid date';
  }

  return <div>{text}</div>;
}

export default DateDisplay;
