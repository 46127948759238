import { Helmet } from 'react-helmet-async';
// @mui
import { Alert, Box, Breadcrumbs, Container, Grid, Link, Stack, TextField, Typography } from '@mui/material';
// components
// mock
import { useMutation } from '@apollo/client';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Editor from '../components/editor/editor';
import { UPDATE_PROFILE_NO_FILE } from '../graphql/mutations/user';
import { useBoolean } from '../hooks/use-boolean';
import { setUser } from '../redux/auth.slice';

// ----------------------------------------------------------------------

export default function ProfilePage() {

    const { handleSubmit, register, setValue } = useForm();

    const { user } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const [signature, setSignature] = useState('');

    const [udatedProfile, { data, loading, error }] = useMutation(UPDATE_PROFILE_NO_FILE)

    const fullScreen = useBoolean();

    const { enqueueSnackbar } = useSnackbar();

    const handleChangeSignature = useCallback((value) => {
        setSignature(value);
    }, []);


    const onSubmit = (data) => {
        udatedProfile({
            variables: {
                input: {
                    ...data,
                    signature
                }
            }
        })

    };

    useEffect(() => {
        if (user) {
            setValue('firstName', user.firstName);
            setValue('lastName', user.lastName);
            setValue('email', user.email);
            setValue('username', user.username);
            setValue('hubSpotId', user.hubSpotId);
            setSignature(user.signature);
        }
    }, [user, setValue]);

    useEffect(() => {
        if (data?.updateUserProfileWithoutImage) {
            dispatch(setUser(data?.updateUserProfileWithoutImage));
            enqueueSnackbar('Profile updated', { variant: 'success' });
        }
    }, [data, enqueueSnackbar]);

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            Sequence
        </Link>,
        <Typography key="3" color="text.primary">
            Profile
        </Typography>,
    ];



    return (
        <>
            <Helmet>
                <title> Sequence: Products | Auto Caller UI </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="column" spacing={0} alignItems="flex-start">
                            <Typography variant="h3">{`${user?.organization?.name}`}</Typography>
                            <Typography variant="caption">update profile information</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={3}>
                                {error && <Alert severity="error">{error?.message}</Alert>}
                                <TextField name="firstName" required label="First Name" {...register('firstName', { required: true })} />
                                <TextField name="lastName" required label="Last Name" {...register('lastName', { required: true })} />
                                <TextField name="email" required label="Email address" {...register('email', { required: true })} />
                                <TextField name="username" label="Username" {...register('username', { required: false })} />
                                <TextField name='hubSpotId' label="Contact Owner ID" {...register('hubSpotId', { required: true })} />
                                <Typography variant="h6">Signature:</Typography>
                                <Editor
                                    simple
                                    id="compose-mail"
                                    value={signature}
                                    onChange={handleChangeSignature}
                                    placeholder="Type a message"
                                    sx={{
                                        '& .ql-editor': {},
                                        ...(fullScreen.value && {
                                            height: 1,
                                            '& .quill': {
                                                height: 1,
                                            },
                                            '& .ql-editor': {
                                                maxHeight: 'unset',
                                            },
                                        }),
                                    }}
                                />
                                <Box>
                                    <LoadingButton loading={false} type="submit" variant="contained">
                                        Save
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
