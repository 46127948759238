import { Button, Divider, IconButton, Link, MenuItem, Popover, Stack, Tooltip, Typography } from '@mui/material';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
import { CallOutlined, EmailRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { INTERRACTION_TYPES } from '../../utils/constants';
import { fToNow } from '../../utils/formatTime';
import { openLinkInNewTab } from '../../utils/helperFunctions';

function ContactCard({
  contact,
  callLogsData,
  emailLogsData,
  handleCreateOrUpdateDefaultContact,
  activeCompanyDefaultContact,
  setOpenContactDetails,
  handleOpenMenuOptOut,
  setInteractionTarget,
  setInteractionType
}) {
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  
  const initConv = () =>{
    setOpen(null);
    setOpenContactDetails(true);
    setInteractionTarget({
      name: `${contact?.firstName} ${contact?.lastName}`,
      email: contact?.emailAddress,
      phone: contact?.prospectPhoneNumber,
      linkedin: contact?.prospectLinkedinProfile,
    });
  }
 

  const handleSendSms = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.TEXT);
  };

  const handlePhoneCall = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.CALL);
  };

  const handleSendEmail = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.EMAIL);
  };

  const handleSendLinkedinMessage = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.LINKEDINMESSAGE);

  };

  const handleBookAppointment = () =>{
    initConv();
    setInteractionType(INTERRACTION_TYPES.APPOINTMENT);
  }

  const handleOpenDetails = () =>{
    setInteractionType('');
    initConv();
  }
   
  const handleSendLinkedinRequest = () => {
    setOpen(null);
    setOpenContactDetails(true);
    setInteractionTarget({
      name: `${contact?.firstName} ${contact?.lastName}`,
      email: contact?.emailAddress,
      phone: contact?.prospectPhoneNumber,
      linkedin: contact?.prospectLinkedinProfile,
    });
    setInteractionType(INTERRACTION_TYPES.LINKEDINCONNECT);
  };



  const lastContactedViaEmailOrPhone = useMemo(() => {
    if (!contact) {
      return null;
    }

    const lastContactedViaEmail = emailLogsData?.find((emailLog) => emailLog?.email === contact?.emailAddress);

    const lastContactedViaPhone = callLogsData?.find(
      (callLog) => callLog?.phoneNumber === contact?.prospectPhoneNumber
    );

    if (lastContactedViaEmail?.emailSent === 'Yes' && lastContactedViaPhone?.callDone === 'Yes') {
      return lastContactedViaEmail?.dateCreated > lastContactedViaPhone?.dateCreated
        ? lastContactedViaEmail
        : lastContactedViaPhone;
    }

    if (lastContactedViaEmail?.emailSent === 'Yes' && lastContactedViaPhone?.callDone === 'No') {
      return lastContactedViaEmail;
    }

    if (lastContactedViaEmail?.emailSent === 'No' && lastContactedViaPhone?.callDone === 'Yes') {
      return lastContactedViaPhone;
    }

    if (lastContactedViaEmail?.emailSent === 'No' && lastContactedViaPhone?.callDone === 'No') {
      return null;
    }

    return null;
  }, [callLogsData, emailLogsData, contact]);

  return (
    <>
      {contact?.status !== 2 ? (
        <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={0} justifyContent="center" alignItems="center">
              <Link
                underline="hover"
                key="1"
                color="inherit"
                href="#"
                onClick={handleOpenDetails}
              >
                {contact?.firstName} {contact?.lastName}{' '}
                {contact?.prospectDesignation && `(${contact?.prospectDesignation})`}
              </Link>
              <IconButton
                onClick={() => {
                  openLinkInNewTab(contact?.prospectLinkedinProfile);
                }}
              >
                <img src="/assets/icons/ic_linkedin_contact_icon.svg" alt="location" width="20" />
              </IconButton>
            </Stack>
            <Typography variant="caption">
              {lastContactedViaEmailOrPhone
                ? `Last contacted ${fToNow(lastContactedViaEmailOrPhone?.createdAt)}`
                : 'Not contacted'}
            </Typography>
            {activeCompanyDefaultContact &&
            (activeCompanyDefaultContact?.phone === contact?.prospectPhoneNumber ||
              activeCompanyDefaultContact?.email === contact?.emailAddress) ? (
              <Typography>Default</Typography>
            ) : (
              <Button
                startIcon={<img src="/assets/icons/ic_make_default.svg" width={20} alt="plus" />}
                color="inherit"
                size="small"
                sx={{ width: 'max-content' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleCreateOrUpdateDefaultContact(
                    `${contact?.firstName} ${contact?.lastName}`,
                    contact?.emailAddress,
                    contact?.prospectPhoneNumber,
                    contact?.prospectDesignation
                  );
                }}
              >
                Make default
              </Button>
            )}
          </Stack>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
              <IconButton onClick={handleOpenMenu}>
                <img src="/assets/icons/ic_options.svg" alt="edit" width="20" />
              </IconButton>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
              {!contact?.prospectPhoneNumber ? (
                <Tooltip title="No phone number">
                  <span>
                    <IconButton>
                      <CallOutlined />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <IconButton
                  sx={{ border: '1px solid rgba(8, 7, 8, 0.20)', borderRadius: '20%' }}
                  disabled={!contact?.prospectPhoneNumber}
                  onClick={handlePhoneCall}
                >
                  <CallOutlined />
                </IconButton>
              )}
              {!contact?.emailAddress ? (
                <Tooltip title="No email address">
                  <span>
                    <IconButton>
                      <EmailRounded />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <IconButton
                  sx={{ border: '1px solid rgba(8, 7, 8, 0.20)', borderRadius: '20%' }}
                  disabled={!contact?.emailAddress}
                  onClick={handleSendEmail}
                >
                  <EmailRounded />
                </IconButton>
              )}
              <IconButton 
                 sx={{ border: '1px solid rgba(8, 7, 8, 0.20)', borderRadius: '20%' }}
                  onClick={handleBookAppointment}
              >
                <img src="/assets/icons/ic_calender.svg" alt="meeting" width="20" />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack direction="column" spacing={2} sx={{ p: 2 }}>
          <Typography variant='body1'  color="inherit">
          {contact?.firstName} {contact?.lastName}{' '}
                {contact?.prospectDesignation && `(${contact?.prospectDesignation})`}
          </Typography>
          <Typography variant='caption'>Reason for opt out:</Typography>
          <Typography variant='body2'>
            {contact?.reasonForOptOut}
          </Typography>
        </Stack>
      )}
      <Divider />
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleSendSms}>
          <Stack direction="row" spacing={1}>
            <img src="/assets/icons/ic_sms_icon.svg" alt="details" width="20" />
            <Typography variant="body2">SMS</Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleSendLinkedinRequest}>
          <Stack direction="row" spacing={1}>
            <img src="/assets/icons/ic_linkedin_request_icon.svg" alt="details" width="20" />
            <Typography variant="body2">Linkedin Request</Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleSendLinkedinMessage}>
          <Stack direction="row" spacing={1}>
            <img src="/assets/icons/ic_linkedin_message.svg" alt="details" width="20" />
            <Typography variant="body2">Linkedin Message</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenMenuOptOut(contact);
            setOpen(null);
          }}
        >
          <Stack direction="row" spacing={1}>
            <img src="/assets/icons/ic_optout.svg" alt="opt out" width="20" />
            <Typography variant="body2">Opt out</Typography>
          </Stack>
        </MenuItem>
      </Popover>
    </>
  );
}

ContactCard.propTypes = {
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    prospectDesignation: PropTypes.string,
    prospectPhoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
  }),
  handleCreateOrUpdateDefaultContact: PropTypes.func,
  setOpenContactDetails: PropTypes.func,
  handleOpenMenuOptOut: PropTypes.func,
  setInteractionTarget: PropTypes.func,
  setInteractionType: PropTypes.func,
  callLogsData: PropTypes.arrayOf(
    PropTypes.shape({
      phoneNumber: PropTypes.string,
      callDone: PropTypes.string,
      dateCreated: PropTypes.string,
    })
  ),
  emailLogsData: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      emailSent: PropTypes.string,
      dateCreated: PropTypes.string,
    })
  ),
  activeCompanyDefaultContact: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    _id: PropTypes.string,
  }),
};

export default ContactCard;
