import { Button, Dialog, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function AssetDeleteDialog({
    openDelete, 
    handleCloseDelete, 
    handleDeleteAssetsConfirm
}){
    return (
        <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <div style={{padding:'15px'}}>
          <Typography variant="h6" gutterBottom>
            Are you sure you want to delete this asset?
          </Typography>
          <Stack direction="row" alignItems="center" mb={2} spacing={1}>
            <Button variant="contained" color="error" onClick={handleDeleteAssetsConfirm}>
              Delete
            </Button>
            <Button variant="contained" onClick={handleCloseDelete}>
              Cancel
            </Button>
          </Stack>
        </div>
      </Dialog>
    )
}

AssetDeleteDialog.propTypes = {
    openDelete: PropTypes.bool,
    handleCloseDelete: PropTypes.func,
    handleDeleteAssetsConfirm: PropTypes.func
}

AssetDeleteDialog.defaultProps = {
    openDelete: false,
    handleCloseDelete: () => {},
    handleDeleteAssetsConfirm: () => {}
}

export default AssetDeleteDialog;