import { gql } from '@apollo/client';

export const CREATE_NOTE = gql`
    mutation createNote(
        $input: NoteInput!
    ) {
        createNote(
            input: $input
        ) {
            _id
        }
    }
`;

export const CREATE_LEAD_REPORT = gql`
  mutation createLeadReport($input: LeadReportInput!) {
    createLeadReport(input: $input) {
      _id
      created_at
      callDone
      lead {
        _id
        simplifiedCompanyName
        firstName
        lastName
        companyPhoneNumber
        emailAddress
      }
      voicemailDone
      emailSent
      emailPersonalized
    }
  }
`;

export const CREATE_COMPANY_DEFAULT_CONTACT = gql`
  mutation createLeadDefaultContact($input: CompanyDefaultContactInput!) {
    createLeadDefaultContact(input: $input) {
      _id
      createdAt
      name
      role
      email
      phone
    }
  }
`;

export const CREATE_OR_UPDATE_LEAD = gql`
  mutation createOrUpdateLead($input: CreateLeadInput!) {
    createOrUpdateLead(input: $input) {
      _id
      jobSource
      jobCategory
      jobPostTitle
      jobType
      jobPostDate
      jobLocation
      simplifiedCompanyName
      companyDomainName
      companyLinkedinProfile
      companyPhoneNumber
      companyEmail
      simplifiedCompanyLocation
      aboutCompany
      minimumCompanySize
      maximumCompanySize
      simplifiedCompanyIndustry
      generatedCompanyDescription
      websiteTrafficAnalysis
      recommendation
      nearbyLocation1
      nearbyLocation2
      contactOwner
      contactOwnerId
      step
    }
  }
`;

export const DELETE_LEAD = gql`
  mutation deleteLead($leadId: String!) {
    deleteLead(leadId: $leadId) {
      _id
    }
  }
`;

export const CREATE_OR_UPDATE_LEAD_MULTIPLE = gql`
  mutation createOrUpdateLeadMultiple($input: [CreateLeadInput!]!) {
    createOrUpdateLeadMultiple(input: $input) {
      _id
      jobSource
      jobCategory
      jobPostTitle
      jobType
      jobPostDate
      jobLocation
      simplifiedCompanyName
      companyDomainName
      companyLinkedinProfile
      companyPhoneNumber
      companyEmail
      simplifiedCompanyLocation
      aboutCompany
      minimumCompanySize
      maximumCompanySize
      simplifiedCompanyIndustry
      generatedCompanyDescription
      websiteTrafficAnalysis
      recommendation
      nearbyLocation1
      nearbyLocation2
      contactOwner
      contactOwnerId
      step
    }
  }
`;
