import { gql } from '@apollo/client';
import { USER } from '../fragments/user';

export const CREATE_USER = gql`
  ${USER}
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...UserFields
    }
  }
`;

export const googleLogin = gql`
  ${USER}
  mutation googleLogin($input: GoogleInput!) {
    googleLogin(input: $input) {
      ...UserFields
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const LOGIN = gql`
  ${USER}
  mutation login($input: LoginInput!) {
    login(input: $input) {
      ...UserFields
    }
  }
`;

export const SIGNUP = gql`
  ${USER}
  mutation signUp($input: SignUpUserInput!) {
    signUp(input: $input) {
      ...UserFields
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      createdAt
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  ${USER}
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      ...UserFields
    }
  }
`;
