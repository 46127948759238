import * as React from 'react';
// @mui
import {
  Typography,
  IconButton,
  Dialog,
  TextField,
  DialogContent,
  Alert,
  Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
// components
import { useForm } from 'react-hook-form';
// components
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { CREATE_OR_UPDATE_LEAD } from '../../../graphql/mutations/lead';
import Iconify from '../../../components/iconify';


function EditCompanyDialog({ openEditCompanyDialog, handleEditCompanyDialogClose, targetCompany, leads, setAllLeads }) {
  const [createOrUpdateLead, { data, loading, error }] = useMutation(CREATE_OR_UPDATE_LEAD);
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm();

  React.useEffect(() => {
    if (data && data.createOrUpdateLead) {
      const updatedLeads = leads.map((lead) => {  
        if (lead.id === data.createOrUpdateLead.id) {
          return data.createOrUpdateLead;
        }
        return lead;
      });
      setAllLeads(updatedLeads);
      enqueueSnackbar('Company updated successfully', { variant: 'success' });
      handleEditCompanyDialogClose();
    }
  }, [data, enqueueSnackbar, leads, setAllLeads]);

  const onSubmit = (data) => {
    createOrUpdateLead({
      variables: { input: { ...data } },
    });
  };

  return (
    <Dialog open={openEditCompanyDialog} onClose={handleEditCompanyDialogClose} aria-labelledby="form-dialog-title">
      <Typography variant="h5" sx={{ p: 2 }}>
        Edit Company
      </Typography>
      <IconButton
        aria-label="close"
        onClick={handleEditCompanyDialogClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon={'eva:close-fill'} />
      </IconButton>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              {error && <Alert severity="error">{error?.message}</Alert>}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="simplifiedCompanyName"
                required
                label="Simplified Company Name"
                defaultValue={targetCompany?.simplifiedCompanyName}
                {...register('simplifiedCompanyName', { required: true })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="companyDomainName"
                required
                label="Company Domain Name"
                defaultValue={targetCompany?.companyDomainName}
                {...register('companyDomainName', { required: true })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="companyLinkedinProfile"
                label="Company Linkedin Profile"
                defaultValue={targetCompany?.companyLinkedinProfile}
                {...register('companyLinkedinProfile', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="companyPhoneNumber"
                label="Company Phone Number"
                defaultValue={targetCompany?.companyPhoneNumber}
                {...register('companyPhoneNumber', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="companyEmail"
                label="Company Email"
                defaultValue={targetCompany?.companyEmail}
                {...register('companyEmail', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="simplifiedCompanyLocation"
                label="Simplified Company Location"
                defaultValue={targetCompany?.simplifiedCompanyLocation}
                {...register('simplifiedCompanyLocation', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="aboutCompany"
                label="About Company"
                defaultValue={targetCompany?.aboutCompany}
                {...register('aboutCompany', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="minimumCompanySize"
                label="Minimum Company Size"
                defaultValue={targetCompany?.minimumCompanySize}
                {...register('minimumCompanySize', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="maximumCompanySize"
                label="Maximum Company Size"
                defaultValue={targetCompany?.maximumCompanySize}
                {...register('maximumCompanySize', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="simplifiedCompanyIndustry"
                label="Simplified Company Industry"
                defaultValue={targetCompany?.simplifiedCompanyIndustry}
                {...register('simplifiedCompanyIndustry', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="generatedCompanyDescription"
                label="Generated Company Description"
                defaultValue={targetCompany?.generatedCompanyDescription}
                {...register('generatedCompanyDescription', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="websiteTrafficAnalysis"
                label="Website Traffic Analysis"
                defaultValue={targetCompany?.websiteTrafficAnalysis}
                {...register('websiteTrafficAnalysis', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobSource"
                label="Job Source"
                {...register('jobSource', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobPostTitle"
                label="Job Post Title"
                defaultValue={targetCompany?.jobPostTitle}
                {...register('jobPostTitle', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobType"
                defaultValue={targetCompany?.jobType}
                label="Job Type"
                {...register('jobType', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobPostDate"
                label="Job Post Date"
                defaultValue={targetCompany?.jobPostDate}
                {...register('jobPostDate', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobLocation"
                label="Job Location"
                defaultValue={targetCompany?.jobLocation}
                {...register('jobLocation', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="recommendation"
                label="Recommendation"
                defaultValue={targetCompany?.recommendation}
                {...register('recommendation', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="nearbyLocation1"
                label="Nearby Location 1"
                defaultValue={targetCompany?.nearbyLocation1}
                {...register('nearbyLocation1', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="nearbyLocation2"
                label="Nearby Location 2"
                defaultValue={targetCompany?.nearbyLocation2}
                {...register('nearbyLocation2', { required: false })}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6}>
              {!loadingGetUsers && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Owner</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={owner?.toString()}
                  label="Owner"
                  onChange={handleChange}
                >
                  {allUsers?.getAllUsers?.map((user) => (
                    <MenuItem value={user?.hubSpotId?.toString()}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>)}
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                type='number'
                defaultValue={targetCompany?.step}
                name="step"
                label="Step"
                {...register('step', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                defaultValue={targetCompany?.stage}
                name="stage"
                label="Stage"
                {...register('stage', { required: false })}
              />
            </Grid> */}
            <Grid item xs={12} sm={12} md={6}>
              <LoadingButton loading={loading} type="submit" variant="contained">
                Save Changes
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

EditCompanyDialog.propTypes = {
  openEditCompanyDialog: PropTypes.bool,
  handleEditCompanyDialogClose: PropTypes.func,
  targetCompany: PropTypes.object,
  leads: PropTypes.array,
  setAllLeads: PropTypes.func,
};

export default EditCompanyDialog;
