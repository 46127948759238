import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { PlusOneOutlined } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
import { useLazyQuery } from '@apollo/client';
import { useBoolean } from '../../../hooks/use-boolean';
import { useResponsive } from '../../../hooks/use-responsive';

// components

import { GET_EMAILS } from '../../../graphql/queries/email';
import MailCompose from '../mail-compose';
import MailDetails from '../mail-details';
import MailList from '../mail-list';

// ----------------------------------------------------------------------

export default function MailView() {
  const [getEmails, { data, loading }] = useLazyQuery(GET_EMAILS)
  const [state, setState] = useState({
    mails: {
      byId: {},
      allIds: []
    
    },
    mail: null,
    labels: [
      {
        "id": "all",
        "type": "system",
        "name": "all",
        "unreadCount": 3
      },
      {
        "id": "inbox",
        "type": "system",
        "name": "inbox",
        "unreadCount": 1
      },
      {
        "id": "sent",
        "type": "system",
        "name": "sent",
        "unreadCount": 0
      },
      {
        "id": "drafts",
        "type": "system",
        "name": "drafts",
        "unreadCount": 0
      },
      {
        "id": "trash",
        "type": "system",
        "name": "trash",
        "unreadCount": 0
      },
      {
        "id": "spam",
        "type": "system",
        "name": "spam",
        "unreadCount": 1
      },
      {
        "id": "important",
        "type": "system",
        "name": "important",
        "unreadCount": 1
      },
      {
        "id": "starred",
        "type": "system",
        "name": "starred",
        "unreadCount": 1
      },
      {
        "id": "social",
        "type": "custom",
        "name": "social",
        "unreadCount": 0,
        "color": "#00AB55"
      },
      {
        "id": "promotions",
        "type": "custom",
        "name": "promotions",
        "unreadCount": 2,
        "color": "#FFC107"
      },
      {
        "id": "forums",
        "type": "custom",
        "name": "forums",
        "unreadCount": 1,
        "color": "#FF4842"
      }
    ],
    labelsLoading: false,
    mailsLoading: false,
    mailLoading: false,
    mailsError: false,
    mailError: false,
    mailsEmpty: true
  });

  const [selectedLabelId, setSelectedLabelId] = useState(0);

  const [logData, setLogData] = useState(null);

  const selectedMailId = 'inbox';

  const router = useNavigate();

  const mdUp = useResponsive('up', 'md');

  const openNav = useBoolean();

  const activeLead = null;

  const activeContact = null;

  const openMail = useBoolean();

  const openCompose = useBoolean();

  const { mailsError, mailError } = state;

  const firstMailId = '';

  const handleToggleCompose = useCallback(() => {
    if (openNav.value) {
      openNav.onFalse();
    }
    openCompose.onToggle();
  }, [openCompose, openNav]);

  const handleCloseInteraction = useCallback((data) => {
    openMail.onFalse();
    setLogData(data);
  }, [openMail]);

  const handleClickMail = useCallback((mailId) => {
    if (!mdUp && mailId) {
      openMail.onFalse();
    }
  },
    [openMail, mdUp]
  );

  const onClickMail = useCallback((mailId) => {
      setState((prevState) => ({
        ...prevState,
        mail: prevState.mails.byId[mailId]
      }));
      const index = state.mails.allIds.indexOf(mailId);
      setSelectedLabelId(index);
  }, [state.mails.allIds]);

  useEffect(() => {
    if (data?.getEmails) {
      const byId = {};
      const allIds = [];
      data?.getEmails.forEach((mail) => {
        byId[mail._id] = {
          id: mail._id,
          to: mail?.recipients?.map((recipient) => ({
            name: recipient,
            email: recipient,
            avatarUrl: null
          })),
          from: {
            name: `${mail?.user?.firstName}  ${mail?.user?.lastName}`,
            email: mail?.user?.email,
            avatarUrl: null
          },
          folder: 'inbox',
          labelIds: [],
          attachments: mail.attachments,
          createdAt: mail.createdAt,
          subject: mail.subject,
          isUnread: false,
          isImportant: true,
          message: mail.message,
          names: mail.names,
          companies: mail.companies,
          isStarred: true

        };
        allIds.push(mail._id);
      });

      setState((prevState) => ({
        ...prevState,
        mails: {
          byId,
          allIds
        },
        mail: byId[allIds[0]],
        mailsLoading: false,
        mailsError: false,
        mailsEmpty: !allIds.length
      }));
    }
  }, [data]);

  useEffect(() => {
    if (mailsError || mailError) {
      router.push('/');
    }
  }, [mailError, mailsError, router]);

  useEffect(() => {
    if (firstMailId) {
      handleClickMail(firstMailId);
    }
  }, [firstMailId, handleClickMail]);

  useEffect(() => {
    if (openCompose.value) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [openCompose.value]);

  useEffect(() => {
    getEmails();
  }, []);

  useEffect(() => {
    if (logData) {
      getEmails();
    }
  }, [logData, getEmails]);

  const renderMailList = (
    <MailList
      mails={state.mails}
      loading={loading}
      //
      openMail={openMail.value}
      onCloseMail={openMail.onFalse}
      onClickMail={onClickMail}
      //
      selectedLabelId={selectedLabelId}
      selectedMailId={selectedMailId}
    />
  );

  const renderMailDetails = (
    <MailDetails
      mail={state.mail}
      renderLabel={(id) => state.labels.filter((label) => label.id === id)[0]}
    />

  );

  return (
    <>
      <Container maxWidth={'xl'}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h4"
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          >
            Mail
          </Typography>
          <Button variant="contained" onClick={handleToggleCompose} startIcon={<PlusOneOutlined />}>
            New Message
          </Button>
        </Stack>
        <Stack
          spacing={1}
          direction="row"
          flexGrow={1}
          sx={{
            p: 2,
            height: {
              xs: '72vh',
              md: 'calc(100vh - 80px)',
            },
          }}
        >
          {renderMailList}
          <Divider orientation="vertical" flexItem />
          {renderMailDetails}
        </Stack>
        {openCompose.value && <MailCompose
          onCloseCompose={openCompose.onFalse}
          handleCloseInteraction={handleCloseInteraction}
          defaultRecipients={[]}
          activeLead={activeLead}
          contact={activeContact}
        />}
      </Container>
    </>
  );
}
