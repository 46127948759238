import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import { Button, Divider, LinearProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { useSnackbar } from 'notistack';
// components

import { LoadingButton } from '@mui/lab';
// components
import { useLazyQuery, useMutation } from '@apollo/client';
import Iconify from '../../../components/iconify';
// hooks
import { CREATE_TRAINING_MULTIPLE, UPDATE_MULTIPLE_TRAINING } from '../../../graphql/mutations/training';
import { GET_LEAD_BY_NAME } from '../../../graphql/queries/lead';
import { GET_CHAT_COMPLETION, GET_CONTACT_BY_KEYWORD } from '../../../graphql/queries/log';
import { GET_ORGANIZATION } from '../../../graphql/queries/organization';
import { TestingCampElement } from './TestingCampElement';


export default function TestingCamp({ activeChat, setActiveChat }) {
    const [responses, setResponses] = useState([]);
    const [getContactsByKeyword, { data: contacts }] = useLazyQuery(GET_CONTACT_BY_KEYWORD);
    const [getLeadByName, { data: leadByName, loading: loadingLeadByName }] = useLazyQuery(GET_LEAD_BY_NAME);
    const [getOrganization, { data: organization }] = useLazyQuery(GET_ORGANIZATION);
    const [createModelTrainingMultiple, { data: createTrainingData, loading: createLoading }] = useMutation(CREATE_TRAINING_MULTIPLE);
    const [updateModelTrainingMultiple, { data: updateTrainingData, loading: updateLoading }] = useMutation(UPDATE_MULTIPLE_TRAINING);
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState({
        context: '',
        prompt: '',
    });
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                getContactsByKeyword({ variables: { keyword: request.input } });
            }, 400),
        [],
    );

    useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const [getChatCompletion, { data: chatResponse, loading }] = useLazyQuery(GET_CHAT_COMPLETION);

    const handleInputChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = useCallback(() => {
        if (data.context !== '' || data.prompt !== '') {
            getChatCompletion({ variables: { input: { context: data.context, prompt: data.prompt } } });
        }

    }, [data]);

    const handleReset = () => {
        setResponses([]);
        setValue(null);
        setInputValue('');
        setActiveChat(null);
        setData({
            context: '',
            prompt: '',
        });
    };

    const hadleOnKeyDown = (e) => {
        if (e.keyCode === 13) {
            onSubmit();
        }
    };
    useEffect(() => {
        getOrganization();
    }, []);

    useEffect(() => {
        if (contacts && contacts?.getContactsByKeyword) {
            let newOptions = [];

            if (value) {
                newOptions = [value];
            }
            setOptions([...newOptions, ...contacts?.getContactsByKeyword]);
        }
    }, [contacts, value]);

    useEffect(() => {
        if (chatResponse) {
            setResponses([...responses, {
                context: data?.context,
                prompt: data?.prompt,
                completion: chatResponse?.completeChat,
            }]);
        }
    }, [chatResponse]);

    useEffect(() => {
        if ((value || leadByName?.getLeadByName) && organization?.getOrganization?.system) {
            const context = `${organization?.getOrganization?.system}, Company Name: ${leadByName?.getLeadByName?.simplifiedCompanyName || ''}, Company Website Traffic Analysis: ${leadByName?.getLeadByName?.websiteTrafficAnalysis || ''}, About Company: ${leadByName?.getLeadByName?.aboutCompany || ''}, Prospect Name: ${value?.firstName} ${value?.lastName}, Prospect Industry:${leadByName?.getLeadByName?.simplifiedCompanyIndustry || ''}, Prospect Location:${leadByName?.getLeadByName?.simplifiedCompanyLocation || ''}, Prospect Domain:${value?.prospectDesignation}, Prospect Company Size:${leadByName?.getLeadByName?.simplifiedCompanyLocation || ''}. Type: Cold Call. You: Punch. Me: Prospect.`
            setData({
                ...data,
                context
            });
        }
    }, [value, leadByName, organization]);

    useEffect(() => {
        if (createTrainingData) {
            enqueueSnackbar('Training uploaded successfully', { variant: 'success' });
            handleReset();
        }
    }, [createTrainingData]);

    useEffect(() => {
        if (updateTrainingData) {
            enqueueSnackbar('Training updated successfully', { variant: 'success' });
            handleReset();
        }
    }, [updateTrainingData]);



    useEffect(() => {
        if (activeChat?._id) {
            setData({
                context: activeChat?.preContext || '',
            });
            const activeChatResponses = activeChat?.response?.map((response, index) => {
                return {
                    context: activeChat?.preContext || '',
                    prompt: activeChat?.objection[index],
                    completion: response,
                };
            });
            setResponses(activeChatResponses);
        }
    }, [activeChat?._id]);

    const handleSaveFullChat = useCallback(() => {
        if (activeChat?._id) {
            updateModelTrainingMultiple({
                variables: {
                    input: {
                        preContext: data?.context,
                        objection: responses.map((response) => response?.prompt),
                        response: responses.map((response) => response?.completion),
                    },
                    _id: activeChat?._id
                }
            });
        } else {
            createModelTrainingMultiple({
                variables: {
                    input: {
                        preContext: data?.context,
                        objection: responses.map((response) => response?.prompt),
                        response: responses.map((response) => response?.completion),
                    }
                }
            });
        }
    }, [activeChat, responses, data]);

    useEffect(() => {
        if (value?.company) {
            getLeadByName({
                variables: {
                    name: value?.company
                }
            })
        }
    }, [value, getLeadByName]);

    return (
        <>
            <Stack direction="column" spacing={2} flexGrow={1} sx={{ p: 2 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6">Try AI Learning</Typography>
                    <Stack direction="column" spacing={2} sx={{ width: '30%' }}>
                        <Stack direction="row" spacing={2}>
                            <Autocomplete
                                id="google-map-demo"
                                sx={{ width: '70%' }}
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : option.firstName
                                }
                                filterOptions={(x) => x}
                                options={options}
                                autoComplete
                                includeInputInList
                                filterSelectedOptions
                                value={value}
                                noOptionsText="No Contact.."
                                onChange={(event, newValue) => {
                                    setOptions(newValue ? [newValue, ...options] : options);
                                    setValue(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="View as" fullWidth />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <Stack direction="column">
                                            <Typography variant="body2" color="text.primary">
                                                {`${option?.firstName} ${option?.lastName} ${option?.prospectPhoneNumber}`}
                                            </Typography>
                                            <Typography variant="body2" color="text.primary">
                                                {`${option?.company},  ${option?.prospectDesignation}`}
                                            </Typography>
                                            <Divider />
                                        </Stack>
                                    </li>
                                )
                                }
                            />
                            <Button
                                variant="outlined"
                                onClick={handleReset}
                                endIcon={<Iconify icon="eva:close-fill" />}
                            >
                                Reset
                            </Button>
                        </Stack>
                        {value &&
                            <Typography variant='body2' color='text.secondary'>
                                {`${value?.firstName} ${value?.lastName} ${value?.prospectPhoneNumber}, ${value?.prospectDesignation}, ${value?.company} `}
                            </Typography>
                        }
                    </Stack>
                </Stack>
                {(loadingLeadByName || loading) && <LinearProgress />}
                <TextField multiline placeholder='Type in your context...' label="Context" rows={3} name="context" value={data?.context} onChange={handleInputChange} />
                <Stack direction="column" spacing={2} sx={{ height: '40vh', overflowY: 'auto' }}>
                    {responses.length === 0 && <Typography variant="body2" color="text.secondary">No responses yet</Typography>}
                    {responses.map((response, index) => (
                        <TestingCampElement response={response} key={index} id={index} responses={responses} setResponses={setResponses} />
                    ))}
                </Stack>
            </Stack>
            <Stack spacing={2} direction='column' flexGrow={1} sx={{ p: 2, maxWidth: '100%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <TextField multiline rows={3} placeholder='Type in your prompt...' label="Prompt" size='small' sx={{ width: '90%' }} name="prompt" value={data?.prompt} onChange={handleInputChange} onKeyDown={hadleOnKeyDown} />
                    <Stack direction="column" alignItems="center" spacing={1}>
                        <Button
                            variant="outlined"
                            onClick={handleSaveFullChat}
                            endIcon={<Iconify icon="eva:save-fill" />}
                        >
                            {createLoading || updateLoading ? 'Saving...' : activeChat ? 'Update' : 'Save'}
                        </Button>

                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={loading}
                            onClick={onSubmit}
                            endIcon={<Iconify icon="iconamoon:send-fill" />}
                        >
                            Send
                        </LoadingButton>
                    </Stack>

                </Stack>

            </Stack>
        </>
    );
}

TestingCamp.propTypes = {

};
