import { useState, useCallback } from 'react';

const useCallTimer = () => {
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const resetTimer = useCallback(() => {
    setTimer(0);
  }, []);

  const stopTimer = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [intervalId]);

  const startTimer = useCallback(() => {
    stopTimer(); 
    resetTimer();
    
    const id = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);

    setIntervalId(id);
  }, [stopTimer, resetTimer]);

  const formatTime = useCallback((seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }, []);

  return { startTimer, stopTimer, resetTimer, formattedTime: formatTime(timer), timer };
};

export default useCallTimer;
