import moment from "moment";

export const openLinkInNewTab = (url) => {
  if (!url.includes('http')) {
    url = `https://${url}`;
  }
  window.open(url, '_blank');
};

export const removeDays = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() - days);
  return copy;
};

export const sendSms = (phone, message) => {
  window.open(`sms:${phone}?body=${message}`);
};

export const convertToCamelCase = (str) =>
  str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    .replace(/[^a-zA-Z0-9]/g, '');

export const getToday = () => {
  // Set the timezone to Eastern Standard Time (EST)
  moment.tz.setDefault('America/New_York');

  // Get the current date and time in EST
  const currentDate = moment();

  // Add one day to the current date
  // currentDate.add(1, 'days');

  // Set the start time to the beginning of the day (00:00:00)
  const startTime = currentDate.clone().startOf('day');

  // Set the end time to the end of the day (23:59:59)
  const endTime = currentDate.clone().endOf('day');

  // Format the dates for display or further use
  const startDateTimeString = startTime.toISOString();
  const endDateTimeString = endTime.toISOString();

  return {
    startDate: startDateTimeString,
    endDate: endDateTimeString,
  };
};

export function getTodayUTC() {
  const today = new Date();

  // Start of the day
  const startDate = new Date(today.setUTCHours(0, 0, 0, 0)).toISOString();

  // End of the day
  const endDate = new Date(today.setUTCHours(23, 59, 59, 999)).toISOString();
  return {
    startDate,
    endDate
  };
}

export const getDateRange = (type) => {
  const today = new Date();
  let startDate;
  let endDate;

  switch (type) {
    case 'today': {
      startDate = new Date(today);
      endDate = new Date(today);
      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCHours(23, 59, 59, 999);
      break;
    }

    case 'yesterday': {
      startDate = new Date(today);
      endDate = new Date(today);
      startDate.setUTCDate(today.getUTCDate() - 1);
      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCDate(today.getUTCDate() - 1);
      endDate.setUTCHours(23, 59, 59, 999);
      break;
    }

    case 'thisWeek': {
      const dayOfWeek = today.getUTCDay();
      startDate = new Date(today);
      endDate = new Date(today);
      startDate.setUTCDate(today.getUTCDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCDate(startDate.getUTCDate() + 6);
      endDate.setUTCHours(23, 59, 59, 999);
      break;
    }

    case 'lastWeek': {
      startDate = new Date(today);
      startDate.setUTCDate(today.getUTCDate() - (today.getUTCDay() === 0 ? 13 : today.getUTCDay() + 6));
      startDate.setUTCHours(0, 0, 0, 0);
      endDate = new Date(startDate);
      endDate.setUTCDate(startDate.getUTCDate() + 6);
      endDate.setUTCHours(23, 59, 59, 999);
      break;
    }

    case 'thisMonth': {
      startDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1));
      endDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0));
      endDate.setUTCHours(23, 59, 59, 999);
      break;
    }

    case 'previousMonth': {
      startDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - 1, 1));
      endDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 0));
      endDate.setUTCHours(23, 59, 59, 999);
      break;
    }

    case 'sixMonths': {
      startDate = new Date(today);
      startDate.setUTCMonth(today.getUTCMonth() - 6);
      startDate.setUTCHours(0, 0, 0, 0);
      endDate = new Date(today);
      endDate.setUTCHours(23, 59, 59, 999);
      break;
    }

    default:
      throw new Error('Invalid date range type');
  }

  return { startDate, endDate };
};






export const isAdmin = (user) => user?.includes('ADMIN');

export const isAgent = (user) => user?.includes('AGENT');

export const getFileSize = async (url) => {
  const request = new XMLHttpRequest();
  request.open('HEAD', url, false);
  request.send(null);
  if (request.status === 200) {
    const fileSize = request.getResponseHeader('Content-Length');
    const convertedMB = (fileSize / (1024 * 1024)).toFixed(4);
    return `${convertedMB} MB`;
  }
  return 0;
};

export const formatPhoneNumber = (phoneNumber, append = "") => {
  const cleaned = (`${  phoneNumber}`).replace(/\D/g, '');
  if (cleaned.length < 11) {
    return `${append}${cleaned}`;
  }
  return cleaned
};

export const formatDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;
  return [hours, minutes, seconds]
    .map((v) => v < 10 ? `0${v}` : v)
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
}

export const getPhoneNumber = (phoneNumber) => {
  if (phoneNumber.startsWith('1')) {
    phoneNumber = phoneNumber.substring(1);
  }

const phoneNumbers = [
    { number: "2393089744", area_code: 239, region: "Florida" },
    { number: "4086897355", area_code: 408, region: "California" },
    { number: "8133438775", area_code: 813, region: "Florida" },
    { number: "9542374181", area_code: 954, region: "Florida" }
];

  const providedAreaCode = parseInt(phoneNumber.substring(0, 3), 10);

  const exactMatch = phoneNumbers.find(phone => phone.area_code === providedAreaCode);
  if (exactMatch) {
    return exactMatch.number;
  }

  let nearestMatch = null;
  phoneNumbers.forEach(phone => {
    const distance = Math.abs(phone.area_code - providedAreaCode);
    if (!nearestMatch || distance < nearestMatch.distance) {
      nearestMatch = { phone: phone.number, distance };
    }
  });

  return `1${nearestMatch?.phone}`;
}

export const formatPhoneNumberFull = (phoneNumberString) => {
  const formattedNumber = phoneNumberString.replace(/\D/g, '');
  if (formattedNumber.length !== 11) {
    return `1${formattedNumber}`;
  }

  return formattedNumber;
}


export function removeMicStream(firstStream, secondStream) {
  // Escape special characters in the first stream for regex
  const escapedFirstStream = firstStream.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Create a regular expression to match the first stream anywhere in the second stream
  const regex = new RegExp(escapedFirstStream, 'g');

  // Replace occurrences of the first stream with an empty string in the second stream
  const updatedSecondStream = secondStream.replace(regex, '').trim();

  return updatedSecondStream;
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}