import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import LottieLoadingLoader from '../components/loader/LottieLoadingLoader';
import { setUser, logOutUser } from '../redux/auth.slice';
import { GET_USER } from '../graphql/queries/user';

const VerifySSOPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_USER);
  useEffect(() => {
    console.log("DATA....", {data, loading, error});
    if (data && data.getUser) {
      dispatch(setUser(data.getUser));
      navigate('/sequence', { replace: true });
    } else if (!loading && !data) {
      dispatch(logOutUser());
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 500);
    }
  }, [data, dispatch, navigate]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      {!loading ? (
        // <CircularProgress />
        <LottieLoadingLoader w={300} h={300} />
      ) : error ? (
        <Typography variant="h6" color="error">
          Error loading user data
        </Typography>
      ) : (
        // <Typography variant="h6">Verification successful. Redirecting...</Typography>
        <LottieLoadingLoader w={300} h={300} />
      )}
    </Box>
  );
};

export default VerifySSOPage;
