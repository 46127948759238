import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function LinkedinConnectPrompt({ onClose, interactionTarget }) {
  const [completed, setCompleted] = useState(false);
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    connectRequestSent: 'No',
  });

  const handleOk = (value) => {
      setFormData({ ...formData, connectRequestSent: value });
      setCompleted(true);
  };

  const handleCancel = () => {
    if(completed){
      onClose({});
    }else if (step === 1) {
        setStep(0);
    }else if (step === 2) {
        setStep(1);
    }else {
        onClose({});
    }
  };

  const onFinalClose = () => {
    onClose(formData);
    setStep(0);
    setCompleted(false);
    setFormData({  connectRequestSent: 'No' });
  };

  const handleRestart = () => {
    setStep(0);
    setCompleted(false);
    setFormData({ connectRequestSent: 'No' });
  };

  useEffect(() => {
    if (completed) {
      onClose(formData);
      setStep(0);
      setCompleted(false);
      setFormData({ connectRequestSent: 'No' });
    }
  }, [completed, formData, onClose]);

  return (
    <Stack direction="row" spacing={2}>
      <Typography variant="h6">
        {completed ? 'All Steps Complete': `Did you request to connect with ${interactionTarget?.name}?`}
        
      </Typography>
      {completed ? (
        <>
            <Button variant="outlined" onClick={handleRestart}>
              Restart
            </Button>
            <Button variant='outlined' onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant='outlined' onClick={onFinalClose}>
                Submit
            </Button>
        </>
      ) : (
        <>
          <Button variant="outlined" onClick={() => handleOk('Yes')}>
            Yes
          </Button>
          <Button variant="outlined" onClick={() => handleOk('No')}>
            No
          </Button>
          <Button
            variant="outlined"
            autoFocus
            onClick={handleCancel}
          >
            {step === 1 ? 'Back' : 'Cancel'}
          </Button>
        </>
      )}
    </Stack>
  );
}

export default LinkedinConnectPrompt;
