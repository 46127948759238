import PropTypes from 'prop-types';
// @mui
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// utils
// hooks
// components
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import EmptyContent from '../../components/empty-content';
import Iconify from '../../components/iconify';
import TextMaxLine from '../../components/text-max-line';
import { GET_CALL_RECORDING_BY_PHONE_NUMBER } from '../../graphql/queries/log';
import { fDateTime } from '../../utils/formatTime';

// ----------------------------------------------------------------------

export default function CallDetails({ call }) {
  const [getCallRecodingByPhone, { data: callRecordingsData, loading }] = useLazyQuery(
    GET_CALL_RECORDING_BY_PHONE_NUMBER
  );

  const [callRecordings, setCallRecordings] = useState([]);

  useEffect(() => {
    if (call?.voicemailDone) {
      getCallRecodingByPhone({ variables: { phoneNumber: call?.voicemailDone } });
    }
  }, [call?.voicemailDone]);

  useEffect(() => {
    if (callRecordingsData?.getRecordingsForPhoneNumber) {
      setCallRecordings(callRecordingsData.getRecordingsForPhoneNumber);
    }
  }, [callRecordingsData?.getRecordingsForPhoneNumber]);

  if (!call) {
    return (
      <EmptyContent
        title="No Conversation Selected"
        description="Select a conversation to read"
        imgUrl="/assets/icons/empty/ic_email_selected.svg"
        sx={{
          borderRadius: 1.5,
          bgcolor: 'background.default',
        }}
      />
    );
  }

  const renderSubject = (
    <Stack spacing={2} direction="row" flexShrink={0} sx={{ p: 2 }}>
      <TextMaxLine variant="subtitle2" sx={{ flexGrow: 1 }}>
        {`Connected by ${call?.agentId?.user?.firstName} to ${call.subject} - ${call.to}`}
      </TextMaxLine>

      <Stack spacing={0.5}>
        <Typography variant="caption" noWrap sx={{ color: 'text.disabled' }}>
          {fDateTime(call.createdAt)}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderDetails = (
    <Stack
      spacing={2}
      sx={{
        p: (theme) => theme.spacing(2, 2, 2, 2),
      }}
    >
      <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="flex-start">
        <Typography variant="body1">
          <Iconify width={18} icon="solar:user-bold-duotone" /> {call?.leadId?.firstName}
        </Typography>
        <Typography variant="body1">
          <Iconify width={18} icon="solar:phone-linear" /> {call?.leadId?.prospectPhoneNumber}
        </Typography>
        <Typography variant="body1" sx={{ textTransform: 'uppercase' }}>
          <Iconify width={18} icon="gg:time" /> {call?.status}
        </Typography>
        <Typography variant="body1">
          <Iconify width={18} icon="pajamas:status" /> {call?.duration} sec
        </Typography>
        {loading && <Typography variant="body1">Loading recordings...</Typography>}
        {call?.callRecordingUrl && (
          <audio controls>
            <source src={call.callRecordingUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
      </Stack>
    </Stack>
  );

  return (
    <Stack
      flexGrow={1}
      sx={{
        width: 1,
        minWidth: 0,
        borderRadius: 1.5,
        bgcolor: 'background.default',
      }}
    >
      {renderSubject}
      <Divider sx={{ borderStyle: 'dashed' }} />
      {renderDetails}
    </Stack>
  );
}

CallDetails.propTypes = {
  call: PropTypes.object,
  renderLabel: PropTypes.func,
};
