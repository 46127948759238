import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Alert, Checkbox, FormControlLabel, FormGroup, FormLabel, InputAdornment, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Iconify from '../../../components/iconify';
import { CREATE_USER } from '../../../graphql/mutations/auth';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function AddUserDialog({ open, handleClose, userList, setUserList }) {
  const [createUser, { data: createUserData, loading, error }] = useMutation(CREATE_USER);
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState({
    AGENT: true,
    ADMIN: false,
  });

  const handleChange = (event) => {
    setRole({
      ...role,
      [event.target.name]: event.target.checked,
    });
  };
 
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    const myRole = Object.keys(role).filter((key) => role[key]).join(',');
    createUser({
      variables: { input: { ...data, role:myRole } },
    });
  };

  useEffect(() => {
    if (createUserData && createUserData.createUser) {
      setUserList([...userList, createUserData.createUser]);
      handleClose();
    }
  }, [createUserData])

  const { ADMIN, AGENT } = role;

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add User
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ width: { sm: '100%', md: '500px' } }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {error && <Alert severity="error">{error?.message}</Alert>}
              <TextField name="firstName" required label="First Name" {...register('firstName', { required: true })} />
              <TextField name="lastName" required label="Last Name" {...register('lastName', { required: true })} />
              <TextField name="email" required label="Email address" {...register('email', { required: true })} />
              <TextField name="username" label="Username" {...register('username', { required: false })} />
              <TextField name='hubSpotId' label="Contact Owner ID" {...register('hubSpotId', { required: role === 'AGENT' })} />
              <Box sx={{ minWidth: 120 }}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">Role</FormLabel>
                  <FormGroup row>
                    <FormControlLabel control={<Checkbox checked={AGENT} onChange={handleChange} name="AGENT" />} label="AGENT" />
                    <FormControlLabel control={<Checkbox checked={ADMIN} onChange={handleChange} name="ADMIN" />} label="ADMIN" />
                  </FormGroup>
                </FormControl>
              </Box>
              <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register('password', { required: true })}
              />
              <LoadingButton loading={loading} type="submit" variant="contained">
                Add User
              </LoadingButton>
            </Stack>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

AddUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userList: PropTypes.array.isRequired,
  setUserList: PropTypes.func.isRequired,
};

export default AddUserDialog;
