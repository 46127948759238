import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Device } from '@twilio/voice-sdk';
import { baseServerUrl } from '../../config/apiConfig';

const VoiceCallPage3 = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [callStatus, setCallStatus] = useState('');
  const [incomingCall, setIncomingCall] = useState(null);
  const callingToken = useRef(null);
  const device = useRef(null);
  const callInstance = useRef(null);
  const userId = 'starr'; // This should be unique per user/device

  useEffect(() => {
    // Function to fetch token from the server
    const fetchToken = async () => {
      try {
        const response = await axios.post(`${baseServerUrl}/api/v1/twilio-v3/token`, { identity: userId });
        callingToken.current = response.data.token;
        
        initializeDevice(); // Initialize Twilio Device after fetching token
      } catch (error) {
        console.error('Error fetching token:', error);
        setCallStatus('Error fetching token');
      }
    };

    // Function to initialize Twilio Device
    const initializeDevice = () => {
      device.current = new Device(callingToken.current, {
        codecPreferences: ['opus', 'pcmu'],
        // 'tokenWillExpire' event will be emitted 30 seconds before the AccessToken expires
        tokenRefreshMs: 30000
      });

      device.current.on('ready', () => {
        console.log('Device is ready');
        setCallStatus('Device is ready');
      });

      device.current.on('error', (error) => {
        console.error('Device error:', error);
        setCallStatus(`Device error: ${error.message}`);
      });

      device.current.on('incoming', (call) => {
        console.log('Incoming call from:', call.parameters.From);
        setIncomingCall(call);
        setCallStatus('Incoming call');
      });

      // Handle token expiration
      device.current.on('tokenWillExpire', async () => {
        try {
          const response = await axios.post(`${baseServerUrl}/api/v1/twilio-v3/token`, { identity: userId });
          callingToken.current = response.data.token;
          device.current.updateToken(callingToken.current); // Update Device with new token
          console.log('Token updated');
        } catch (error) {
          console.error('Error updating token:', error);
          setCallStatus('Error updating token');
        }
      });

      device.current.register(); // Register the Device after initialization
    };

    // Initial fetch of token
    fetchToken();

    // Clean up function
    return () => {
      if (device.current) {
        device.current.disconnectAll();
        device.current.unregister();
      }
    };
  }, []);

  const handleCall = async () => {
    try {
      if (!callingToken.current) {
        throw new Error('No token available');
      }
      
      const params = {
        To: phoneNumber,
        From: '+19033286481'
      };

      await axios.post(`${baseServerUrl}/api/v1/twilio-v3/outbound-call`, params);

      if (device.current) {
        callInstance.current = await device.current.connect({ params });

        callInstance.current.on('accept', () => {
          console.log('Call accepted');
          setCallStatus('Call accepted');
        });

        callInstance.current.on('ringing', () => {
          console.log('Ringing...');
          setCallStatus('Ringing...');
        });

        callInstance.current.on('answered', () => {
          console.log('Call answered');
          setCallStatus('Call answered');
        });

        callInstance.current.on('connect', () => {
          console.log('Connected');
          setCallStatus('Connected');
        });

        callInstance.current.on('disconnect', () => {
          console.log('Call ended');
          setCallStatus('Call ended');
          device.current.disconnectAll();
          callInstance.current = null;
        });

        callInstance.current.on('cancel', () => {
          console.log('Call cancelled');
          setCallStatus('Call cancelled');
        });

        console.log('Device state after connect:', device.current);
        console.log('Call instance state after connect:', callInstance.current);
      } else {
        throw new Error('Unable to make call');
      }
    } catch (error) {
      console.error('Error making call:', error);
      setCallStatus(`Error making call: ${error.message}`);
    }
  };

  const handleEndCall = () => {
    if (callInstance.current) {
      callInstance.current.disconnect();
      setCallStatus('Call ended');
    }
  };

  const handleAcceptIncomingCall = () => {
    if (incomingCall) {
      incomingCall.accept();
      setCallStatus('Incoming call accepted');
      setIncomingCall(null);
    }
  };

  const handleRejectIncomingCall = () => {
    if (incomingCall) {
      incomingCall.reject();
      setCallStatus('Incoming call rejected');
      setIncomingCall(null);
    }
  };

  return (
    <div>
      <h1>Make a Voice Call</h1>
      <input
        type="text"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Enter phone number"
      />
      <button onClick={handleCall}>Call</button>
      {callInstance.current && (
        <button onClick={handleEndCall}>End Call</button>
      )}
      <p>Call Status: {callStatus}</p>

      {incomingCall && (
        <div>
          <p>Incoming call from: {incomingCall.parameters.From}</p>
          <button onClick={handleAcceptIncomingCall}>Accept</button>
          <button onClick={handleRejectIncomingCall}>Reject</button>
        </div>
      )}
    </div>
  );
};

export default VoiceCallPage3;
