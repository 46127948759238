import { useSelector } from 'react-redux';
import { RefreshOutlined } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React, { useEffect, useMemo, useState } from 'react';
import { fDate } from '../../../utils/formatTime';
import useTwilioService from '../../../services/twilio/use.twilio.service';

export default function CallPollingLogPlayground() {
  const [state, setState] = React.useState({
    page: 0,
    limit: 100,
    skip: 0,
    search: '',
    filter: {},
    sort: {
      field: '_id',
      order: 'desc',
    },
  });

  const { fetchCallLogs } = useTwilioService();
  const { callLogs } = useSelector((state) => state.twilio);
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchCallLogs();
      } catch (error) {
        console.error('Error fetching call logs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const dataCount = useMemo(() => callLogs?.length || 0, [callLogs]);

  return (
    <Grid item container xs={12} sm={12}>
      <Grid item xs={12} sm={12}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`Page -${skip / 100 + 1}`}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField size="small" placeholder="Search" variant="outlined" />
            <IconButton
              disabled={skip < 100 || loading}
              onClick={() => {
                setState({ ...state, skip: skip - 100 });
              }}
              aria-label="refresh"
            >
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setState({ ...state, skip: skip + 100 });
              }}
              disabled={dataCount < 100 || loading}
              aria-label="refresh"
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <IconButton onClick={() => {}} aria-label="refresh">
              <RefreshOutlined />
            </IconButton>
            {loading && <CircularProgress />}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Call SID</TableCell>
                <TableCell>Lead Name</TableCell>
                <TableCell>Lead Phone</TableCell>
                <TableCell>Agent Assigned</TableCell>
                <TableCell>Call Recording</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <Typography>Loading...</Typography>
              ) : (
                callLogs?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>
                      <Typography variant="body2">{row?.callSid}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{row?.leadId?.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{row?.leadId?.phoneNumber}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{row?.agentId?.name}</Typography>
                    </TableCell>
                    <TableCell>
                      {row?.callRecordingUrl ? (
                        <div>
                          <audio controls>
                            <source src={row.callRecordingUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                          <a href={row.callRecordingUrl} download>
                            Download Recording
                          </a>
                        </div>
                      ) : (
                        <Typography variant="body2">No recording available</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                        {row?.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{fDate(row?.createdAt)}</Typography>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
