import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import RequireAuth from './guards/auth';
import AuthGuard from './guards/protected';
import AllCompaniesPage from './pages/AllCompaniesPage';
import AppointmentPage from './pages/AppointmentPage';
import AssetsPage from './pages/AssetsPage';
import CallPage from './pages/CallPage';
import CallPollingPage from './pages/CallPollingPage';
import CampaignPage from './pages/CampaignPage';
import CompaniesImportPage from './pages/CompaniesImportPage';
import CompaniesPage from './pages/CompaniesPage';
import CompanyDetailsPage from './pages/CompanyDetailsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import DetailsPage from './pages/DetailsPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import LeadPage from './pages/LeadPage';
import LeaderboardPage from './pages/LeaderboardPage';
import LearningPage from './pages/LearningPage';
import LoginPage from './pages/LoginPage';
import MailPage from './pages/MailPage';
import ManageSequencePage from './pages/ManageSequencePage';
import Page404 from './pages/Page404';
import PoolDetailsPage from './pages/PoolDetailsPage';
import ProfilePage from './pages/ProfilePage';
import ResetPasswordPage from './pages/ResetPasswordPage';
// import SequencePage from './pages/SequencePage';
import SignUpPage from './pages/SignupPage';
import SMSPage from './pages/SmsPage';
import TrainingHistoryPage from './pages/TrainingHistoryPage';
import UserPage from './pages/UserPage';
import CallPoolPlayground from './pages/twilio-caller/CallPoolPlayground';
import CallPoolLogPage from './pages/twilio-caller/CallPoolLogPage';
import VoiceCallPage3 from './pages/twilio-caller/VoiceCallPage3';
import TwilioPoolDetailsPage from './pages/TwilioPoolDetailsPage';
import ManualCallPage from './pages/twilio-caller/ManualCallPage';
import CallAnalyticsPage from './pages/CallAnalyticsPage';
import VerifySSOPage from './pages/VerifySSOPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/sequence',
      element: <RequireAuth><DashboardLayout /></RequireAuth>,
      children: [
        { element: <Navigate to="/sequence/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        // { path: 'sequence', element: <SequencePage /> },
        { path: 'manage', element: <ManageSequencePage /> },
        { path: 'leaderboard', element: <LeaderboardPage /> },
        { path: 'companies', element: <AllCompaniesPage /> },
        { path: 'campaigns', element: <CampaignPage/>},
        { path: 'companies/import', element: <CompaniesImportPage /> },
        { path: 'companies/:slug', element: <CompanyDetailsPage /> },
        { path: 'leads', element: <LeadPage /> },
        { path: 'leads/companies', element: <CompaniesPage /> },
        { path: 'leads/:slug/:active', element: <DetailsPage /> },
        { path: 'pool/:phone', element: <PoolDetailsPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'appointments', element: <AppointmentPage /> },
        { path: 'email', element: <MailPage /> },
        { path: 'calls', element: <CallPage /> },
        { path: 'call-analytics', element: <CallAnalyticsPage /> },
        { path: 'sms', element: <SMSPage /> },
        { path: 'assets', element: <AssetsPage /> },
        {path: 'training', element: <LearningPage /> },
        {path: 'polling', element: <CallPollingPage /> },
        {path: 'training/history', element: <TrainingHistoryPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'call-pool-playground', element: <CallPoolPlayground /> },
        { path: 'voice-call3', element: <VoiceCallPage3 /> },
        { path: 'call-pool-logs', element: <CallPoolLogPage /> },
        { path: 'pool-details', element: <TwilioPoolDetailsPage /> },
        { path: 'manual-call', element: <ManualCallPage /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: 'verify-sso',
      element: <VerifySSOPage />,
    },
    {
      path: 'login',
      element: <AuthGuard><LoginPage /></AuthGuard>,
    },
    {
      path: 'signup',
      element: <AuthGuard><SignUpPage /></AuthGuard>,
    },
    {
      path: 'forgot-password',
      element: <AuthGuard><ForgotPasswordPage /></AuthGuard>,
    },
    {
      path: 'reset-password',
      element: <AuthGuard><ResetPasswordPage /></AuthGuard>,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/sequence/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
