import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
    query {
        getOrganization{
        name
        model
        system
        temperature
        }
    }
`;