import React from 'react';
import { Box, styled, Typography } from '@mui/material';

const truncateText = (text, length) => {
  text = text ?? ''; 
  if (text?.length <= length) {
    return text;
  }
  return `${text?.slice(0, length)}...`;
};



const ConnectedCallsMain = ({ connectedCalls }) => {
  return (
    <Wrapper>
      <Typography variant="body1" sx={{ fontSize: '16px' }}>Currently Calling...</Typography>
      {connectedCalls?.length > 0 ? (
        connectedCalls.map((item, index) => (
          <Row key={index}>
            <Typography variant="body1" sx={{ fontSize: '16px' }}>
              Name: <b>{truncateText(`${item?.lead?.firstName} ${item?.lead?.lastName}`, 10)}</b>
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '14px' }}>
              Company: <b>{truncateText(item?.lead?.company, 10)}</b>
            </Typography>
            <Typography variant="body3" sx={{ fontSize: '13px' }}>
              Phone: <b>{item?.lead?.prospectPhoneNumber ?? 'N/A'}</b>
            </Typography>
          </Row>
        ))
      ) : (
        <Typography variant="body1" sx={{ fontSize: '16px' }}>No connected calls</Typography>
      )}
    </Wrapper>
  );
};

export default ConnectedCallsMain;

const Wrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  border: '1px dashed #ccc',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: theme.spacing(1),
  overflowX: 'auto' 
}));

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  border: '1px solid #ccc',
  borderRadius: '4px',
}));
