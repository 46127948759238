import { createSlice } from '@reduxjs/toolkit';
import { getToday } from '../utils/helperFunctions';

const {startDate, endDate} = getToday();

const leadSlice = createSlice({
  name: 'lead',
  initialState: {
    lead: {},
    step: 0,
    stage: '',
    activeLead: 0,
    leads: [],
    selectedAgent: 'all',
    activeDateSelection: 'today',
    dateInterval: { from: startDate, to: endDate }
  },
  reducers: {
    setTargetLeads: (state, action) => {
      state.leads = action.payload;
    },
    setLead: (state, action) => {
      state.lead = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setLeadStage: (state, action) => {
      state.stage = action.payload;
    },
    setActiveLead: (state, action) => {
      state.activeLead = action.payload;
    },
    setSelectedAgent: (state, action) => {
      state.selectedAgent = action.payload;
    },
    setDateInterval:(state, action) => {
      state.dateInterval = action.payload;
    },
    setActiveDateSelection: (state, action) => {
      state.activeDateSelection = action.payload;
    },
  },
});

export const leadReducer = leadSlice.reducer;

export const { setTargetLeads, setLead, setStep, setLeadStage, setActiveLead, setSelectedAgent, setDateInterval, setActiveDateSelection } = leadSlice.actions;
