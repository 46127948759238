import { filter } from 'lodash';
import { useState } from 'react';
// @mui
import {
  Card,
  Table,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Avatar,
} from '@mui/material';
// components
import { useSelector } from 'react-redux';
import DateDisplay from '../../../components/days-to';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { LeadListHead } from '../lead';

const TABLE_HEAD = [
  { id: '' },
  { id: 'simplifiedCompanyName', label: 'Company Name', alignRight: false },
  { id: 'simplifiedCompanyIndustry', label: 'Company Industry', alignRight: false },
  { id: 'jobPostDate', label: 'Last Activity', alignRight: false },
  { id: 'companyPhoneNumber', label: 'Company Phone', alignRight: false },
  { id: 'companyDomainName', label: 'Company Website', alignRight: false },
  { id: 'jobPostTitle', label: 'Job Title', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CompaniesTable() {
  const { leads } = useSelector((state) => state.lead);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = leads.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leads.length) : 0;

  const filteredLeads = applySortFilter(leads, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredLeads.length && !!filterName;

  return (
    <>
      {' '}
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <LeadListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={leads.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredLeads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  // extract leads count from the array
                  const {
                    _id: id,
                    companyLogo,
                    simplifiedCompanyName,
                    simplifiedCompanyIndustry,
                    jobPostDate,
                    companyPhoneNumber,
                    companyDomainName,
                    jobPostTitle,
                  } = row;
                  const selectedLead = selected.indexOf(id) !== -1;

                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedLead}>
                      <TableCell padding="checkbox">
                        <Avatar src={companyLogo} sx={{ width: 40, height: 40 }} />
                        {/* <img src={companyLogo} alt={simplifiedCompanyName} width={40} height={40} /> */}
                      </TableCell>
                      <TableCell align="left">{simplifiedCompanyName}</TableCell>
                      <TableCell align="left">{simplifiedCompanyIndustry}</TableCell>
                      <TableCell align="left">
                        <DateDisplay targetDate={jobPostDate} />
                      </TableCell>
                      <TableCell align="left">{companyPhoneNumber}</TableCell>
                      <TableCell align="left">{companyDomainName}</TableCell>
                      <TableCell align="left">{jobPostTitle}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={leads.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'ph:eye-light'} sx={{ mr: 2 }} />
          Details
        </MenuItem>
        <MenuItem>
          <Iconify icon={'clarity:assign-user-line'} sx={{ mr: 2 }} />
          Assign
        </MenuItem>
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
