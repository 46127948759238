import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { formatFileSize, lightenDarkenColor, useCSVReader } from 'react-papaparse';

const GREY = '#CCC';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    marginTop: 2,
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '50vh',
    justifyContent: 'center',
    padding: 20,
    transition: 'border-color 0.3s ease',
  },
  file: {
    marginTop: 20,
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY,
    borderRadius: 3,
    marginBottom: '0.5em',
    padding: '5px',
    display: 'inline-block',
  },
  name: {
    backgroundColor: GREY,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
    padding: '5px',
    display: 'inline-block',
  },
  progressBar: {
    position: 'absolute',
    width: '100%',
    bottom: '14px',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

const useStyles = {
  cloudUploadIcon: {
    fontSize: '4rem',
    color: GREY,
  },
  uploadText: {
    marginTop: 2,
    color: GREY,
  },
};

export default function Step1({ setColumns, setCsvData}) {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);

  return (
    <CSVReader
      onUploadAccepted={(results) => {
        setCsvData(results?.data);
        setColumns(results?.data[0]);
        setZoneHover(false);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
        <>
          <Box
            {...getRootProps()}
            sx={{
              ...styles.zone,
              ...(zoneHover && styles.zoneHover),
            }}
          >
            {acceptedFile ? (
              <>
                <Box style={styles.file}>
                  <Box style={styles.info}>
                    <Typography variant="body2" sx={styles.size}>
                      {formatFileSize(acceptedFile.size)}
                    </Typography>
                    <Typography variant="caption" sx={styles.name}>
                      {acceptedFile.name}
                    </Typography>
                  </Box>
                  <Box sx={styles.progressBar}>
                    <LinearProgress />
                  </Box>
                  <IconButton
                    {...getRemoveFileProps()}
                    sx={styles.remove}
                    onMouseOver={() => setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT)}
                    onMouseOut={() => setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR)}
                  >
                    <DeleteIcon sx={{ color: removeHoverColor }} />
                  </IconButton>
                </Box>
              </>
            ) : (
              <>
                <CloudUploadIcon sx={useStyles.cloudUploadIcon} />
                <Typography variant="body2" sx={useStyles.uploadText}>
                  Drop CSV file here or click to upload
                </Typography>
              </>
            )}
          </Box>
        </>
      )}
    </CSVReader>
  );
}

Step1.propTypes = {
  setColumns: PropTypes.func,
  setCsvData: PropTypes.func,
};