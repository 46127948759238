import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { isBefore } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { CREATE_APPOINTMENT } from '../../graphql/queries/appointment';


function AppointmentPrompt({ onClose, interactionTarget, activeLead }) {

    const { enqueueSnackbar } = useSnackbar();
    const { user } = useSelector((state) => state.auth);
    
    const[createAppointment, {data, loading, error}] = useMutation(CREATE_APPOINTMENT);

    const EventSchema = Yup.object().shape({
        note: Yup.string().max(5000),
    });

    const methods = useForm({
        resolver: yupResolver(EventSchema),
        defaultValues: {
            title: `${user.firstName} ${user.lastName} & ${interactionTarget.name}`,
            note: 'Let\'s meet and discuss how we can help you.',
            start: new Date(new Date().setMinutes(0, 0, 0) + 60 * 60 * 1000),
            end: new Date(new Date().setMinutes(0, 0, 0) + 60 * 60 * 1000 * 2),
            meetingLink: '',
        },
    });

    const {
        reset,
        watch,
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    const onSubmit = async (data) => {
        try {
           createAppointment({
                variables: {
                    input: {
                        ...data,
                        lead: activeLead?._id,
                    },
                },
            });
          
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    useEffect(() => {
        if (data) {
            enqueueSnackbar('Appointment created successfully', { variant: 'success' });
            onClose({});
            reset();
        }
    }, [data]);


    const isDateError =
        !values.allDay && values.start && values.end
            ? isBefore(new Date(values.end), new Date(values.start))
            : false;


    return (
        <Stack direction="row" spacing={2}>
            <Grid onSubmit={handleSubmit(onSubmit)} component="form" container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Stack direction='column' spacing={2}>
                        <Controller
                            name="title"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    label="Title"
                                    value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                                    error={!!error}
                                    helperText={error?.message ? error?.message : ''}
                                />
                            )}
                        />

                        <Controller
                            name="start"
                            control={control}
                            render={({ field }) => (
                                <MobileDateTimePicker
                                    {...field}

                                    onChange={(newValue) => field.onChange(newValue)}
                                    label="Start date"
                                    inputFormat="dd/MM/yyyy hh:mm a"
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            )}
                        />
                        <Controller
                            name="end"
                            control={control}
                            render={({ field }) => (
                                <MobileDateTimePicker
                                    {...field}
                                    onChange={(newValue) => field.onChange(newValue)}
                                    label="End date"
                                    inputFormat="dd/MM/yyyy hh:mm a"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}

                                            fullWidth
                                            error={!!isDateError}
                                            helperText={isDateError && 'End date must be later than start date'}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Stack>

                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack direction='column' spacing={2}>
                    <Controller
                            name="meetingLink"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    label="Meeting Link"
                                    value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                                    error={!!error}
                                    helperText={error?.message ? error?.message : ''}
                                />
                            )}
                        />
                    <Controller
                            name="note"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label="Note"
                                    value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                                    error={!!error}
                                    helperText={error?.message ? error?.message : ''}
                
                                />
                            )}
                        />
                       
                    </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Stack direction="row" spacing={2}>

                        <Button variant="outlined" color="inherit" onClick={() => onClose({})}>
                            Cancel
                        </Button>

                        <LoadingButton type="submit" variant="contained" loading={isSubmitting || loading}>
                            {'Add'}
                        </LoadingButton>
                    </Stack>
                </Grid>
            </Grid>

        </Stack>
    );
}

export default AppointmentPrompt;
