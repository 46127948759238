import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Stack, TextField } from '@mui/material';
// components
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FORGOT_PASSWORD } from '../../../graphql/mutations/auth';

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [forgotPassword, { data, loading, error }] = useMutation(FORGOT_PASSWORD);
  const {enqueueSnackbar} = useSnackbar();

  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    forgotPassword({
      variables: { input: data },
    });
  };

  useEffect(() => {
    if (data && data.forgotPassword) {
      enqueueSnackbar('Email sent successfully', {variant: 'success'});
      navigate('/login', { replace: true });
    }
  }, [data, dispatch, navigate, enqueueSnackbar]);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ my: 2 }}>
       {error && <Alert severity="error">{error?.message}</Alert>}
        <TextField name="email" label="Email address" {...register('email', { required: true })} />
      </Stack>

      <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained">
        Send Request
      </LoadingButton>
    </form>
  );
}
