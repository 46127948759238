import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { GET_ALL_ASSETS } from '../../../graphql/queries/assets';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function SelectAssetsDialog({ openSelectAsset, handleAddToAttachments, handleClose }) {
    const { data, loading, error } = useQuery(GET_ALL_ASSETS)
    const [docs, setDocs] = useState([]);
    const [filteredDocs, setFilteredDocs] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (data?.getAssets) {
            setDocs(data.getAssets);
        }
    }, [data]);

    useEffect(() => {
        if (search) {
            const filteredDocs = docs.filter((doc) => doc.title.toLowerCase().includes(search.toLowerCase()) || doc.category.toLowerCase().includes(search.toLowerCase()));
            setFilteredDocs(filteredDocs);
        } else {
            setFilteredDocs(docs);
        }
    }, [search, docs]);

    const handleAddAssetToAttachments = (asset) => {
        handleAddToAttachments(asset);
        handleClose();
    }

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openSelectAsset}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Select Assets
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item sm={12} md={12}>
                            <TextField name="search" fullWidth label="Search" placeHolder="Start typing..." value={search} onChange={(e) => setSearch(e.target.value)} />
                        </Grid>
                        {filteredDocs.map((pdf, index) => (
                            <Grid item sm={12} md={6} key={index}>
                                <Card sx={{ p: 2 }}>
                                    {pdf?.url?.endsWith('.pdf') ? (
                                        <iframe title={`PDF ${index + 1}`} src={pdf.url} style={{ width: '100%', height: '390px' }} />
                                    ) : (
                                        <CardMedia
                                            component="img"
                                            alt="Placeholder"
                                            height="400"
                                            image="/assets/icons/file-minus-alt-svgrepo-com.svg"
                                        />
                                    )}
                                    <Typography variant="body1" align="center">
                                        {pdf?.title}
                                    </Typography>
                                    <CardActions
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Button size="small" onClick={() => handleAddAssetToAttachments(pdf?.url)}>
                                            Select
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

SelectAssetsDialog.propTypes = {
    openSelectAsset: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleAddToAttachments: PropTypes.func.isRequired,
};

export default SelectAssetsDialog;
