import { Card, CardContent, CircularProgress, Divider, Stack, Typography } from '@mui/material';
// hooks
// components
// utils
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CompanyContactCard from '../../../components/company-contact-card';
import ContactCard from '../../../components/contact-card';
import ContactDetailedInformation from '../../../components/contact-details';
import OptOutConfirmationDailog from './OptOutConfirmationDailog';

function ContactDetails({
  activeLead,
  contacts,
  loadingGettingContacts,
  loadingCompanyDefaultContact,
  callLogsData,
  emailLogsData,
  handleCreateOrUpdateDefaultContact,
  activeCompanyDefaultContact,
  setOpenContactDetails,
  targetContact,
  openContactDetails,
  handleOpenMenuOptOut,
  openOptOutDialog,
  handleCloseOptOutDialog,
  setInteractionTarget,
  setInteractionType,
  interactionTarget,
}) {
  const [companyContacts, setCompanyContacts] = useState([]);

  useEffect(() => {
    if (contacts) {
      setCompanyContacts(contacts);
    }
  }, [contacts]);

  const renderContactCards = () => (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
        <Typography variant="h6">Other Prospects</Typography>{' '}
        <Typography variant="h6">{`Total: ${companyContacts ? companyContacts?.length : 0}`}</Typography>
      </Stack>
      <Divider />
      {loadingGettingContacts || loadingCompanyDefaultContact ? (
        <CircularProgress />
      ) : (
        !loadingCompanyDefaultContact &&
        companyContacts
          ?.filter((contact) => contact.prospectPhoneNumber !== activeCompanyDefaultContact?.phone)
          ?.map((contact) => (
            <ContactCard
              key={contact._id}
              contact={contact}
              callLogsData={callLogsData}
              emailLogsData={emailLogsData}
              handleCreateOrUpdateDefaultContact={handleCreateOrUpdateDefaultContact}
              activeCompanyDefaultContact={activeCompanyDefaultContact}
              setOpenContactDetails={setOpenContactDetails}
              handleOpenMenuOptOut={handleOpenMenuOptOut}
              setInteractionTarget={setInteractionTarget}
              setInteractionType={setInteractionType}
            />
          ))
      )}
      {!loadingCompanyDefaultContact && activeCompanyDefaultContact?.name !== activeLead?.simplifiedCompanyName && (
        <CompanyContactCard
          activeLead={activeLead}
          callLogsData={callLogsData}
          emailLogsData={emailLogsData}
          handleCreateOrUpdateDefaultContact={handleCreateOrUpdateDefaultContact}
          activeCompanyDefaultContact={activeCompanyDefaultContact}
          setInteractionTarget={setInteractionTarget}
          setInteractionType={setInteractionType}
        />
      )}
    </>
  );

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        {openContactDetails && ( interactionTarget?.email?.length > 0 || interactionTarget?.phone?.length > 0) ? (
          <CardContent sx={{ padding: 0 }}>
            <ContactDetailedInformation
              contact={
                companyContacts?.find((contact) => contact.prospectPhoneNumber === interactionTarget?.phone) ||
                companyContacts?.find((contact) => contact.emailAddress === interactionTarget?.email)
              }
              setOpenContactDetails={setOpenContactDetails}
              activeLead={activeLead}
              handleOpenMenuOptOut={handleOpenMenuOptOut}
              setInteractionTarget={setInteractionTarget}
              setInteractionType={setInteractionType}
            />
          </CardContent>
        ) : (
          <CardContent sx={{ padding: 0 }}>{renderContactCards()}</CardContent>
        )}
      </Card>
      <OptOutConfirmationDailog
        open={openOptOutDialog}
        handleClose={handleCloseOptOutDialog}
        targetContact={targetContact}
        setCompanyContacts={setCompanyContacts}
      />
    </>
  );
}

ContactDetails.propTypes = {
  activeLead: PropTypes.object,
  contacts: PropTypes.array,
  loadingGettingContacts: PropTypes.bool,
  loadingCompanyDefaultContact: PropTypes.bool,
  callLogsData: PropTypes.array,
  emailLogsData: PropTypes.array,
  openEmailPrompt: PropTypes.bool,
  handleCreateOrUpdateDefaultContact: PropTypes.func,
  activeCompanyDefaultContact: PropTypes.object,
  setOpenContactDetails: PropTypes.func,
  targetContact: PropTypes.object,
  openContactDetails: PropTypes.bool,
  handleOpenMenuOptOut: PropTypes.func,
  openOptOutDialog: PropTypes.bool,
  handleCloseOptOutDialog: PropTypes.func,
  setInteractionTarget: PropTypes.func,
  setInteractionType: PropTypes.func,
  interactionTarget: PropTypes.object,
};

export default ContactDetails;
