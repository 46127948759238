import { gql } from '@apollo/client';

export const LOG_CALL = gql`
  mutation createCallLog($input: CallLogInput!) {
    createCallLog(input: $input) {
      _id
      callDone
      voicemailDone
      talkDone
      name
      step
      phoneNumber
      recordingUrl
      createdAt
    }
  }
`;

export const LOG_CALL_MANUAL = gql`
  mutation createManualCallLog($input: ManualCallLogInput!) {
    createManualCallLog(input: $input){
      phoneNumber
    }
  }
`;

export const LOG_TIMER = gql`
  mutation logTimer($input: TimerLogInput!) {
    logTimer(input: $input) {
      _id
      time
      startDate
      endDate
      createdAt
    }
  }
`;

export const LOG_TIMER_T = gql`
  mutation logTimerT($input: TimerLogInput!) {
    logTimerT(input: $input) {
      _id
      time
      startDate
      endDate
      createdAt
    }
  }
`;

export const LOG_SMS = gql`
  mutation createSMSLog($input: SMSLogInput!) {
    createSMSLog(input: $input) {
      _id
      smsDone
      name
      step
      phoneNumber
      createdAt
    }
  }
`;

export const LINKEDIN_CONNECT_LOG = gql`
  mutation createLinkedInConnect($input: LinkedInConnectInput!) {
    createLinkedInConnect(input: $input) {
      _id
      connectRequestSent
      name
    }
  }
`;

export const LINKEDIN_MESSAGE_LOG = gql`
  mutation createLinkedinMessage($input: LinkedinMessageInput!) {
    createLinkedinMessage(input: $input) {
      _id
      messageSent
      name
    }
  }
`;

export const LOG_EMAIL = gql`
  mutation createEmailLog($input: EmailLogInput!) {
    createEmailLog(input: $input) {
      _id
      emailSent
      emailPersonalized
      name
      step
      email
      createdAt
    }
  }
`;

export const GET_LEAD_CALL_LOGS = gql`
  query getLeadCallLogs($lead: String!) {
    getLeadCallLogs(lead: $lead) {
      _id
      callDone
      voicemailDone
      talkDone
      name
      step
      phoneNumber
      createdAt
    }
  }
`;

export const GET_LEAD_EMAIL_LOGS = gql`
  query getLeadEmailLogs($lead: String!) {
    getLeadEmailLogs(lead: $lead) {
      _id
      emailSent
      emailPersonalized
      name
      step
      email
      createdAt
    }
  }
`;
