import { gql } from '@apollo/client';

export const GET_LEAD_NOTES = gql`
  query getNoteByLead($lead: String!) {
    getNoteByLead(lead: $lead) {
      _id,
      user {
        _id
        firstName
        lastName
        email
      }
      notes
      attachment
      createdAt
    }
  }
`;
