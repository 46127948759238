import { gql } from '@apollo/client';

export const UPDATE_TRAINING = gql`
    mutation updateModelTrainingMultiple($input: [ModelTrainingInput!]!) {
        updateModelTrainingMultiple(input: $input) {
            _id
            objection
            response
            preContext
        }
    }
`;

export const CREATE_TRAINING = gql`
  mutation createModelTraining($input: ModelTrainingInput!) {
    createModelTraining(input: $input) {
     _id
      preContext
      objection
      response
    }
  }
`;

export const CREATE_TRAINING_MULTIPLE = gql`
    mutation createModelTrainingMultiple($input: ModelTrainingMultipleInput!) {
      createModelTrainingMultiple(input: $input) {
      _id
        preContext
        objection
        response
      }
    } 
`;

export const UPDATE_TRAINING_SINGLE = gql`
  mutation updateModelTraining($input: ModelTrainingInput!, $_id: String!) {
    updateModelTraining(_id: $_id, input: $input) {
      _id
      preContext
      objection
      response
    }
  }
`;

export const UPDATE_MULTIPLE_TRAINING = gql`
  mutation updateMultipleTrainingMultiple($input: ModelTrainingMultipleInput!, $_id: String!) {
    updateMultipleTrainingMultiple(input: $input, _id:$_id) {
    _id
      preContext
      objection
      response
    }
  }
`;

export const UPDATE_ORGANIZATION_MODEL = gql`
  mutation updateOrganizationModel($model: String!) {
    updateOrganizationModel(model: $model) {
    _id
    model
    }
  }
`;

export const UPDATE_ORGANIZATION_CAMPAIGN = gql`
  mutation updateOrganizationCampaign($campaign: String!) {
    updateOrganizationCampaign(campaign: $campaign) {
    _id
    campaign
    }
  }`;

export const DELETE_TRAINING_MULTIPLE = gql`
  mutation deleteModelTrainingMultiple($_id: String!) {
    deleteModelTrainingMultiple(_id: $_id) {
    _id
    }
  }
`;

export const DELETE_TRAINING = gql`
  mutation deleteModelTraining($_id: String!) {
    deleteModelTraining(_id: $_id) {
    _id
    }
  }
`;

export const UPDATE_ORGANIZATION_AI_SETTINGS = gql`
  mutation updateOrganizationTemperatureAndSystem($temperature: Float!, $system: String!) {
    updateOrganizationTemperatureAndSystem(temperature: $temperature, system: $system) {
      temperature
      system
    }
  }
`;