import { useLazyQuery } from '@apollo/client';
import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { GET_PITCH_BY_JOB_TITLE } from '../../graphql/queries/pitch';
import { INTERRACTION_TYPES } from '../../utils/constants';
import { openLinkInNewTab } from '../../utils/helperFunctions';

function ContactDetailedInformation({
  contact,
  setOpenContactDetails,
  activeLead,
  handleOpenMenuOptOut,
  setInteractionTarget,
  setInteractionType,
}) {

  const [pitches, setPitches] = useState([]);

  const [getPitchByJobTitle, { data, loading }] = useLazyQuery(GET_PITCH_BY_JOB_TITLE);

  useEffect(() => {
    if (contact?.prospectDesignation) {
      getPitchByJobTitle({
        variables: { jobTitle: contact?.prospectDesignation },
      });
    }
  }, [contact?.prospectDesignation]);


  useEffect(() => {
    if (data?.getPitchByJobTitle) {
      setPitches(data?.getPitchByJobTitle);
    }
  }, [data?.getPitchByJobTitle]);


  const initConv = (type = "phone") => {
    setInteractionTarget({
      name: `${contact?.firstName} ${contact?.lastName}`,
      email: contact?.emailAddress,
      phone: type === "phone" ? contact?.prospectPhoneNumber : contact?.prospectDirectPhoneNumber,
      linkedin: contact?.prospectLinkedinProfile,
    });
  }

  const handlePhoneCall = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.CALL);
  };

  const handlePhoneCallDirect = () => {
    initConv("direct");
    setInteractionType(INTERRACTION_TYPES.CALL);
  };

  const handleSendSMS = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.TEXT);
  };

  const handleSendEmail = () => {
    initConv();
    setInteractionType(INTERRACTION_TYPES.EMAIL);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 2,
          backgroundColor: '#000',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={activeLead?.companyLogo} alt="company logo" width="50px" height="50px" />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2, width: '100%' }}>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Button
            color="inherit"
            size="small"
            startIcon={<ArrowBack />}
            onClick={() => {
              setOpenContactDetails(false);
            }}
          >
            {' '}
            See other
          </Button>
          <Button
            size="small"
            startIcon={<img src="/assets/icons/ic_edit.svg" width="30" alt="edit" />}
            color="inherit"
            variant="outlined"
          >
            Edit Prospect
          </Button>
        </Stack>
        <Stack direction="column" spacing={0}>
          <Typography variant="h5">
            {contact?.firstName} {contact?.lastName}{' '}
          </Typography>
          <Typography variant="caption" color="inherit">
            {contact?.emailAddress}
          </Typography>
          <Typography variant="body2">
            {contact?.prospectDesignation && `${contact?.prospectDesignation}@`}
            <b>{contact?.company}</b>
          </Typography>
        </Stack>

        <Stack direction="column" spacing={0}>
          <Typography variant="caption" color="inherit">
            Role Description:
          </Typography>
          <Typography variant="body2">{contact?.prospectDescription}</Typography>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="caption" color="primary">
            Location:
          </Typography>
          <Typography variant="body2">{contact?.prospectLocation}</Typography>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="caption" color="primary">
            Phone:
          </Typography>
          <Typography variant="body2">{contact?.prospectPhoneNumber}</Typography>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="caption" color="primary">
            Direct Phone:
          </Typography>
          <Typography variant="body2">{contact?.prospectDirectPhoneNumber}</Typography>
        </Stack>

        <Stack direction="column" spacing={1}>
          <Typography variant="caption" color="primary">
            LinkedIn:
          </Typography>
          {contact?.prospectLinkedinProfile && (
            <Link href="#" onClick={() => openLinkInNewTab(contact?.prospectLinkedinProfile)}>
              {contact?.prospectLinkedinProfile}
            </Link>
          )}
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<img src="/assets/icons/ic_call.svg" alt="call" width={20} />}
            onClick={handlePhoneCall}
          >
            {`Call ${contact?.prospectPhoneNumber}`}
          </Button>
          {contact?.prospectDirectPhoneNumber &&
            <Button
              variant="outlined"
              color="inherit"
              startIcon={<img src="/assets/icons/ic_call.svg" alt="call" width={20} />}
              onClick={handlePhoneCallDirect}
            >
              {`Call Direct ${contact?.prospectDirectPhoneNumber}`}
            </Button>}
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<img src="/assets/icons/ic_sms.svg" alt="message" width={20} />}
            onClick={handleSendSMS}
          >
            Message
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<img src="/assets/icons/ic_email.svg" alt="email" width={20} />}
            onClick={handleSendEmail}
          >
            Email
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<img src="/assets/icons/ic_optout.svg" alt="opt out" width={20} />}
            onClick={() => handleOpenMenuOptOut(contact)}
          >
            Opt Out
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

ContactDetailedInformation.propTypes = {
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    prospectDesignation: PropTypes.string,
    prospectPhoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    prospectDescription: PropTypes.string,
    prospectLocation: PropTypes.string,
    prospectLinkedinProfile: PropTypes.string,
    company: PropTypes.string,
  }),
  activeLead: PropTypes.object,
  setOpenContactDetails: PropTypes.func,
  handleOpenMenuOptOut: PropTypes.func,
  setInteractionTarget: PropTypes.func,
  setInteractionType: PropTypes.func,
};

export default ContactDetailedInformation;
