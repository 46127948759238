import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_CALL_SUMMARY_STATS } from '../../graphql/queries/call.analytics';

const useCallSummaryStats = (dateInterval) => {
  const [getCallSummaryStats, { data, loading, error }] = useLazyQuery(GET_CALL_SUMMARY_STATS);

  useEffect(() => {
    if (dateInterval) {
      getCallSummaryStats({ variables: { input: dateInterval } });
    }
  }, [dateInterval, getCallSummaryStats]);

  return { data: data?.getCallSummaryStats, loading, error };
};

export default useCallSummaryStats;
