import { Helmet } from 'react-helmet-async';
import { SmsView } from '../sections/sms/view';
// sections

// ----------------------------------------------------------------------
export default function SMSPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: SMS</title>
      </Helmet>

      <SmsView />
    </>
  );
}
