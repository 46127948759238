import { createSlice } from '@reduxjs/toolkit';

const twilioSlice = createSlice({
  name: 'twilio',
  initialState: {
    activeLead: {},
    activeAgents: [],
    queuedLeads: [],
    callLogs: [],
    connectedCalls: [],
  },
  reducers: {
    setActiveLead: (state, action) => {
      state.activeLead = action.payload;
    },
    setActiveAgents: (state, action) => {
      state.activeAgents = action.payload;
    },
    addActiveAgent: (state, action) => {
      const newAgent = action.payload;
      const existingIndex = state.activeAgents.findIndex(agent => agent._id === newAgent._id);
      if (existingIndex !== -1) {
        state.activeAgents.splice(existingIndex, 1);
      }
      state.activeAgents.unshift(newAgent);
    },
    removeActiveAgent: (state, action) => {
      return {
        ...state,
        activeAgents: state.activeAgents.filter(agent => agent._id !== action.payload),
      };
    },
    setQueuedLeads: (state, action) => {
      state.queuedLeads = action.payload;
    },
    addLeadToQueue: (state, action) => {
      return {
        ...state,
        queuedLeads: [action.payload, ...state.queuedLeads],
      };
    },
    removeLeadFromQueue: (state, action) => {
      return {
        ...state,
        queuedLeads: state.queuedLeads.filter(lead => lead._id !== action.payload),
      };
    },
    setCallLogs: (state, action) => {
      state.callLogs = action.payload;
    },
    addToConnectedCall: (state, action) => {
      const callWithTimestamp = {
        ...action.payload,
        createdAt: new Date().toISOString(),
      };
      state.connectedCalls.unshift(callWithTimestamp);
    },
    removeExpiredConnectedCalls: (state) => {
      const now = Date.now();
      const expirationTime = 10 * 1000; // 10 seconds in milliseconds

      state.connectedCalls = state.connectedCalls.filter(call => {
        return (now - new Date(call.createdAt).getTime()) < expirationTime;
      });
    },
  },
});

export const twilioReducer = twilioSlice.reducer;

export const { setActiveLead, setActiveAgents, addActiveAgent, removeActiveAgent, setQueuedLeads, addLeadToQueue, removeLeadFromQueue, setCallLogs, addToConnectedCall, removeExpiredConnectedCalls } = twilioSlice.actions;
