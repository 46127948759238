import { useMutation } from '@apollo/client';
import { Check, Close, Delete as DeleteIcon, Edit } from '@mui/icons-material';
import { CircularProgress, IconButton, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { UPDATE_TRAINING_SINGLE } from '../../../graphql/mutations/training';

const TrainingCampRow = ({ row, handleDeleteTraining }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [updateModelTraining, { data, loading }] = useMutation(UPDATE_TRAINING_SINGLE);
    const [content, setContent] = useState({
        preContext: "",
        objection: "",
        response: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContent({
            ...content,
            [name]: value,
        });
    };

    const [edit, setEdit] = useState(false);

    const handleUpdate = () => {
        updateModelTraining({
            variables: {
                _id: row._id,
                input: {
                    preContext: content.preContext,
                    objection: content.objection,
                    response: content.response,
                },
            },
        });
    };

    useEffect(() => {
        setContent(row);
    }, [row]);

    useEffect(() => {
        if (data) {
            setEdit(false);
            enqueueSnackbar("Training data saved successfully", { variant: "success" });
        }
    }, [data]);

    return (
        <TableRow>
            {edit ? (
                <>
                    <TableCell>
                        <TextField
                            fullWidth
                            variant='outlined'
                            multiline
                            rows={3}
                            name='objection'
                            value={content.objection}
                            onChange={handleInputChange}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            fullWidth
                            variant='outlined'
                            multiline
                            rows={3}
                            name='response'
                            value={content.response}
                            onChange={handleInputChange}
                        />
                    </TableCell>
                    <TableCell>
                        {loading ? (<CircularProgress size={20} />) : (
                            <>
                                <IconButton onClick={handleUpdate}>
                                    <Check />
                                </IconButton>
                                <IconButton onClick={() => setEdit(false)}>
                                    <Close />
                                </IconButton>
                            </>
                        )}
                    </TableCell>
                </>
            ) : (
                <>
                    <TableCell>
                        <Typography variant='body2'>
                            {content.objection}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='body2'>
                            {content.response}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={() => setEdit(true)}>
                            <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteTraining(row)}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                </>
            )}
        </TableRow>
    )
}

export default TrainingCampRow;