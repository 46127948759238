import { Helmet } from 'react-helmet-async';
// @mui
import {
    Breadcrumbs,
    Button,
    Container,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// sections

import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Loader from '../components/loader';
import { GET_ALL_SEQUENCE, UPDATE_SEQUENCE } from '../graphql/queries/sequence';
import { LABELS, SEQUENCE_TEMPLATE } from '../utils/constants';


export default function ManageSequencePage() {
    const { enqueueSnackbar } = useSnackbar();
    const { data: sequenceData, loading: loadingSequenceData } = useQuery(GET_ALL_SEQUENCE);
    const [updateSequenceMultiple, { data: updateData, loading: loadingUpdate }] = useMutation(UPDATE_SEQUENCE);
    const connnectTypeOptions = [{
        value: 'LINKEDINCONNECT',
        label: 'LinkedIn Connect'
    },
    {
        value: 'LINKEDINMESSAGE',
        label: 'LinkedIn Message'
    },
    {
        value: 'EMAIL',
        label: 'Email'
    },
    {
        value: 'CALL',
        label: 'Call'
    },
    {
        value: 'TEXT',
        label: 'Text'
    },
    {
        value: 'SLACK',
        label: 'Slack'
    },
    {
        value: 'VOICEMAIL',
        label: 'Voicemail'
    },
    {
        value: 'SOCIAL',
        label: 'Social'
    }];

    const [data, setData] = useState([
        { step: 1, stage: 'Stage 1', title: 'Title 1', description: 'Description 1', type: 'LINKEDINCONNECT' },
        { step: 2, stage: 'Stage 2', title: 'Title 2', description: 'Description 2', type: 'EMAIL' },
        // Add more initial data as needed
    ]);

    const handleEdit = (index, field, value) => {
        const newData = [...data];
        newData[index][field] = value;
        setData(newData);
    };

    const handleUseTemplate = () => setData(SEQUENCE_TEMPLATE);

    const handleAddRow = () => {
        const newRow = { _id: String(data.length + 1), step: '', stage: '', title: '', description: '', type: '' };
        setData([...data, newRow]);
    };

    const handleDeleteRow = (index) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };

    useEffect(() => {
        if (sequenceData?.getSequence) {
            const data = sequenceData?.getSequence.map(({ _id, __typename, ...rest }) => rest);
            if (data?.length) {
                setData(data);
            }
        }
    }, [sequenceData?.getSequence]);

    useEffect(() => {
        if (updateData?.updateSequenceMultiple) {
            enqueueSnackbar('Sequence updated successfully', { variant: 'success' });
            const data = updateData?.updateSequenceMultiple.map(({ _id, __typename, ...rest }) => rest);
            if (data?.length) {
                setData(data);
            }
        }
    }, [updateData?.updateSequenceMultiple, enqueueSnackbar]);

    const handleSubmit = () => {
        const input = data.map(({ _id, ...rest }) => rest);
        updateSequenceMultiple({ variables: { input } });
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            Sequence
        </Link>,
        <Typography key="3" color="text.primary">
            Manage
        </Typography>,
    ];

    return (
        <>
            <Helmet>
                <title> Manage Sequence | Auto Caller UI </title>
            </Helmet>
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                            <Stack direction="column" spacing={0} alignItems="flex-start">
                                <Typography variant="h3">{LABELS.ManageSequence}</Typography>
                                <Typography variant="caption">{LABELS.ManageSequenceDescription}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    startIcon={<img src="/assets/icons/ic_leads.svg" alt="import leads" />}
                                    onClick={handleUseTemplate}
                                    sx={{
                                        color: 'text.primary',
                                        textTransform: 'none',
                                        border: '1px solid rgba(145, 158, 171, 0.24)',
                                    }}
                                >
                                    Use template
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                    {loadingSequenceData && <Grid item xs={12} sm={12} md={12}>  <Loader /></Grid>}
                    {!loadingSequenceData && (
                        <>
                            <Grid item xs={12} sm={12} md={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Step</TableCell>
                                                <TableCell>Stage</TableCell>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((row, index) => (
                                                <TableRow key={row.step}>
                                                    <TableCell>
                                                        <TextField
                                                            value={row.step}
                                                            onChange={(e) => handleEdit(index, 'step', e.target.value)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            value={row.stage}
                                                            onChange={(e) => handleEdit(index, 'stage', e.target.value)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            value={row.title}
                                                            onChange={(e) => handleEdit(index, 'title', e.target.value)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            value={row.description}
                                                            onChange={(e) => handleEdit(index, 'description', e.target.value)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Select
                                                            value={row.type}
                                                            sx={{ width: 200 }}
                                                            onChange={(e) => handleEdit(index, 'type', e.target.value)}
                                                        >
                                                            {connnectTypeOptions.map((type) => (
                                                                <MenuItem key={type.value} value={type.value}>
                                                                    {type.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={() => handleDeleteRow(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                    <Button onClick={handleAddRow} variant="contained" color="primary" sx={{ m: 2 }}>
                                        Add Row
                                    </Button>

                                </TableContainer>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={loadingUpdate}
                                        onClick={handleSubmit}
                                        sx={{ textTransform: 'none' }}
                                    >
                                        {loadingUpdate ? 'Saving...' : 'Save Changes'}
                                    </Button>
                                </Stack>
                            </Grid>
                        </>)}
                </Grid>
            </Container>
        </>
    );
}
