import { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  CircularProgress,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { DELETE_LEAD } from '../../../graphql/mutations/lead';
import { fDate } from '../../../utils/formatTime';
import { LeadListHead, LeadListToolbar } from '../lead';
import CompanyDeleteConfirmationDialog from './CompanyDeleteConfirmationDialog';
import EditCompanyDialog from './EditCompanyDialog';

const TABLE_HEAD = [
  { id: '' },
  { id: 'simplifiedCompanyName', label: 'Company Name', alignRight: false },
  { id: 'simplifiedCompanyIndustry', label: 'Company Industry', alignRight: false },
  { id: 'companyPhoneNumber', label: 'Company Phone', alignRight: false },
  { id: 'companyDomainName', label: 'Company Website', alignRight: false },
  { id: 'jobPostTitle', label: 'Job Title', alignRight: false },
  { id: 'created_at', label: 'Uploaded', alignRight: false },
  { id: 'optOut', label: 'Opted Out', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((lead) =>
      lead.simplifiedCompanyName.toLowerCase().includes(query.toLowerCase())
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function CompaniesTable({ leads, onLoadMore, loading, totalCount }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [allLeads, setAllLeads] = useState(leads);
  const [open, setOpen] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('simplifiedCompanyName');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [filterName, setFilterName] = useState('');
  const [openEditCompanyDialog, setOpenEditCompanyDialog] = useState(false);
  const [targetCompany, setTargetCompany] = useState({});

  const [deleteLead] = useMutation(DELETE_LEAD);

  useEffect(() => {
    console.log("Leads...SHH", leads?.length > 0);
    if (leads?.length > 0) {
      setAllLeads(leads);
    }
  }, [leads]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allLeads.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      onLoadMore(newPage);
    }
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditCompanyDialogOpen = (company) => {
    setTargetCompany(company);
    setOpenEditCompanyDialog(true);
    setOpen(null);
  };

  const handleEditCompanyDialogClose = () => {
    setOpenEditCompanyDialog(false);
  };

  const handleDeleteCompanyConfirm = () => {
    deleteLead({ variables: { leadId: targetCompany._id } })
      .then(() => {
        enqueueSnackbar('Company deleted successfully', { variant: 'success' });
        setAllLeads((prevLeads) =>
          prevLeads.filter((lead) => lead._id !== targetCompany._id)
        );
        setOpenDelete(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setOpenDelete(false);
      });
  };

  const handleOpenDelete = (company) => {
    setTargetCompany(company);
    setOpenDelete(true);
    setOpen(null);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allLeads.length) : 0;

  const filteredLeads = applySortFilter(
    allLeads,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredLeads.length && !!filterName;

  return (
    <>
      <Card>
        <LeadListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={(e) => setFilterName(e.target.value)}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <LeadListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={allLeads.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {filteredLeads
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        _id: id,
                        companyLogo,
                        simplifiedCompanyName,
                        simplifiedCompanyIndustry,
                        companyPhoneNumber,
                        companyDomainName,
                        jobPostTitle,
                        optOut,
                        optOutReason,
                        created_at: createdAt,
                      } = row;
                      const selectedLead = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedLead}
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            navigate(`/sequence/companies/${id}`)
                          }
                        >
                          <TableCell align="left">
                            <Avatar
                              src={companyLogo}
                              alt={simplifiedCompanyName}
                              sx={{ width: 40, height: 40 }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {simplifiedCompanyName}
                          </TableCell>
                          <TableCell align="left">
                            {simplifiedCompanyIndustry}
                          </TableCell>
                          <TableCell align="left">
                            {companyPhoneNumber}
                          </TableCell>
                          <TableCell align="left">
                            {companyDomainName}
                          </TableCell>
                          <TableCell align="left">{jobPostTitle}</TableCell>
                          <TableCell align="left">
                            {fDate(createdAt)}
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              color={
                                optOut === true ? 'error.main' : 'primary'
                              }
                            >
                              {optOutReason || 'No'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
              )}
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                      <Paper sx={{ textAlign: 'center' }}>
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>
                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete
                          words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[100, 200, 300]}
          component="div"
          count={totalCount} // Update this line
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

CompaniesTable.propTypes = {
  leads: PropTypes.array.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CompaniesTable;
