import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

export default function Step3(props) {
  const { columns, csvData, columnKeys } = props;
  const data = csvData?.slice(1);
  const dataForSelectedColumns = useMemo(() =>
    data.map(
      (row) => {
        const obj = {};
        columns.forEach((column, index) => {
          if (columnKeys[column] && columnKeys[column] !== '') {
            if(!obj[columnKeys[column]]) obj[columnKeys[column]] = row[index];
          }
        });
        return obj;
      },
      [data, columns, columnKeys]
    )
  );

  const selecteColumns = useMemo(() => columns.filter((column) => columnKeys[column] && columnKeys[column] !== ''), [columns, columnKeys]);
  
  const selectedColumnsByColumnKeys = useMemo(() => selecteColumns.map((column) => columnKeys[column])
  , [selecteColumns, columnKeys]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {selectedColumnsByColumnKeys.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataForSelectedColumns.map((row, index) => (
            <TableRow key={index}>
              {selectedColumnsByColumnKeys.map((column) => (
                <TableCell key={column}>{row[column]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

Step3.propTypes = {
  columns: PropTypes.array,
  csvData: PropTypes.array,
  columnKeys: PropTypes.object,
};
