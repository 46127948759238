import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_LEADS_REPORT } from '../../graphql/queries/lead';

export default function ReportConfirmationDialog({open, handleClose}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [reports, setReports] = React.useState([]);
  const [getAllLeadsReport, {data, loading, error} ]= useLazyQuery(GET_ALL_LEADS_REPORT)
  const {register, reset, handleSubmit } = useForm();

  const onSubmit = (data) => {
    getAllLeadsReport({
      variables: {
        input: {
          from: data.from,
          to: data.to
        }
      }
    })
  }

  React.useEffect(() => {
    if (data) {
      setReports(data.getAllLeadsReport)
    }
  }, [data]);
   
  return (

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Leads Report"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
             Kindly select date interval to generate report
          </DialogContentText>
          <Stack direction="row" sx={{mt: 2}} spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              id="date"
              label="From"
              type="date"
              name='from'
              defaultValue={new Date().toISOString().slice(0, 10)}
              InputLabelProps={{
                shrink: true,
              }}
              {...register('from', { required: true })}

            />
            <TextField
              id="date"
              label="To"
              name='to'
              type="date"
              defaultValue={new Date().toISOString().slice(0, 10)}
              InputLabelProps={{
                shrink: true,
              }}
               {...register('to', { required: true })}
            />
            <Button variant="contained" color="primary" type='submit'>
                Generate
            </Button>
            </Stack>
            {loading && <p>Loading...</p>}
            {!loading && reports.length > 0 && (
                <table>
                    <thead>
                    <tr>
                        <th>Lead</th>
                        <th>Call Done</th>
                        <th>Talk Done</th>
                        <th>Voicemail Done</th>
                        <th>Email Sent</th>
                        <th>Email Personalized</th>
                        <th>Created At</th>
                    </tr>
                    </thead>
                    <tbody>
                    {reports.map((report) => (
                        <tr key={report._id}>
                        <td>{report.lead.simplifiedCompanyName}</td>
                        <td>{report.callDone}</td>
                        <td>{report.talkDone}</td>
                        <td>{report.voicemailDone}</td>
                        <td>{report.emailSent}</td>
                        <td>{report.emailPersonalized}</td>
                        <td>{report.created_at}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
         )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            close
          </Button>
          <Button autoFocus onClick={handleClose}>
            send
          </Button>
        </DialogActions>
      </Dialog>
  );
}