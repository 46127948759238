import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react'; // => request placed at the top
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import { Helmet } from 'react-helmet-async';
// @mui
import { useQuery } from '@apollo/client';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from "react";
import CalendarToolbar from '../components/calendar/CalendarToolbar';
import { GET_ALL_APPOINTMENTS } from '../graphql/queries/appointment';
import useResponsive from '../hooks/useResponsive';
import { LABELS } from '../utils/constants';
// sections

export default function LeaderboardPage() {

  const todaysDate = new Date();

  const { data, loading, error } = useQuery(GET_ALL_APPOINTMENTS);

  const { enqueueSnackbar } = useSnackbar();

  const isDesktop = useResponsive('up', 'sm');

  const calendarRef = useRef(null);

  const [openForm, setOpenForm] = useState(false);

  const [selectedEventId, setSelectedEventId] = useState(null);

  const [events, setEvents] = useState([]);

  const [selectedRange, setSelectedRange] = useState(null);

  const [value, setValue] = useState(null);

  // const picker = useDateRangePickerDefaultizedProps(null, null);

  const [date, setDate] = useState(new Date());

  const [openFilter, setOpenFilter] = useState(false);

  const [view, setView] = useState('timeGridWeek');

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = 'timeGridWeek';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, []);


  const handleOpenModal = () => {
    setOpenForm(true);
  };

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleSelectRange = (arg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.unselect();
    }
    handleOpenModal();
    setSelectedRange({
      start: arg.start,
      end: arg.end,
    });
  };

  const handleSelectEvent = (arg) => {
    handleOpenModal();
    setSelectedEventId(arg.event.id);
  };

  const handleResizeEvent = ({ event }) => {

  };

  const handleDropEvent = ({ event }) => {

  };

  useEffect(() => {
    if (data?.getAllAppointments) {
      const filteredEvents = data?.getAllAppointments?.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }));
      setEvents(filteredEvents);
    }
  }, [data?.getAllAppointments]);


  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Sequence
    </Link>,
    <Typography key="3" color="text.primary">
      Appointments
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title> Appointments | Auto Caller UI </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="column" spacing={0} alignItems="flex-start">
              <Typography variant="h3">{LABELS.Appointments}</Typography>
              <Typography variant="caption">{LABELS.AppointmentsDescription}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <CalendarToolbar
              date={date}
              view={view}
              onNextDate={handleClickDateNext}
              onPrevDate={handleClickDatePrev}
              onToday={handleClickToday}
              onChangeView={handleChangeView}
              onOpenFilter={() => setOpenFilter(true)}
            />
            <FullCalendar
              weekends
              editable
              droppable
              selectable
              rerenderDelay={10}
              allDayMaintainDuration
              eventResizableFromStart
              ref={calendarRef}
              initialDate={date}
              initialView={view}
              dayMaxEventRows={3}
              eventDisplay="block"
              events={events}
              headerToolbar={false}
              initialEvents={events}
              select={handleSelectRange}
              eventDrop={handleDropEvent}
              eventClick={handleSelectEvent}
              eventResize={handleResizeEvent}
              height={isDesktop ? 720 : 'auto'}
              plugins={[
                listPlugin,
                dayGridPlugin,
                timelinePlugin,
                timeGridPlugin,
                interactionPlugin,
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
