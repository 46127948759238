import { gql } from '@apollo/client';

export const LEAD = gql`
  fragment LeadFields on Lead {
    _id
    jobSource
    jobCategory
    jobPostTitle
    jobType
    jobPostDate
    jobLocation
    jobPostUrl
    simplifiedCompanyName
    companyDomainName
    companyLinkedinProfile
    companyPhoneNumber
    companyLogo
    companyEmail
    simplifiedCompanyLocation
    aboutCompany
    minimumCompanySize
    maximumCompanySize
    simplifiedCompanyIndustry
    generatedCompanyDescription
    websiteTrafficAnalysis
    recommendation
    nearbyLocation1
    nearbyLocation2
    yearFounded
    type
    industry
    industryTags
    uxAnalysisLink
    contactOwner
    contactOwnerId
    step
    stage
    optOut
    optOutReason
    minimumAnnualRevenue
    maximumAnnualRevenue
    trafficMonth1
    trafficValue1
    trafficMonth2
    trafficValue2
    trafficMonth3
    trafficValue3
    trafficMonth4
    trafficValue4
    trafficMonth5
    trafficValue5
    trafficMonth6
    trafficValue6
    expertDocumentLink
    created_at
  }
`;
