import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { baseServerUrl } from '../../config/apiConfig';
import useTwilioVoice from './hooks/useTwilioVoice';

const ManualCallPage = () => {
  const [phoneNumber, setPhoneNumber] = useState('2348128782234');
  const callInstance = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const userId = user?._id;
  // const userId = 'starr'; 
  const { device, callStatus, setCallStatus, incomingCall, acceptedCall, setAcceptedCall,setIncomingCall, callingToken} = useTwilioVoice(userId);

  const handleCall = async () => {
    try {
      if (!callingToken.current) {
        throw new Error('No token available');
      }
      
      const params = {
        To: phoneNumber,
        From: '+19033286481',
        userId,
        leadId: 'contactId',
        manual: true,
      };

      await axios.post(`${baseServerUrl}/api/v1/twilio-v1/manual-outbound-call`, params);

      if (device.current) {
        callInstance.current = await device.current.connect({ params });

        callInstance.current.on('accept', () => {
          console.log('Call accepted');
          setCallStatus('Call accepted');
        });

        callInstance.current.on('ringing', () => {
          console.log('Ringing...');
          setCallStatus('Ringing...');
        });

        callInstance.current.on('answered', () => {
          console.log('Call answered');
          setCallStatus('Call answered');
        });

        callInstance.current.on('connect', () => {
          console.log('Connected');
          setCallStatus('Connected');
        });

        callInstance.current.on('disconnect', () => {
          console.log('Call ended');
          setCallStatus('Call ended');
          device.current.disconnectAll();
          callInstance.current = null;
        });

        callInstance.current.on('cancel', () => {
          console.log('Call cancelled');
          setCallStatus('Call cancelled');
        });

        console.log('Device state after connect:', device.current);
        console.log('Call instance state after connect:', callInstance.current);
      } else {
        throw new Error('Unable to make call');
      }
    } catch (error) {
      console.error('Error making call:', error);
      setCallStatus(`Error making call: ${error.message}`);
    }
  };

  const handleEndCall = () => {
    if (callInstance.current) {
      callInstance.current.disconnect();
      setCallStatus('Call ended');
    }
  };

  const handleAcceptIncomingCall = () => {
    if (incomingCall) {
      incomingCall.accept();
      setCallStatus('Incoming call accepted');
      setIncomingCall(null);
    }
  };

  const handleRejectIncomingCall = () => {
    if (incomingCall) {
      incomingCall.reject();
      setCallStatus('Incoming call rejected');
      setIncomingCall(null);
    }
  };

  return (
    <div>
      <h1>Make a Voice Call</h1>
      <input
        type="text"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Enter phone number"
      />
      <button onClick={handleCall}>Call</button>
      {callInstance.current && (
        <button onClick={handleEndCall}>End Call</button>
      )}
      <p>Call Status: {callStatus}</p>

      {incomingCall && (
        <div>
          <p>Incoming call from: {incomingCall.parameters.From}</p>
          <button onClick={handleAcceptIncomingCall}>Accept</button>
          <button onClick={handleRejectIncomingCall}>Reject</button>
        </div>
      )}
    </div>
  );
};

export default ManualCallPage;
