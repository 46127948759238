import * as React from 'react';
// @mui
import { useMutation } from '@apollo/client';
import { Alert, Card, CardContent, Grid, TextField } from '@mui/material';
// components
import { useForm } from 'react-hook-form';
// components
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { CREATE_OR_UPDATE_LEAD } from '../../../graphql/mutations/lead';

function EditCompanyDetails({ targetCompany }) {
  const [createOrUpdateLead, { data, loading, error }] = useMutation(CREATE_OR_UPDATE_LEAD);
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm();

  React.useEffect(() => {
    if (data && data.createOrUpdateLead) {
      enqueueSnackbar('Company updated successfully', { variant: 'success' });
    }
  }, [data, enqueueSnackbar]);

  const onSubmit = (data) => {
    createOrUpdateLead({
      variables: { input: { ...data } },
    });
  };

  return (
    <Card sx={{ p: 3 }}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              {error && <Alert severity="error">{error?.message}</Alert>}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="simplifiedCompanyName"
                required
                label="Simplified Company Name"
                defaultValue={targetCompany?.simplifiedCompanyName}
                {...register('simplifiedCompanyName', { required: true })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="companyDomainName"
                label="Company Domain Name"
                defaultValue={targetCompany?.companyDomainName}
                {...register('companyDomainName', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="companyLinkedinProfile"
                label="Company Linkedin Profile"
                defaultValue={targetCompany?.companyLinkedinProfile}
                {...register('companyLinkedinProfile', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="companyPhoneNumber"
                label="Company Phone Number"
                defaultValue={targetCompany?.companyPhoneNumber}
                {...register('companyPhoneNumber', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="companyEmail"
                label="Company Email"
                defaultValue={targetCompany?.companyEmail}
                {...register('companyEmail', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="simplifiedCompanyLocation"
                label="Simplified Company Location"
                defaultValue={targetCompany?.simplifiedCompanyLocation}
                {...register('simplifiedCompanyLocation', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="aboutCompany"
                label="About Company"
                defaultValue={targetCompany?.aboutCompany}
                {...register('aboutCompany', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="minimumCompanySize"
                label="Minimum Company Size"
                defaultValue={targetCompany?.minimumCompanySize}
                {...register('minimumCompanySize', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="maximumCompanySize"
                label="Maximum Company Size"
                defaultValue={targetCompany?.maximumCompanySize}
                {...register('maximumCompanySize', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="simplifiedCompanyIndustry"
                label="Simplified Company Industry"
                defaultValue={targetCompany?.simplifiedCompanyIndustry}
                {...register('simplifiedCompanyIndustry', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="generatedCompanyDescription"
                label="Generated Company Description"
                defaultValue={targetCompany?.generatedCompanyDescription}
                {...register('generatedCompanyDescription', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="websiteTrafficAnalysis"
                label="Website Traffic Analysis"
                defaultValue={targetCompany?.websiteTrafficAnalysis}
                {...register('websiteTrafficAnalysis', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="recommendation"
                label="Recommendation"
                defaultValue={targetCompany?.recommendation}
                {...register('recommendation', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="nearbyLocation1"
                label="Nearby Location 1"
                defaultValue={targetCompany?.nearbyLocation1}
                {...register('nearbyLocation1', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="nearbyLocation2"
                label="Nearby Location 2"
                defaultValue={targetCompany?.nearbyLocation2}
                {...register('nearbyLocation2', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="yearFounded"
                label="Year Founded"
                defaultValue={targetCompany?.yearFounded}
                {...register('yearFounded', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="type"
                label="Type"
                defaultValue={targetCompany?.type}
                {...register('type', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="industry"
                label="Industry"
                defaultValue={targetCompany?.industry}
                {...register('industry', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="industryTags"
                label="Industry Tags"
                defaultValue={targetCompany?.industryTags}
                {...register('industryTags', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="uxAnalysisLink"
                label="UX Analysis Link"
                defaultValue={targetCompany?.uxAnalysisLink}
                {...register('uxAnalysisLink', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="contactOwnerId"
                label="Contact Owner Id"
                defaultValue={targetCompany?.contactOwnerId}
                {...register('contactOwnerId', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobSource"
                label="Job Source"
                defaultValue={targetCompany?.jobSource}
                {...register('jobSource', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobPostTitle"
                label="Job Post Title"
                defaultValue={targetCompany?.jobPostTitle}
                {...register('jobPostTitle', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobType"
                label="Job Type"
                defaultValue={targetCompany?.jobType}
                {...register('jobType', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobPostDate"
                label="Job Post Date"
                defaultValue={targetCompany?.jobPostDate}
                {...register('jobPostDate', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobLocation"
                label="Job Location"
                defaultValue={targetCompany?.jobLocation}
                {...register('jobLocation', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="jobPostUrl"
                label="Job Post Url"
                defaultValue={targetCompany?.jobPostUrl}
                {...register('jobPostUrl', { required: false })}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="step"
                label="Step"
                defaultValue={targetCompany?.step}
                {...register('step', { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <LoadingButton loading={loading} type="submit" variant="contained">
                Save Changes
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

EditCompanyDetails.propTypes = {
  targetCompany: PropTypes.object,
};

export default EditCompanyDetails;
