import PropTypes from 'prop-types';
// @mui
import { Button, IconButton, InputAdornment, OutlinedInput, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 80,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[800],
  borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  '&:hover': {
    borderColor: `${alpha(theme.palette.grey[500], 0.72)} !important`,
    backgroundColor: 'transparent',
  },
}));

// ----------------------------------------------------------------------

ContactListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function ContactListToolbar({ numSelected, filterName, onFilterName }) {

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Stack direction="column" spacing={1}>
           <Typography variant='h6'>
              Contacts
           </Typography>
           <Typography variant='caption'>
             All Contacts
           </Typography>
        </Stack>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <Stack direction="row" spacing={1}>
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder="Search..."
            size='small'
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          <Tooltip title="Filter list">
            <StyledButton startIcon={ <Iconify icon="ic:round-filter-list" />} variant='outlined'>
               Filters
            </StyledButton>
          </Tooltip>
        </Stack>
      )}
    </StyledRoot>
  );
}
