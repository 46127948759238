import { filter } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

// @mui
import {
  Card,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
// components
import PropTypes from 'prop-types';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import ContactListHead from './ContactListHead';
import ContactListToolbar from './ContactListToolbar';

const TABLE_HEAD = [
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'emailAddress', label: 'Email', alignRight: false },
  { id: 'prospectPhoneNumber', label: 'Phone', alignRight: false },
  { id: 'prospectLocation', label: 'Location', alignRight: false },
  { id: 'prospectDesignation', label: 'Designation', alignRight: false },
  { id: 'prospectLinkedinProfile', label: 'LinkedIn', alignRight: false },
  { id: 'prospectDescription', label: 'Description', alignRight: false },
  { id: 'timeZone', label: 'Time Zone', alignRight: false },
  { id: 'reasonForOptOut', label: 'Opt Out Reason', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function formatPhoneNumber(phoneNumber) {
  // Remove non-numeric characters and spaces for phone number
  const formattedNumber = phoneNumber.replace(/[^0-9]/g, '');
  if(formattedNumber.length){
    return formattedNumber;
  }
  return phoneNumber;
}


function applySortFilter(array, comparator, query) {
  console.log('array',query);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
      return filter(array, (contact) => contact?.company?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || contact?.firstName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || contact?.lastName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || contact?.emailAddress?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || formatPhoneNumber(contact?.prospectPhoneNumber)?.indexOf(formatPhoneNumber(query.toLowerCase())) !== -1 || contact?.prospectLocation?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || contact?.prospectDesignation?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el) => el[0]);

}

function ContactTable({ contacts }) {

  const [allContacts, setAllContacts] = useState(contacts);

  const [open, setOpen] = useState(null);

  const [openDelete, setOpenDelete] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(100);


  const handleOpenDelete = () => {
    setOpenDelete(true);
    setOpen(null);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (contacts?.length > 0) {
      setAllContacts(contacts);
    }
  }, [contacts, setAllContacts]);

  const handleEditCompanyDialogOpen = useCallback(() => {
    handleCloseMenu();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allContacts.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allContacts.length) : 0;

  const filteredContacts = applySortFilter(allContacts, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredContacts.length && !!filterName;

  return (
    <>
      <Card>
        <ContactListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={(e) => setFilterName(e.target.value)}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ContactListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={allContacts.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredContacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  // extract allContacts count from the array
                  const {
                    _id: id,
                    company,
                    firstName,
                    lastName,
                    emailAddress,
                    prospectPhoneNumber,
                    prospectLocation,
                    prospectDesignation,
                    prospectLinkedinProfile,
                    prospectDescription,
                    timeZone,
                    reasonForOptOut
                  } = row;
                  const selectedLead = selected.indexOf(id) !== -1;

                  return (
                    <TableRow key={id} tabIndex={-1} role="checkbox" selected={selectedLead}>
                      <TableCell align="left">{company}</TableCell>
                      <TableCell align="left">{firstName}</TableCell>
                      <TableCell align="left">
                        {lastName}
                      </TableCell>
                      <TableCell align="left">{emailAddress}</TableCell>
                      <TableCell align="left">{prospectPhoneNumber}</TableCell>
                      <TableCell align="left">{prospectLocation}</TableCell>
                      <TableCell align="left">{prospectDesignation}</TableCell>
                      <TableCell align="left">{prospectLinkedinProfile}</TableCell>
                      <TableCell align="left">{prospectDescription}</TableCell>
                      <TableCell align="left">{timeZone}</TableCell>
                      <TableCell align="left">
                        <Typography variant="body2" color={(reasonForOptOut && 'error') || 'primary'}>
                          {reasonForOptOut || 'No'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={allContacts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'ph:eye-light'} sx={{ mr: 2 }} />
          Details
        </MenuItem>
        <MenuItem>
          <Iconify icon={'clarity:assign-user-line'} sx={{ mr: 2 }} />
          Assign
        </MenuItem>
        <MenuItem onClick={handleEditCompanyDialogOpen}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDelete}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

    </>
  );
}

ContactTable.propTypes = {
  contacts: PropTypes.array.isRequired,
};

export default ContactTable;
