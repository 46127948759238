import { Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
// components
import PropTypes from 'prop-types';
// sections
import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../components/iconify';
import { GET_TOTAL_LOG_COUNT_BY_STEP, GET_TOTAL_LOG_COUNT_BY_USER_BY_STEP } from '../../../graphql/queries/log';
import { setLeadStage, setStep, setTargetLeads } from '../../../redux/service.slice';
import { SEQUENCE_ICONS, SEQUENCE_MESSAGE } from '../../../utils/constants';

function SequenceCard({ groupedLeads, step, selectedAgent, handleOpenSequenceAutoCaller }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getTotalLogCountByUserByStep, { data: totalLogCountByUserByStep }] = useLazyQuery(
    GET_TOTAL_LOG_COUNT_BY_USER_BY_STEP
  );
  const [getTotalLogCountByStep, { data: totalLogCountByStep }] = useLazyQuery(GET_TOTAL_LOG_COUNT_BY_STEP);

  useEffect(() => {
    if (selectedAgent === 'all') {
      getTotalLogCountByStep({ variables: { step: `${step.step}` } });
    } else {
      getTotalLogCountByUserByStep({ variables: { user: selectedAgent, step: `${step.step}` } });
    }
  }, [selectedAgent, step, getTotalLogCountByStep, getTotalLogCountByUserByStep]);

  const logCount = useMemo(() => {
    if (selectedAgent === 'all') {
      return totalLogCountByStep?.getTotalLogCountByStep || 0;
    }
    return totalLogCountByUserByStep?.getTotalLogCountByUserByStep || 0;
  }, [totalLogCountByStep, totalLogCountByUserByStep, selectedAgent]);

  return (
    <Paper
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 2,
      }}
      key={step.title}
    >
      <Grid container spacing={3}>
        <Grid item container xs={12} sm={12} md={6} justifyItems="center" justifyContent="flex-start">
          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <img src={SEQUENCE_ICONS[step.type]} alt={step.type} width="30" />
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">{`${step.step}. ${step.title}`}</Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={1}>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography>Logged</Typography>
            <Typography>{logCount}</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <Stack direction="row" justifyItems="center" justifyContent="flex-end" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<Iconify icon="material-symbols:autorenew" width="30" />}
              onClick={() => {
                dispatch(setTargetLeads([]));
                // dispatch(setTargetLeads(groupedLeads?.[step.step]));
                dispatch(setStep(step.step));
                dispatch(setLeadStage(step.stage));
                handleOpenSequenceAutoCaller();
              }}
              disabled={!groupedLeads?.[step.step]}
            >
              {`${groupedLeads?.[step.step] || 0} Auto Dialer`}
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: '#080708',
                color: '#080708',
              }}
              startIcon={<img src={SEQUENCE_ICONS[step.type]} alt={step.type} width="30" />}
              onClick={() => {
                dispatch(setStep(step.step));
                dispatch(setLeadStage(step.stage));
                navigate(`/sequence/leads/${step.step}/0`);
              }}
              disabled={!groupedLeads?.[step.step]}
            >
              {`${groupedLeads?.[step.step] || 0} ${SEQUENCE_MESSAGE[step.type]}`}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Divider />
        </Grid>
      </Grid>
    </Paper>
  );
}

SequenceCard.propTypes = {
  groupedLeads: PropTypes.object,
  handleOpenSequenceAutoCaller: PropTypes.func,
  selectedAgent: PropTypes.string,
  step: PropTypes.object,
};

export default SequenceCard;
