import { Helmet } from 'react-helmet-async';
// @mui
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
// sections
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CompaniesTable from '../sections/@dashboard/sequence-company/CompaniesTable';

// ----------------------------------------------------------------------

export default function CompaniesPage() {

  const navigate = useNavigate();

  const { leads, step, stage, activeLead } = useSelector((state) => state.lead);

  const handleClickLink = (event, path = '/') => {
    event.preventDefault();
    navigate(path);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={(event)=>handleClickLink(event)}>
      Sequence
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={(event)=>handleClickLink(event, `/sequence/leads/${step}/${activeLead}`)}>
      {stage} : Step {step}
    </Link>,
    <Typography key="3" color="text.primary">
      See all companies
    </Typography>,
  ];
  
  return (
    <>
      <Helmet>
        <title> Companies Page | Auto Caller UI </title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h4">All Companies</Typography>
          <Typography variant="body2">
            All the company that you have to reach out for successfully reaching today’s target.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={2}>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="caption">Total Companies</Typography>
                <Typography variant="h5">{leads?.length}</Typography>
              </Stack>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="caption">Total Prospects</Typography>
                <Typography variant="h5">15</Typography>
              </Stack>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="caption">Pending Leads</Typography>
                <Typography variant="h5">10</Typography>
              </Stack>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack direction="row" spacing={2}>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="caption">Call Logged</Typography>
                <Typography variant="h5">126</Typography>
              </Stack>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="caption">Attended</Typography>
                <Typography variant="h5">102</Typography>
              </Stack>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="caption">Voice Mails</Typography>
                <Typography variant="h5">14</Typography>
              </Stack>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="caption">Email Sent</Typography>
                <Typography variant="h5">23</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
        <CompaniesTable/>
        </Grid>
      </Grid>
      
    </>
  );
}
