import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_CALL_CONNECTIONS_STATS } from '../../graphql/queries/call.analytics';

const useCallConnectionStats = (range) => {
  const [getCallConnectionStats, { data, loading, error }] = useLazyQuery(GET_CALL_CONNECTIONS_STATS);

  useEffect(() => {
    if (range) {
      getCallConnectionStats({ variables: { range } });
    }
  }, [range, getCallConnectionStats]);

  return { data: data?.getCallConnectionStats, loading, error };
};

export default useCallConnectionStats;
