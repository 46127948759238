import { gql } from '@apollo/client';

export const CREATE_ASSETS = gql`
    mutation createAssets($input:AssetsInput! ){
        createAssets(input: $input){
            _id
            createdAt
            url
            description
            title
            category
        }
    }
`;

export const GET_ALL_ASSETS = gql`
    query{
        getAssets{
        _id
        createdAt
        url
        description
        title
        category
        }
    }
`;