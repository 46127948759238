import { useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { LOG_TIMER_T } from '../../../graphql/mutations/log';
import { getToday } from '../../../utils/helperFunctions';

const Timer = ({ isRunning }) => {
  const [time, setTime] = useState(0);
  const [dates, setDates] = useState(getToday());
  const intervalRef = useRef(null);
  const [logTimer, {data}] = useMutation(LOG_TIMER_T)
  const {enqueueSnackbar} = useSnackbar();

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  // Save the logged time to the database
  const saveLoggedTimeToDB = (time, dates) => {
    // Implement the logic to save the logged time to the database here
    logTimer({
      variables: {
        input: {
          time: formatTime(time),
          startDate: dates.startDate,
          endDate: dates.endDate,
        },
      },
    });
  };

  // Load the timer state from localStorage when the component mounts
  useEffect(() => {
    const savedTime = localStorage.getItem('timer-time');
    const savedDates = JSON.parse(localStorage.getItem('timer-dates'));
    // const savedRunningState = localStorage.getItem('timer-running');

    if (savedTime) {
      setTime(parseInt(savedTime, 10));
    }
    if (savedDates) {
      setDates(savedDates);
    }
  }, []);

  // Save the timer state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('timer-time', time);
    localStorage.setItem('timer-dates', JSON.stringify(dates));
    localStorage.setItem('timer-running', isRunning);
  }, [time, dates, isRunning]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar('Logged time successfully', {variant: 'success'});
    }
  }, [data]);

  // Handle the timer logic
  useEffect(() => {
    const currentDates = getToday();
    if (currentDates.startDate !== dates.startDate || currentDates.endDate !== dates.endDate) {
      // Save the logged time with the previous dates to the db
      saveLoggedTimeToDB(time, dates);

      // Reset the timer and update the dates
      setTime(0);
      setDates(currentDates);
    }

    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    } else if (!isRunning && intervalRef.current) {
      clearInterval(intervalRef.current);
      saveLoggedTimeToDB(time, dates);
    }

    return () => clearInterval(intervalRef.current);
  }, [time, dates, isRunning]);

  return (
    <Typography>Timer: {formatTime(time)}</Typography>
  );
};

export default Timer;