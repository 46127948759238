import { useMutation } from '@apollo/client';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Iconify from '../../../components/iconify';
import { CREATE_TRAINING } from '../../../graphql/mutations/training';

export const TestingCampElement = ({ response, responses, setResponses, id }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [createModelTraining, { data, loading }] = useMutation(CREATE_TRAINING);
    const [content, setContent] = useState({
        prompt: "",
        completion: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContent({
            ...content,
            [name]: value,
        });
    };

    const [edit, setEdit] = useState(false);

    const handleSave = () => {
        createModelTraining({
            variables: {
                input: {
                    preContext: content?.prompt,
                    objection: content?.prompt,
                    response: content?.completion,
                }
            }
        });

    };

    const handleUpdate = () => {
        const newResponses = responses.map((item, index) => {
            if (index === id) {
                return { ...response, ...content };
            }
            return item;
        });

        console.log(newResponses);
        setResponses(newResponses);
        setEdit(false);
    };

    useEffect(() => {
        setContent(response);
    }, [response]);

    useEffect(() => {
        if (data) {
            setEdit(false);
            enqueueSnackbar("Training data saved successfully", { variant: "success" });
        }
    }
        , [data]);


    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" justifyContent="space-between" sx={{ py: 1 }}>
                {edit ? (
                    <TextField
                        placeholder="Type in your prompt..."
                        label="Prompt"
                        size="small"
                        fullWidth
                        name="prompt"
                        value={content?.prompt}
                        onChange={handleInputChange}
                    />
                ) : (
                    <Typography variant="subtitle1">{content?.prompt}</Typography>
                )}
                <Stack direction="row" spacing={2}>
                    {!edit && (
                        <Button
                            variant="outlined"
                            onClick={() => setEdit(!edit)}
                            endIcon={<Iconify icon="eva:edit-fill" />}
                        >
                            Edit
                        </Button>
                    )}
                </Stack>
            </Stack>
            <Divider />
            {edit ? (
                <Stack direction="column" spacing={1}>
                    <TextField
                        multiline
                        rows={3}
                        placeholder="Type in your completion..."
                        label="Completion"
                        size="small"
                        fullWidth
                        name="completion"
                        value={content?.completion}
                        onChange={handleInputChange}
                    />
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="outlined"
                            onClick={() => setEdit(!edit)}
                            endIcon={<Iconify icon="eva:close-fill" />}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<Iconify icon="eva:checkmark-fill" />}
                            onClick={handleSave}
                            disabled={loading}
                        >
                            {loading ? "Saving..." : "Train"}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<Iconify icon="eva:checkmark-fill" />}
                            onClick={handleUpdate}
                            disabled={loading}
                        >
                            {loading ? "Saving..." : "Update"}
                        </Button>
                    </Stack>
                </Stack>
            ) : (
                <Typography variant="body2">{content?.completion}</Typography>
            )}

        </Stack>
    )
}