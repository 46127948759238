import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
import { BaseOptionChart } from '../../../components/call-analytics/chart';

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// Static Hours Data (placeholder for now)
const HOURS_DATA = [3, 5, 4, 2]; 

export default function AnalyticsAgentWithMostConnection({ agentsConnStats = [] }) {
  const theme = useTheme();

  const AGENT_NAMES = agentsConnStats.map(agent => agent.agentName);
  const CHART_DATA = agentsConnStats.map(agent => agent.totalConnectedCalls);

  const chartOptions = merge(BaseOptionChart(), {
    colors: ['#01AB55', theme.palette.primary.main, '#826AF9', '#ead300'],
    labels: AGENT_NAMES,
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      floating: true,
      horizontalAlign: 'center',
      markers: { width: 12, height: 12, radius: 12, offsetX: -2 }, 
    },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const value = CHART_DATA[seriesIndex];
        const hours = HOURS_DATA[seriesIndex] || 'N/A'; 
        const name = AGENT_NAMES[seriesIndex];
        // <div>${hours} hr+ in the pool</div>
        return `
          <div style="padding: 10px; font-size: 14px;"> 
            <div style="display: flex; align-items: center;">
              <div style="width: 12px; height: 12px; border-radius: 50%; background-color: ${chartOptions.colors[seriesIndex]}"></div>
              <strong style="margin-left: 8px;">${name}</strong>
            </div>
            <div>${fNumber(value)} connected calls</div>
          </div>
        `;
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card>
      <CardHeader title="Agents with Most Connections" />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={CHART_DATA} options={chartOptions} height={280} />
      </ChartWrapperStyle>
    </Card>
  );
}
