import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { useCallback, useState } from 'react';

let recognizer;
let speakerSource;
let mixedOutput;

function useSpeechRecognition() {
  const [recognizedText, setRecognizedText] = useState('');
  const [recognitionText, setRecognizingText] = useState('');

  const startStreaming = useCallback(async () => {
    try {
      // const micStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const speakerStream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: false } });
      // Assuming micStream and micAndEchoStream are already defined
      // const micStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // const micAndEchoStream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: false } });
      // // Extract audio tracks from both streams
      // const micStreamAudioTrack = micStream.getAudioTracks()[0];
      // const micAndEchoStreamAudioTrack = micAndEchoStream.getAudioTracks()[0];

      // // Create a new MediaStream with only the audio from micAndEchoStream
      // const echoOnlyStream = new MediaStream();
      // echoOnlyStream.addTrack(micAndEchoStreamAudioTrack.clone());

      // // If you want to remove the audio from micStream, you can stop its track
      // micStreamAudioTrack.stop();

      // Now echoOnlyStream contains only the audio from micAndEchoStream
      const audioContext = new AudioContext();
      speakerSource = audioContext.createMediaStreamSource(speakerStream);
      mixedOutput = audioContext.createMediaStreamDestination();
      speakerSource.connect(mixedOutput);

      const audioConfig = sdk.AudioConfig.fromStreamInput(mixedOutput.stream);
      const speechConfig = sdk.SpeechConfig.fromSubscription("e97fc34430634e16a42736022f311276", "eastus");
      recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
      recognizer.startContinuousRecognitionAsync(() => {
        console.log('Recognition started');
      }, (error) => {
        console.error(`Error starting recognition: ${error}`);
      });

      recognizer.recognizing = (s, e) => {
        console.log(`RECOGNIZING: Text=${e.result.text}`);
        setRecognizingText(e.result.text);
      };

      recognizer.recognized = (s, e) => {
        if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
          console.log(`RECOGNIZED: Text=${e.result.text}`);
          setRecognizedText(e.result.text);
        } else if (e.result.reason === sdk.ResultReason.NoMatch) {
          console.log("NOMATCH: Speech could not be recognized.");
        }
      }
    } catch (error) {
      console.log('Error starting recognition: ', error);
    }

  }, []);

  const resetTranscription = useCallback(() => {
    setRecognizedText('');
    setRecognizingText('');
  }, []);

  const stopStreaming = useCallback(() => {
    try {
      if (recognizer) {
        recognizer.stopContinuousRecognitionAsync(() => {
          console.log('Recognition stopped');
        }, (error) => {
          console.error(`Error stopping recognition: ${error}`);
        });
      }
      if (speakerSource && mixedOutput) {
        speakerSource.disconnect(mixedOutput);
        // Clean up the streams when the component unmounts
        mixedOutput.stream.getTracks().forEach(track => track.stop());
        // stop speaker streaming 
        speakerSource.mediaStream.getTracks().forEach(track => track.stop());
      }

    } catch (error) {
      console.log('Error stopping recognition: ', error);
    }

  }, []);


  return { recognizedText, recognitionText, startStreaming, stopStreaming, resetTranscription };
}

export default useSpeechRecognition;