import { useMutation } from '@apollo/client';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Chip, Divider, LinearProgress, ListItem, Paper, Stack, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { CREATE_ASSETS } from '../../../graphql/queries/assets';
import { createParams, filePath, s3 } from '../../../utils/s3';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function AddAssetsDialog({ open, handleClose, handleAssetsAdded }) {

  const [createAssets, { data: assetsData, loading, error }] = useMutation(CREATE_ASSETS);

  const { handleSubmit, register } = useForm();

  const { enqueueSnackbar } = useSnackbar();

  const [create, setCreate] = useState(false);

  const [progress, setProgress] = useState(0);

  const [url, setUrl] = useState(null);

  const handleDelete = () => () => {
    setUrl(null);
  };

  const onDrop = async (acceptedFiles) => {

    const file = acceptedFiles[0];

    const params = createParams(file);

    const command = new PutObjectCommand(params);

    setProgress(10);

    try {

      const data = await s3.send(command, {
        onUploadProgress: (progressEvent) => {
          const { loaded } = progressEvent;
          const { total } = progressEvent;
          const percentage = Math.round((loaded / total) * 100);
          setProgress(percentage);
        },
      });

      if (params.Key && data.$metadata.httpStatusCode === 200) {
        setUrl(filePath(params.Key));
        setProgress(0);
      }

    } catch (error) {

      enqueueSnackbar('Error uploading file', { variant: 'error' });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });


  useEffect(() => {
    if (assetsData && assetsData.createAssets && create) {
      enqueueSnackbar('Assets created successfully', { variant: 'success' });
      handleAssetsAdded(assetsData.createAssets);
      handleClose();
      setCreate(false);
    }
  }, [assetsData, enqueueSnackbar, handleClose, create, handleAssetsAdded]);


  const onSubmit = (data) => {
    setCreate(true);
    if (!url && data.url === '') {
      enqueueSnackbar('Please upload a file or provide a url', { variant: 'error' });
    } else {
      const myUrl = url || data.url;
      createAssets({
        variables: { input: { ...data, url: myUrl } },
      });
    }

  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Assets
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {error && <Alert severity="error">{error?.message}</Alert>}

              <TextField name="title" required label="Assets title" {...register('title', { required: true })} />

              <TextField name="category" label="Tags" {...register('category', { required: true })} />

              <TextField name="description"
                multiline
                rows={4}
                label="Description" {...register('description', { required: true })} />

              <TextField name="url" placeholder='Document url....' {...register('url', { required: true })} />

              <Divider>
                or
              </Divider>

              <Stack direction="column" alignItems="center" spacing={1}>
                <Box {...getRootProps()} sx={{ width: 400, cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <input {...getInputProps()} id="file-input" />
                  <p>Drag & drop a file here, or click to select a file</p>
                  <img src="/assets/icons/ic_attach.svg" alt="upload" width="100" />
                </Box>
              </Stack>
              {progress > 0 && <LinearProgress variant="determinate" value={progress} />}

              <Paper
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {url && (
                  <ListItem>
                    <Chip
                      label={url}
                      onDelete={handleDelete}
                      sx={{ maxWidth: 400, textOverflow: 'ellipsis', overflow: 'hidden' }}
                    />
                  </ListItem>
                )}
              </Paper>

              <Box>
                <LoadingButton loading={loading} type="submit" variant="contained">
                  Save
                </LoadingButton>
              </Box>
            </Stack>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

AddAssetsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAssetsAdded: PropTypes.func.isRequired,
};

export default AddAssetsDialog;
