import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
// @mui
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CallOutlined, RefreshOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Button, Card, CardActions, CardContent, CircularProgress, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Portal from '@mui/material/Portal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import Iconify from '../../components/iconify';
// hooks
import DialPad from '../../components/dial-pad';
import { GET_CONTACT_BY_PHONE } from '../../graphql/queries/lead';
import { useBoolean } from '../../hooks/use-boolean';
import { useResponsive } from '../../hooks/use-responsive';
import { formatPhoneNumber, formatPhoneNumberFull, getPhoneNumber } from '../../utils/helperFunctions';
import { useTwilioCall } from '../../context/TwilioCallContext';
import useCallTimer from '../../pages/twilio-caller/hooks/useCallTimer';
import useTwilioService from '../../services/twilio/use.twilio.service';

// ----------------------------------------------------------------------

const ZINDEX = 1998;

const POSITION = 24;

export default function CallInitiateManual({ onCloseCompose }) {
  const { fetchTwilioToken } = useTwilioService();
  const { user } = useSelector((state) => state.auth);
  const userId = user?._id;
  const [isTokenLoading, setisTokenLoading] = useState(false);
  const smUp = useResponsive('up', 'sm');
  const messagesEndRef = useRef(null);
  const fullScreen = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const [getContactByPhone, { data, loading }] = useLazyQuery(GET_CONTACT_BY_PHONE);
  const [chatCompletionResponses, setChatCompletionResposes] = useState([]);
  const [phone, setPhone] = useState('');
  // Twilio Version Start
  const { startTimer, stopTimer, resetTimer, formattedTime, twilioError } = useCallTimer();
  const { handleCall, handleEndCall, callStatus, callingToken, callInstance } = useTwilioCall();

  useEffect(() => {
    if (phone.length >= 10) getContactByPhone({ variables: { phoneNumber: phone } });
  }, [phone, getContactByPhone]);

  const sendDMF = (number) => {};

  const initiateCall = () => {
    const formattedPhone = formatPhoneNumber(phone, '1');
    const leadId = data?.getContactByPhoneNumber?._id || null;
    const callDialType = 'MANUAL_DIAL';
    handleCall(formattedPhone, userId, leadId, callDialType);
    startTimer();
  };

  const handleEndCallFxn = () => {
    handleEndCall();
    stopTimer();
    resetTimer();
  };

  const fetchToken = async () => {
    if (isTokenLoading) return;

    setisTokenLoading(true);

    try {
      const token = await fetchTwilioToken(userId);
      callingToken.current = token;
      enqueueSnackbar(`Token Refreshed`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to refresh token.', { variant: 'error' });
    } finally {
      setisTokenLoading(false);
    }
  };

  return (
    <>
      <Portal>
        {(fullScreen.value || !smUp) && <Backdrop open sx={{ zIndex: ZINDEX }} />}

        <Paper
          sx={{
            right: 0,
            bottom: 0,
            borderRadius: 2,
            display: 'flex',
            position: 'fixed',
            zIndex: ZINDEX + 1,
            m: `${POSITION}px`,
            overflow: 'hidden',
            flexDirection: 'column',
            width: 550,
            boxShadow: (theme) => theme.customShadows.dropdown,
            ...(fullScreen.value && {
              m: 0,
              right: POSITION / 2,
              bottom: POSITION / 2,
              width: `calc(100% - ${POSITION}px)`,
              height: `calc(100% - ${POSITION}px)`,
            }),
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }}>
            <IconButton onClick={fullScreen.onToggle}>
              <Iconify icon={fullScreen ? 'eva:collapse-fill' : 'eva:expand-fill'} />
            </IconButton>

            <IconButton onClick={onCloseCompose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Stack>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              Dailer
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  bgcolor: 'background.neutral',
                  p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
                }}
              >
                {data?.getContactByPhoneNumber?.firstName ? (
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    {`Calling  ${data?.getContactByPhoneNumber?.firstName} ${data?.getContactByPhoneNumber?.lastName}(${
                      data?.getContactByPhoneNumber?.company
                    }) from ${getPhoneNumber(formatPhoneNumberFull(phone))}`}
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    {`Calling  ${formatPhoneNumber(phone, '1')} from ${getPhoneNumber(formatPhoneNumberFull(phone))}`}
                  </Typography>
                )}
              </Stack>
              {/* {callStatus && <Alert severity="error"><pre>{callStatus}</pre></Alert>} */}
              <Stack direction="column" alignItems="center" justifyContent="center" sx={{ p: 2 }} spacing={1}>
                <TextField
                  label="Phone number"
                  value={phone}
                  variant="standard"
                  onChange={(e) => setPhone(e.target.value)}
                  sx={{
                    width: 150,
                  }}
                />
                <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                  {!callingToken.current && <CircularProgress size={24} />}
                  <DialPad sendDMF={sendDMF} />

                  {twilioError ? (
                    <>
                      {' '}
                      <Stack spacing={1}>
                        <Typography color="#282833" fontSize="16px">
                          Click on the button below 👇🏼
                        </Typography>
                        <Button
                          startIcon={<RefreshOutlined />}
                          variant="contained"
                          sx={{ backgroundColor: '#282833' }}
                          onClick={fetchToken}
                          disabled={isTokenLoading}
                        >
                          {isTokenLoading ? 'Fetching...' : 'Refresh Token'}
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <>
                      {' '}
                      {callInstance.current ? (
                        <>
                          <Typography variant="h6" color="inherit" sx={{ mb: 1 }}>
                            {formattedTime}
                          </Typography>

                          <Typography variant="caption" sx={{ mb: 1 }}>
                            {callStatus}
                          </Typography>
                          <Button color="error" onClick={handleEndCallFxn} size="small">
                            {' '}
                            End Call{' '}
                          </Button>
                        </>
                      ) : (
                        <Button
                          color="success"
                          startIcon={<CallOutlined />}
                          variant="contained"
                          onClick={initiateCall}
                          sx={{ color: 'white' }}
                        >
                          {' '}
                          Call Number{' '}
                        </Button>
                      )}
                    </>
                  )}
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
              Transcription Engine
            </AccordionSummary>
            <AccordionDetails>
              <Card>
                <CardContent
                  sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2, maxHeight: '30vh', overflowY: 'auto' }}
                >
                  {chatCompletionResponses?.length > 0 && <Typography variant="h6">Ai Says</Typography>}
                  {chatCompletionResponses?.map((response, index) => (
                    <Stack
                      key={index}
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      spacing={1}
                    >
                      <Typography variant="caption" color="inherit" sx={{ mb: 1, fontWeight: 'bold' }}>
                        {`${response.prompt}`}
                      </Typography>
                      <Typography variant="body2" color="inherit" sx={{ mb: 1 }}>
                        {`${response.response}`}
                      </Typography>
                    </Stack>
                  ))}
                  <div ref={messagesEndRef} />
                </CardContent>
                {/* <CardActions>
                                    <Button color='primary' variant='contained' onClick={() => {
                                        startStreaming();
                                        setRecognize(true);
                                    }} disabled={recognize}> Start Streaming </Button>
                                    <Button color='primary' variant='outlined' onClick={() => {
                                        stopStreaming();
                                        setRecognize(false);
                                    }} disabled={!recognize}> Stop Streaming </Button>
                                    <Button color='primary' variant='outlined' onClick={handleResetTranscription}> Reset Transcription </Button>
                                </CardActions> */}
              </Card>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Portal>
    </>
  );
}

CallInitiateManual.propTypes = {
  onCloseCompose: PropTypes.func.isRequired,
};
