import { Helmet } from 'react-helmet-async';
// @mui
import { Breadcrumbs, Button, Container, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
// components
import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// sections
import { useLazyQuery, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReportConfirmationDialog from '../components/confirmation-dialog/reportConfirmationDialog';
import CustomTabPanel from '../components/custom-tab-panel';
import Loader from '../components/loader';
import {
  GET_ACTIVE_LEADS,
  GET_ACTIVE_LEADS_BY_CONTACT_OWNER_ID,
  GET_ALL_ACTIVE_LEAD_SUMMARIES,
  GET_ALL_ACTIVE_LEADS,
} from '../graphql/queries/lead';
import { GET_ALL_SEQUENCE } from '../graphql/queries/sequence';
import { GET_ALL_USERS } from '../graphql/queries/user';
import { setSelectedAgent } from '../redux/service.slice';
import SequenceAutoCaller from '../sections/@dashboard/sequence/SequenceAutoCaller';
import SequenceCallLogs from '../sections/@dashboard/sequence/SequenceCallLogs';
import SequenceCompanies from '../sections/@dashboard/sequence/SequenceCompanies';
import SequenceEmailLogs from '../sections/@dashboard/sequence/SequenceEmailLogs';
import SequenceOverview from '../sections/@dashboard/sequence/SequenceOverview';
import { LABELS } from '../utils/constants';
import { isAdmin, isAgent } from '../utils/helperFunctions';
// ----------------------------------------------------------------------

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DashboardAppPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_ALL_USERS);
  const { data: sequenceData, loading: loadingSequenceData } = useQuery(GET_ALL_SEQUENCE);
  const { user } = useSelector((state) => state.auth);
  const [userHaveOptions, setUserHaveOptions] = useState(false);
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [leadSummaries, setLeadSummaries] = useState([]);
  const [groupedLeads, setGroupedLeads] = useState({});
  const [agents, setAgents] = useState([]);
  const [leadsCount, setLeadsCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const limit = 100;
  
  const {
    data: allLeads,
    loading: loadingAllLeads,
    error,
    fetchMore,
  } = useQuery(GET_ACTIVE_LEADS, {
    variables: { limit, offset },
    notifyOnNetworkStatusChange: true,
  });
  
  const loadMoreLeads = useCallback(
    (newPage) => {
      const newOffset = newPage * limit;
      fetchMore({
        variables: { offset: newOffset },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          console.log("Previous Result:", prevResult);
          if (!fetchMoreResult) return prevResult;
          console.log("Fetch More Result:", fetchMoreResult);
          return {
            ...prevResult,
            getActiveLeads: {
              ...fetchMoreResult.getActiveLeads,
              leads: [
                ...prevResult.getActiveLeads.leads,
                ...fetchMoreResult.getActiveLeads.leads
              ],
            },
          };
        },
      }).then(() => {
        setOffset(newOffset);
        setPage(newPage);
      }).catch(error => {
        console.error("Error fetching more leads:", error);
      });
    },
    [fetchMore]
  );
  
  

  const [getAllActiveLeadSummaries, { data: allLeadSummaries, loading: loadingLeadSummaries }] =
    useLazyQuery(GET_ALL_ACTIVE_LEAD_SUMMARIES);
  const [getActiveLeadsByContactOwnerId, { data: leadsByContactOwnerId, loading: loadingLeadsByContactOwerId }] =
    useLazyQuery(GET_ACTIVE_LEADS_BY_CONTACT_OWNER_ID);
  const { selectedAgent } = useSelector((state) => state.lead);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [openSequenceAutoCaller, setOpenSequenceAutoCaller] = useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Sequence
    </Link>,
    <Typography key="3" color="text.primary">
      Overview
    </Typography>,
  ];

  const handleChangeOfTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    dispatch(setSelectedAgent(event.target.value));
  };

  const handleOpenSequenceAutoCaller = useCallback(() => {
    setOpenSequenceAutoCaller(true);
  }, []);

  const handleCloseSequenceAutoCaller = () => {
    setOpenSequenceAutoCaller(false);
  };

  const groupByStage = (data) => {
    const groupedData = {};

    data?.forEach((item) => {
      const { stage } = item;
      if (!groupedData[stage]) {
        groupedData[stage] = [];
      }
      groupedData[stage].push(item);
    });

    return groupedData;
  };

  useEffect(() => {
    if (isAdmin(user?.role)) {
      setUserHaveOptions(true);
      if (!selectedAgent) {
        dispatch(setSelectedAgent('all'));
      }
    } else {
      setUserHaveOptions(false);
      dispatch(setSelectedAgent(user?.hubSpotId));
    }
  }, [user, dispatch, selectedAgent]);

  useEffect(() => {
    if (allLeadSummaries?.getAllActiveLeadSummaries && !loadingLeadSummaries) {
      setLeadSummaries(allLeadSummaries?.getAllActiveLeadSummaries);

      // Calculate total lead count
      const totalLeadsCount = allLeadSummaries?.getAllActiveLeadSummaries?.reduce((sum, summary) => {
        return sum + summary.totalLeads;
      }, 0);

      setLeadsCount(totalLeadsCount);
    }
  }, [allLeadSummaries?.getAllActiveLeadSummaries, loadingLeadSummaries]);

  useEffect(() => {
    if (!loadingAllLeads && !error) {
      setLeads(prevLeads => {
        const newLeads = allLeads?.getActiveLeads?.leads || [];
        return [...prevLeads, ...newLeads];
      });
      setTotalLeads(allLeads?.getActiveLeads?.totalCount || 0);
    }
  }, [allLeads, loadingAllLeads, error]);

  useEffect(() => {
    if (leadsByContactOwnerId?.getActiveLeadsByContactOwnerId) {
      setLeads(leadsByContactOwnerId?.getActiveLeadsByContactOwnerId);
    }
  }, [leadsByContactOwnerId]);

  useEffect(() => {
    if (data?.getAllUsers) {
      const myAgents = data?.getAllUsers?.filter((dt) => isAgent(dt.role));
      setAgents(myAgents);
    }
  }, [data?.getAllUsers]);

  useEffect(() => {
    if (selectedAgent === 'all' && isAdmin(user?.role)) {
      getAllActiveLeadSummaries();
    } else {
      getActiveLeadsByContactOwnerId({ variables: { contactOwnerId: selectedAgent } });
    }
  }, [selectedAgent, user, getAllActiveLeadSummaries, getActiveLeadsByContactOwnerId]);

  const mySequence = useMemo(() => groupByStage(sequenceData?.getSequence), [sequenceData?.getSequence]);

  useEffect(() => {
    if (leadSummaries?.length && selectedAgent) {
      const grouped = leadSummaries?.reduce((r, a) => {
        const step = `${a.step}`;
        r[step] = a.totalLeads;
        return r;
      }, {});

      setGroupedLeads(grouped);
    } else {
      setGroupedLeads({});
    }
  }, [leadSummaries, selectedAgent]);


  return (
    <>
      <Helmet>
        <title> Sequence | Auto Caller UI </title>
      </Helmet>
      {loading || loadingLeadSummaries || loadingLeadsByContactOwerId || loadingSequenceData ? (
        <Loader />
      ) : (
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Stack direction="column" spacing={0} alignItems="flex-start">
                  <Typography variant="h3">{`${user?.organization?.name} Outreach`}</Typography>
                  <Typography variant="caption">{LABELS.Manage}</Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  {userHaveOptions && (
                    <>
                      <TextField select size="small" value={selectedAgent} onChange={handleChange}>
                        <MenuItem value="all">All agents</MenuItem>
                        {agents?.map((user) => (
                          <MenuItem value={user?.hubSpotId} key={user?._id}>
                            {user?.firstName} {user?.lastName}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: '#08070833',
                          color: '#080708',
                        }}
                        size="small"
                        onClick={() => navigate('/sequence/manage')}
                        startIcon={<img src="/assets/icons/ic_edit.svg" width="30" alt="edit" />}
                      >
                        {' '}
                        Edit Flow
                      </Button>
                    </>
                  )}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChangeOfTab} aria-label="basic tabs example">
                    <Tab label="Overview" {...a11yProps(0)} />
                    <Tab label="Companies" {...a11yProps(1)} />
                    <Tab label="Calls" {...a11yProps(2)} />
                    <Tab label="Emails" {...a11yProps(3)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <SequenceOverview
                    mySequence={mySequence}
                    groupedLeads={groupedLeads}
                    leadsCount={leadsCount}
                    selectedAgent={selectedAgent}
                    handleOpenSequenceAutoCaller={handleOpenSequenceAutoCaller}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <SequenceCompanies
                    leads={leads || []}
                    totalLeads={totalLeads || 0}
                    onLoadMore={loadMoreLeads}
                    loading={loadingAllLeads}
                    page={page}
                    setPage={setPage}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <SequenceCallLogs />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <SequenceEmailLogs />
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
      <ReportConfirmationDialog open={open} handleClose={handleClose} />
      {openSequenceAutoCaller && (
        <SequenceAutoCaller open={openSequenceAutoCaller} handleClose={handleCloseSequenceAutoCaller} />
      )}
    </>
  );
}
