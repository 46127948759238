import { gql } from '@apollo/client';

export const GET_ALL_APPOINTMENTS = gql`
    query{
        getAllAppointments{
            _id
            start
            end
            title
            note
        }
    }
`;

export const CREATE_APPOINTMENT = gql`
    mutation createAppointment($input: CalendarInput!) {
        createAppointment(input: $input) {
          _id
        }
    }
`;
