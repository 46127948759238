import React, { useEffect } from 'react';
import { Dialog, Typography, Button, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { OPT_OUT_COMPANY } from '../../../graphql/mutations/lead';

function CompanyOptOutConfirmationDailog({ open, handleClose, company }) {
  const [optOutLead, { data, loading }] = useMutation(OPT_OUT_COMPANY);
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    optOutLead({
      variables: {
        input: {
        _id: company?._id,
        optOutReason: data?.optOutReason,
        }
      },
    });
  };

  useEffect(() => {
    if (data) {
      enqueueSnackbar('Company successfully opted out', { variant: 'success' });
      handleClose();
    }
  }, [data,enqueueSnackbar, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form style={{ padding: '15px' }} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" gutterBottom>
          Are you sure you want to opt out {`${company?.simplifiedCompanyName}`}?
        </Typography>
        <TextField
          rows={4}
          name="optOutReason"
          id="optOutReason"
          multiline
          fullWidth
          label="Reason for Opting Out"
          {...register('optOutReason', { required: true })}
        />

        <Stack direction="row" alignItems="center" my={2} spacing={1}>
          <LoadingButton variant="contained" color="error" type="submit" loading={loading}>
            Opt out
          </LoadingButton>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
}

CompanyOptOutConfirmationDailog.propTypes = {
  company: PropTypes.object.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

CompanyOptOutConfirmationDailog.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default CompanyOptOutConfirmationDailog;
