import {
  Download,
  PlayCircleSharp,
  RefreshOutlined,
  StopCircleSharp,
} from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  IconButton,
  Stack,
  CardHeader,
} from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import { fDateTime } from '../../../utils/formatTime';
import { truncateText } from '../../../utils/textHelper';

export const CallPollingRecordRow = ({ row }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio] = useState(new Audio(row?.callRecordingUrl));

  const handlePlay = () => {
    if (!isPlaying) {
      audio.play();
      setIsPlaying(true);
    }
  };

  const handleStop = () => {
    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Tooltip title={row.callSid}>
          <Typography variant="body2">{truncateText(row.callSid, 10)}</Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={row.agentName}>
          <Typography variant="body2">{truncateText(row.agentName, 8)}</Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.to}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {row?.direction?.startsWith('outbound') ? 'Outbound' : row?.direction}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.duration}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
          {row.amdStatus}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
          {row.isManualEnd === true ? 'AGENT' : row.isManualEnd === false ? 'AMD' : 'UNKNOWN'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{fDateTime(row.createdAt)}</Typography>
      </TableCell>
      <TableCell>
        {row?.callRecordingUrl ? (
          <>
            {isPlaying ? (
              <IconButton onClick={handleStop}>
                <StopCircleSharp />
              </IconButton>
            ) : (
              <IconButton onClick={handlePlay}>
                <PlayCircleSharp />
              </IconButton>
            )}
            <a href={row?.callRecordingUrl} download>
              <IconButton>
                <Download />
              </IconButton>
            </a>
          </>
        ) : (
          <Typography variant="body2">No recording available</Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default function ConnectedCallsList({ connectedCallLogs, loading, getConnectedCallLogs, dateInterval }) {
  const [state, setState] = useState({
    page: 0,
    limit: 100,
    skip: 0,
    search: '',
    filter: {},
    sort: {
      field: '_id',
      order: 'desc',
    },
  });

  const { limit, skip } = state;

  const dataCount = useMemo(() => connectedCallLogs?.length || 0, [connectedCallLogs]);

  useEffect(() => {
    getConnectedCallLogs({ variables: { input: dateInterval, limit, skip } });
  }, [skip]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardHeader title="Connected Calls Log" sx={{ p: 0, mt: 4 }} />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`Page - ${skip / 100 + 1}`}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              size="small"
              placeholder="Search"
              variant="outlined"
              value={state.search}
              onChange={(e) => setState({ ...state, search: e.target.value })}
            />
            <IconButton
              disabled={skip < 100 || loading}
              onClick={() => setState({ ...state, skip: skip - 100 })}
              aria-label="previous page"
            >
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton
              onClick={() => setState({ ...state, skip: skip + 100 })}
              disabled={dataCount < 100 || loading}
              aria-label="next page"
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <IconButton
              onClick={() => getConnectedCallLogs({ variables: { input: dateInterval, limit, skip } })}
              aria-label="refresh"
            >
              <RefreshOutlined />
            </IconButton>
            {loading && <CircularProgress size={24} />}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Conversation Sid</TableCell>
                <TableCell>Agent</TableCell>
                <TableCell>To</TableCell>
                <TableCell>Direction</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>PickedUp By</TableCell>
                <TableCell>Detected By</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Recording</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connectedCallLogs?.map((row, index) => (
                <CallPollingRecordRow key={index} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
