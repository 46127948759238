// @mui
import { ExpandMoreOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { openLinkInNewTab } from '../../../utils/helperFunctions';
import { AppWebsiteVisits } from '../app';

function CompanyDetails({ activeLead }) {
  const formatString = (str) => str?.replace(/,/g, ', ');
  const [graphData, setGraphData] = useState({
    labels: [],
    data: [],
  });

  useEffect(() => {
    const labels = [];
    const data = [];
    if (activeLead?.trafficMonth1) {
      labels.push(activeLead?.trafficMonth1);
      data.push(activeLead?.trafficValue1);
    }
    if (activeLead?.trafficMonth2) {
      labels.push(activeLead?.trafficMonth2);
      data.push(activeLead?.trafficValue2);
    }
    if (activeLead?.trafficMonth3) {
      labels.push(activeLead?.trafficMonth3);
      data.push(activeLead?.trafficValue3);
    }
    if (activeLead?.trafficMonth4) {
      labels.push(activeLead?.trafficMonth4);
      data.push(activeLead?.trafficValue4);
    }
    if (activeLead?.trafficMonth5) {
      labels.push(activeLead?.trafficMonth5);
      data.push(activeLead?.trafficValue5);
    }
    if (activeLead?.trafficMonth6) {
      labels.push(activeLead?.trafficMonth6);
      data.push(activeLead?.trafficValue6);
    }
    setGraphData({
      labels,
      data,
    });
  }, [activeLead]);

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent sx={{ padding: 0 }}>
        <Stack direction="column" spacing={1} sx={{ p: 2 }}>
          <Typography variant="h6">Company Details</Typography>
          <Typography>All the details about the company and the people working there</Typography>
        </Stack>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1a-content" id="panel1a-header">
                <Stack direction="column" justifyContent="center" spacing={1}>
                  <Typography variant="h6">Basic Details</Typography>
                  <Typography>Information about basic details; name, industry, location, type and about</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {activeLead?.simplifiedCompanyName && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Name
                        </Typography>
                        <Typography variant="body2">{activeLead?.simplifiedCompanyName}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.simplifiedCompanyIndustry && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Industry:
                        </Typography>{' '}
                        <Typography variant="body2">{activeLead?.simplifiedCompanyIndustry}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.type && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Type:
                        </Typography>

                        <Typography variant="body2">{activeLead?.type}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.simplifiedCompanyLocation && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          {' '}
                          Location:
                        </Typography>

                        <Typography variant="body2">{activeLead?.simplifiedCompanyLocation}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.companyDomainName && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Domain Name:
                        </Typography>
                        <Typography variant="body2">{activeLead?.companyDomainName}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.companyLinkedinProfile && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Linkedin Company Page:
                        </Typography>
                        <Typography variant="body2">{activeLead?.companyLinkedinProfile}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.companyPhoneNumber && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Phone Number:
                        </Typography>
                        <Typography variant="body2">{activeLead?.companyPhoneNumber}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.companyEmail && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Email:
                        </Typography>
                        <Typography variant="body2">{activeLead?.companyEmail}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.minimumCompanySize && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Minimum Company Size:
                        </Typography>
                        <Typography variant="body2">{activeLead?.minimumCompanySize}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.maximumCompanySize && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Maximum Company Size:
                        </Typography>
                        <Typography variant="body2">{activeLead?.maximumCompanySize}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.generatedCompanyDescription && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Description:
                        </Typography>
                        <Typography variant="body2">{activeLead?.generatedCompanyDescription}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.recommendation && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Recommendation:
                        </Typography>
                        <Typography variant="body2">{activeLead?.recommendation}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.websiteTrafficAnalysis && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Website Traffic Analysis:
                        </Typography>
                        <Typography variant="body2">{activeLead?.websiteTrafficAnalysis}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.nearbyLocation1 && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Nearby Location 1:
                        </Typography>
                        <Typography variant="body2">{activeLead?.nearbyLocation1}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.nearbyLocation2 && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Nearby Location 2:
                        </Typography>
                        <Typography variant="body2">{activeLead?.nearbyLocation2}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.yearFounded && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Year Founded:
                        </Typography>
                        <Typography variant="body2">{activeLead?.yearFounded}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.minimumAnnualRevenue && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1} justifyContent="flex-start">
                        <Typography variant="caption" color="inherit">
                          Minimum Annual Revenue:
                        </Typography>
                        <Typography variant="body2">{activeLead?.minimumAnnualRevenue}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.maximumAnnualRevenue && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1} justifyContent="flex-start">
                        <Typography variant="caption" color="inherit">
                          Maximum Annual Revenue:
                        </Typography>
                        <Typography variant="body2">{activeLead?.maximumAnnualRevenue}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {graphData.labels.length > 0 && graphData.data.length > 0 && (
                    <Grid item xs={12} md={12}>
                      <AppWebsiteVisits
                        title="Website Traffic Analysis:"
                        subheader={activeLead?.websiteTrafficAnalysis ? activeLead?.websiteTrafficAnalysis : 'Last 6 months'}
                        chart={{
                          labels: graphData.labels,
                          series: [
                            {
                              name: 'Analysed Traffic',
                              type: 'area',
                              fill: 'gradient',
                              data: graphData.data,
                            },
                          ],
                        }}
                      />
                    </Grid>
                  )}
                  {activeLead?.expertDocumentLink && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Stack direction="column" spacing={1} justifyContent="flex-start">
                        <Typography variant="caption" color="inherit">Expert Document Link: </Typography>
                          <div dangerouslySetInnerHTML={{ __html: activeLead?.expertDocumentLink }}/>
                      </Stack>
                    </Grid>
                  )}

                  {activeLead?.expertDocumentHtmlEmbed && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1} justifyContent="flex-start">
                        <Typography variant="caption" color="inherit">Expert Document HTML Embed: </Typography>
                        <Typography variant="body2">{activeLead?.expertDocumentHtmlEmbed}</Typography>
                      </Stack>
                    </Grid>
                  )}

                  {activeLead?.industry && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Industry:
                        </Typography>
                        <Typography variant="body2">{activeLead?.industry}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.industryTags && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Industry Tags:
                        </Typography>
                        <Typography variant="body2">{formatString(activeLead?.industryTags)}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={6}>
                    {activeLead?.aboutCompany && (
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          About :
                        </Typography>
                        <Typography variant="body2">{activeLead?.aboutCompany}</Typography>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1a-content" id="panel1a-header">
                <Stack direction="column" justifyContent="center" spacing={1}>
                  <Typography variant="h6">Job details</Typography>
                  <Typography>Information about the jobs posted by this company</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {activeLead?.jobSource && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Job Source:
                        </Typography>
                        <Typography variant="body2">{activeLead?.jobSource}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.jobSourceLink && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Job Source Link:
                        </Typography>

                        <Typography variant="body2">{activeLead?.jobSourceLink}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.jobPostTitle && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Job Post Title:
                        </Typography>
                        <Typography variant="body2">{activeLead?.jobPostTitle}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.jobLocation && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Job Location:
                        </Typography>
                        <Typography variant="body2">{activeLead?.jobLocation}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.jobType && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Job Type:
                        </Typography>
                        <Typography variant="body2">{activeLead?.jobType}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.jobPostDate && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption" color="inherit">
                          Job Post Date:
                        </Typography>
                        <Typography variant="body2">{activeLead?.jobPostDate}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {activeLead?.jobPostUrl && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Stack direction="column" spacing={1} justifyContent="flex-start">
                        <Typography variant="caption" color="inherit">URL: </Typography>
                        {activeLead?.jobPostUrl && (<Link href="#" onClick={() => openLinkInNewTab(activeLead?.jobPostUrl)}>{activeLead?.jobPostUrl}</Link>)}
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CompanyDetails.propTypes = {
  activeLead: PropTypes.object,
};

export default CompanyDetails;
