import { gql } from '@apollo/client';

export const GET_ALL_SEQUENCE = gql`
 query{
    getSequence{
      _id
      step
      stage
      title
      description
      type
    }
  }
`;

export const UPDATE_SEQUENCE = gql`
  mutation updateSequenceMultiple($input: [SequenceInput!]!) {
    updateSequenceMultiple(input: $input) {
      _id
      step
      stage
      title
      description
      type
    }
  }
`;