import { gql } from '@apollo/client';

export const CREATE_CAMPAIGN = gql`
mutation createCampaign($input: CampaignInput!) {
    createCampaign(input: $input) {
      title
      type
      description
      start
      end
      step
      status
      callbackDuration
      callbackTimeOfDay
      attempts
      sleepInterval
      callbackDuration
      callbackTimeOfDay
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
mutation updateCampaign($_id: String!, $input: CampaignInput!) {
    updateCampaign(_id: $_id, input: $input) {
      title
      type
      description
      start
      end
      step
      status
      callbackDuration
      callbackTimeOfDay
      attempts
      sleepInterval
      callbackDuration
      callbackTimeOfDay
    }
  }
`;

export const DELETE_CAMPAIGN = gql`
mutation deleteCampaign($_id: String!) {
  deleteCampaign(_id: $_id) {
    title
    type
    description
    start
    end
    step
    status
    callbackDuration
    callbackTimeOfDay
    attempts
    sleepInterval
    callbackDuration
    callbackTimeOfDay
  }
}
`;