import { Helmet } from 'react-helmet-async';
// @mui
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Container, Divider, Grid, IconButton, LinearProgress, Link, MenuItem, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
// components
import LoadingButton from '@mui/lab/LoadingButton';
// mock
import { useLazyQuery, useMutation } from '@apollo/client';
import { Delete, Edit } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Loader from '../components/loader';
import { CREATE_CAMPAIGN, DELETE_CAMPAIGN, UPDATE_CAMPAIGN } from '../graphql/mutations/campaign';
import { UPDATE_ORGANIZATION_CAMPAIGN } from '../graphql/mutations/training';
import { GET_CAMPAIGNS } from '../graphql/queries/campaign';
import { GET_USER_ORGANIZATION } from '../graphql/queries/training';
import { LABELS } from '../utils/constants';
import { fDateTime } from '../utils/formatTime';
import { a11yProps } from '../utils/helperFunctions';

// ---------------------------------------------------------------------- 

export default function CampaignPage() {

    const [updateOrganizationCampaign, { data: trainingCampaign, loading: loadingTrainingCampaign }] = useMutation(UPDATE_ORGANIZATION_CAMPAIGN);
    const [createCampaign, { data: newCampaign, loading: loadingCreateCampaign }] = useMutation(CREATE_CAMPAIGN);
    const [updateCampaign, { data: updatedCampaign, loading: loadingUpdateCampaign }] = useMutation(UPDATE_CAMPAIGN);
    const [deleteCampaign, { data: deletedCampaign, loading: loadingDeleteCampaign }] = useMutation(DELETE_CAMPAIGN);
    const [getCampaigns, { data, loading }] = useLazyQuery(GET_CAMPAIGNS);
    const [getOrganization, { data: organizationData, loading: organizationLoading }] = useLazyQuery(GET_USER_ORGANIZATION);
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState(0);
    const [activeCampaign, setActiveCampaign] = useState(null);
    const [campaignUpdate, setCampaignUpdate] = useState(null);

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            Sequence
        </Link>,
        <Typography key="3" color="text.primary">
            Campaigns
        </Typography>,
    ];

    const handleSetActiveCampaign = (campaign) => {
        updateOrganizationCampaign({
            variables: {
                campaign
            }
        });
    }

    useEffect(() => {
        getOrganization();
        getCampaigns();
    }, []);

    useEffect(() => {
        if (trainingCampaign) {
            enqueueSnackbar("Campaign activated successfully", { variant: "success" });
        }
        getOrganization();
    }, [trainingCampaign]);

    useEffect(() => {
        if (organizationData) {
            setActiveCampaign(organizationData?.getOrganization?.campaign);
        }
    }, [organizationData]);

    useEffect(() => {
        if (newCampaign) {
            enqueueSnackbar("Campaign created successfully", { variant: "success" });
            getCampaigns();
        }
    }, [newCampaign]);

    useEffect(() => {
        if (updatedCampaign) {
            enqueueSnackbar("Campaign updated successfully", { variant: "success" });
            getCampaigns();
        }
    }, [updatedCampaign]);

    useEffect(() => {
        if (deletedCampaign) {
            enqueueSnackbar("Campaign deleted successfully", { variant: "success" });
            getCampaigns();
        }
    }, [deletedCampaign]);

    const { handleSubmit, register, setValue:setForm, reset } = useForm();

    const onSubmit = (data) => {
        const formattedData = {
            ...data,
            step: parseInt(data.step, 10),
            attempts: parseInt(data.attempts, 10),
            sleepInterval: parseInt(data.sleepInterval, 10),
            callbackDuration: parseInt(data.callbackDuration, 10),
            callbackTimeOfDay: parseInt(data.callbackTimeOfDay, 10),
            simultaneousCalls: parseInt(data.simultaneousCalls, 10),
            start: new Date(data.start).toISOString(),
            end: new Date(data.end).toISOString()
        }

        if (campaignUpdate) {
            updateCampaign({
                variables: {
                    _id: campaignUpdate._id,
                    input: formattedData
                }
            });
        }else{
            createCampaign({
                variables: {
                    input: formattedData
                }
            });
        }
      
    };

    const handleUpdate = (data) => {
        setCampaignUpdate(data);
        setForm('title', data.title);
        setForm('type', data.type);
        setForm('description', data.description);
        setForm('start', data.start?.split("T")[0]);
        setForm('end', data.end?.split("T")[0]);
        setForm('step', data.step);
        setForm('attempts', data.attempts);
        setForm('sleepInterval', data.sleepInterval);
        setForm('callbackDuration', data.callbackDuration);
        setForm('callbackTimeOfDay', data.callbackTimeOfDay);
        setForm('simultaneousCalls', data.simultaneousCalls);
        setValue(1);
    }

    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            setCampaignUpdate(null);
            getOrganization();
            reset();
        }
        setValue(newValue);
    }

    const handleDelete = (campaign) => {
        deleteCampaign({
            variables: {
                _id: campaign._id
            }
        });
    }

    return (
        <>
            <Helmet>
                <title> Sequence: Campaigns | Auto Caller UI </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack spacing={2} direction='row' justifyContent="space-between" alignItems="center">
                            <Stack direction="column" spacing={0} alignItems="flex-start">
                                <Typography variant="h3">{LABELS.Campaigns}</Typography>
                                <Typography variant="caption">{LABELS.CampaignsDescription}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Campaigns" {...a11yProps(0)} />
                            <Tab label="New Campaign" {...a11yProps(1)} />
                        </Tabs>
                        <Divider />
                    </Grid>

                    {loading && <Loader />}
                    {(loadingTrainingCampaign || organizationLoading || loadingDeleteCampaign) && <Grid item xs={12} sm={12} md={12}><LinearProgress /></Grid>}
                    {value === 0 && (
                        <Grid item xs={12} sm={12} md={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Title
                                            </TableCell>
                                            <TableCell
                                            >
                                                Type
                                            </TableCell>
                                            <TableCell
                                            >
                                                Description
                                            </TableCell>
                                            <TableCell>
                                                Start
                                            </TableCell>
                                            <TableCell>
                                                End
                                            </TableCell>
                                            <TableCell>
                                                Step
                                            </TableCell>
                                            <TableCell>
                                                Attempts
                                            </TableCell>
                                            <TableCell>
                                                Sleep Interval
                                            </TableCell>
                                            <TableCell>
                                                Callback Duration
                                            </TableCell>
                                            <TableCell>
                                                Callback Time of Day
                                            </TableCell>
                                            <TableCell>
                                                Created At
                                            </TableCell>
                                            <TableCell>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.getCampaign?.map((campaign) => (
                                            <TableRow key={campaign._id}>
                                                <TableCell>
                                                    {campaign.title}
                                                </TableCell>
                                                <TableCell>
                                                    {campaign.type}
                                                </TableCell>
                                                <TableCell>
                                                    {campaign.description}
                                                </TableCell>
                                                <TableCell>
                                                    {fDateTime(campaign.start)}
                                                </TableCell>
                                                <TableCell>
                                                    {fDateTime(campaign.end)}
                                                </TableCell>
                                                <TableCell>
                                                    {campaign.step}
                                                </TableCell>
                                                <TableCell>
                                                    {campaign.attempts}
                                                </TableCell>
                                                <TableCell>
                                                    {campaign.sleepInterval}
                                                </TableCell>
                                                <TableCell>
                                                    {campaign.callbackDuration}
                                                </TableCell>
                                                <TableCell>
                                                    {campaign.callbackTimeOfDay}
                                                </TableCell>
                                                <TableCell>
                                                    {fDateTime(campaign.createdAt)}
                                                </TableCell>
                                                <TableCell>
                                                    <Stack direction="row" spacing={2}>
                                                        <Button variant="outlined" onClick={() => handleSetActiveCampaign(campaign._id)} disabled={activeCampaign === campaign._id}>
                                                            {activeCampaign === campaign._id ? "Active" : "Activate"}
                                                        </Button>
                                                        <IconButton onClick={() => handleUpdate(campaign)}>
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleDelete(campaign)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                    {value === 1 && (
                        <Grid item container spacing={2} xs={12} sm={12} md={12} component="form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xs={12} sm={12} md={12}>
                                {/* {error && <Alert severity="error">{error?.message}</Alert>} */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth name="title" required label="Title" {...register('title', { required: true })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth select name="type" required label="Type" {...register('type', { required: true })} >
                                    <MenuItem value="VOICE">VOICE</MenuItem>
                                    <MenuItem value="EMAIL">EMAIL</MenuItem>
                                    <MenuItem value="SMS">SMS</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth name="description" required label="Description" {...register('description', { required: true })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth type='number' name="simultaneousCalls" required label="Simultaneous Calls" {...register('simultaneousCalls', { required: true })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth type='date' name="start" required label="Start" {...register('start', { required: true })} />
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth type='date' name="end" required label="End" {...register('end', { required: true })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth type='number' name="step" required label="Step" {...register('step', { required: true })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth type='number' name="attempts" required label="Attempts" {...register('attempts', { required: true })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth type='number' name="sleepInterval" required label="Sleep Interval" {...register('sleepInterval', { required: true })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth type='number' name="callbackDuration" required label="Callback Duration" {...register('callbackDuration', { required: true })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth type='number' name="callbackTimeOfDay" required label="Callback Time of Day" {...register('callbackTimeOfDay', { required: true })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box>
                                    <LoadingButton type="submit" variant="contained" loading={loadingCreateCampaign || loadingUpdateCampaign}>
                                        {campaignUpdate? "Update Campaign" : "Create Campaign"}
                                    </LoadingButton>
                                </Box>
                            </Grid>

                        </Grid>
                    )}
                </Grid>
            </Container>
        </>
    );
}
