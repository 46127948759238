import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_AGENTS_WITH_MOST_CONNECTIONS } from '../../graphql/queries/call.analytics';

const useAgentsWithMostConnections = (dateInterval) => {
  const [getAgentsWithMostConnections, { data, loading, error }] = useLazyQuery(GET_AGENTS_WITH_MOST_CONNECTIONS);
  useEffect(() => {
    if (dateInterval) {
        getAgentsWithMostConnections({ variables: { input: dateInterval } });
    }
  }, [dateInterval, getAgentsWithMostConnections]);

  return { data: data?.getAgentWithMostConnections, loading, error };
};

export default useAgentsWithMostConnections;
