// @mui
import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Button, Card, CardContent, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
// hooks
// components
// utils
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import EmptyContent from '../../../components/empty-content/empty-content';
import { CREATE_NOTE } from '../../../graphql/mutations/note';
import { GET_LEAD_NOTES } from '../../../graphql/queries/note';
import { fToNow } from '../../../utils/formatTime';

function Notes({ activeLead }) {
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState('');
  const [allNotes, setAllNotes] = useState([]);
  const [getLeadNotes, { data: fetchedNotes}] = useLazyQuery(GET_LEAD_NOTES);
  const [createNote, { data, loading }] = useMutation(CREATE_NOTE);
  const {enqueueSnackbar} = useSnackbar();

  const handleCreateNote = () => {
    createNote({
      variables: {
        input: {
          notes,
          lead: activeLead._id,
        },
      },
    });
  };

  useEffect(() => {
    if (activeLead) {
      getLeadNotes({ variables: { lead: activeLead._id } });
    }
  }, [activeLead, getLeadNotes]);

  useEffect(() => {
    if (data) {
      setOpenNotes(false);
      setNotes("");
      enqueueSnackbar({message: "Note created successfully", options: {variant: "success"}});
      getLeadNotes({ variables: { lead: activeLead._id } });
      // setAllNotes(fetchedNotes.getNoteByLead);
    }
  }
  , [data, enqueueSnackbar, getLeadNotes, activeLead]);

  useEffect(() => { 
    if (fetchedNotes) {
      const notes = fetchedNotes.getNoteByLead;
      if (notes?.length) {
        setAllNotes(notes);
      }
    }
  }, [fetchedNotes]);

  const renderEmpty = (
    <EmptyContent
      title={`Nothing in notes`}
      description="This section is empty"
      imgUrl="/assets/icons/empty/ic_folder_empty.svg"
      sx={{
        borderRadius: 1.5,
        bgcolor: 'background.default',
      }}
    />
  );

  return (
    <>
      {!openNotes ? (
        <Card sx={{ minWidth: 275 }}>
          <CardContent sx={{ padding: 0 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
              <Typography variant="h6">Recent Notes</Typography>{' '}
              <IconButton onClick={() => setOpenNotes(true)}>
                <img src="/assets/icons/ic_notes.svg" alt="notes" width="30" height="30" />
              </IconButton>
            </Stack>
            <Divider />
            {!loading && !allNotes?.length && renderEmpty}
            {allNotes?.map((note) => (
              <Stack direction="column" spacing={1} sx={{ p: 2 }} key={note?._id}>
                <Typography variant="h6" color="inherit">
                  {activeLead?.simplifiedCompanyName}
                </Typography>
                <Typography variant="body2" color="inherit">
                  {note?.notes}
                </Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar alt={note?.user?.firstName} src={note?.user?.profilePicture} />
                    <Typography variant="caption">
                      {note?.user?.firstName} {note?.user?.lastName}
                    </Typography>
                  </Stack>
                  <Typography variant="caption">{fToNow(note?.createdAt)}</Typography>
                </Stack>
                <Divider />
              </Stack>
            ))}
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ minWidth: 275 }}>
          <CardContent sx={{ padding: 0 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
              <Typography variant="h6">Add New Note</Typography>{' '}
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button color="primary" disabled={!notes.length || loading} size="small" onClick={handleCreateNote}>
                  {loading? "Saving..": "Save"}
                </Button>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="column" alignItems="center" sx={{ p: 2 }}>
              <TextField
                id="outlined-multiline-static"
                fullWidth
                multiline
                rows={5}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                variant="outlined"
              />
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
}

Notes.propTypes = {
  activeLead: PropTypes.object,
};

export default Notes;
