import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PAGINATED_EMAIL_LOGS } from '../../../graphql/queries/log';
import { fDate, fDateTime } from '../../../utils/formatTime';

export default function SequenceEmailLogs() {
  // const { data, loading } = useQuery(GET_ALL_EMAIL_LOGS);
  const limit = 100;
  const [skip, setSkip] = useState(0);
  const [getEmailLogsPaginate, { data: paginatedData, loading }] = useLazyQuery(GET_PAGINATED_EMAIL_LOGS);

  useEffect(() => {
    getEmailLogsPaginate({ variables: { limit, skip } });
  }, [getEmailLogsPaginate, limit, skip]);

  const dataCount = useMemo(
    () => paginatedData?.getEmailLogsPaginate?.length || 0,
    [paginatedData?.getEmailLogsPaginate]
  );
  const groupedEmailData = useMemo(() => {
    const groupedData = {};
    paginatedData?.getEmailLogsPaginate?.forEach((item) => {
      const createdAtDate = item.createdAt.split('T')[0]; // Extract the date part
      if (!groupedData[createdAtDate]) {
        groupedData[createdAtDate] = [item];
      } else {
        groupedData[createdAtDate].push(item);
      }
    });

    return groupedData;
  }, [paginatedData?.getEmailLogsPaginate]);

  return (
    <Card>
      <CardContent>
        <Stack direction="column" alignItems="flex-start" spacing={1}>
          <Typography variant="h5">Email Logs Details</Typography>
          <Typography variant="caption">All the emails sent through out this workflow</Typography>
        </Stack>
        <Divider sx={{ my: 2 }} />

        <Stack direction="column" spacing={2}>
          {loading && <CircularProgress />}
          {Object.keys(groupedEmailData).map((stage) => (
              <Fragment key={stage}>
                <Divider>{fDate(stage)}</Divider>
                {groupedEmailData[stage]?.map((log) => (
                  <Accordion key={log._id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreOutlined />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <Typography>
                          {`Sent by ${log?.user?.firstName} ${log?.user?.lastName} to ${log?.name} at`}
                        </Typography>
                        <Link href="#">{log?.email}</Link>
                        <Typography>{`at ${fDateTime(log?.createdAt)}`}</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="flex-start">
                        <Typography variant="body1">Name: {log?.name}</Typography>
                        <Typography variant="body1">Email: {log?.email}</Typography>
                        <Typography variant="body1">Email Sent: {log?.emailSent}</Typography>
                        <Typography variant="body1">Email Personalized: {log?.emailPersonalized}</Typography>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Fragment>
            ))}
          <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
            <Typography variant="body1">
              Showing {paginatedData?.getEmailLogsPaginate?.length} of Many{' '}
              entries
            </Typography>
            <Button
              disabled={(skip < 100) || loading}
              onClick={() => {
                setSkip(skip - 100);
              }}
            >
              Previous
            </Button>
            <Button
              onClick={() => {
                setSkip(skip + 100);
              }}
              disabled={(dataCount < 100) || loading}
            >
              Next
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
