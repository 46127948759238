import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Divider, IconButton, InputAdornment, Link, Stack, TextField } from '@mui/material';
// components
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Iconify from '../../../components/iconify';
import { SIGNUP } from '../../../graphql/mutations/auth';
import { setUser } from '../../../redux/auth.slice';

// ----------------------------------------------------------------------
export default function SignUpForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [signUp, { data, loading, error }] = useMutation(SIGNUP);

  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    signUp({
      variables: { input: data },
    });
  };

  useEffect(() => {
    if (data && data.signUp) {
      dispatch(setUser(data.signUp));
      navigate('/sequence', { replace: true });
    }
  }, [data, dispatch, navigate]);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
       {error && <Alert severity="error">{error?.message}</Alert>}
        <Divider>Company/Organization Information</Divider>
        <TextField name="name" label="Company Name" {...register('name', { required: true })} />
        <TextField name="emailAddress" label="Company Email Address" {...register('emailAddress', { required: true })} />
        <TextField name="fullAddress" label="Company Address" {...register('fullAddress', { required: true })} />
        <TextField name="numberOfUsers" label="Number of Users" {...register('numberOfUsers', { required: true })} />
        <Divider>Personal Information</Divider>
        <TextField name="firstName" label="First Name" {...register('firstName', { required: true })} />
        <TextField name="lastName" label="Last Name" {...register('lastName', { required: true })} />
        <TextField name="username" label="Username" {...register('username', { required: true })} />
        <TextField name="email" label="Email address" {...register('email', { required: true })} />
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password', { required: true })}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" onClick={()=>navigate('/login')}>
          already have an account?
        </Link>
      </Stack>

      <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained">
        Sign Up
      </LoadingButton>
    </form>
  );
}
