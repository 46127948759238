import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import {
  styled,
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Container,
  Divider,
  Button,
  Stack,
  Link,
} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import PersonIcon from '@mui/icons-material/Person';
// components
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { removeExpiredConnectedCalls, setActiveAgents, setQueuedLeads } from '../redux/twilio.slice';
import useTwilioService from '../services/twilio/use.twilio.service';
import ConnectedCallsMain from './twilio-caller/components/ConnectedCallsMain';

// ----------------------------------------------------------------------


export default function TwilioPoolDetailsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeLead, activeAgents, queuedLeads, connectedCalls } = useSelector((state) => state.twilio);
  const { fetchActiveAgents, fetchQueuedLeads } = useTwilioService();
  const [loadingActiveAgents, setLoadingActiveAgents] = useState(true);
  const [loadingQueuedLeads, setLoadingQueuedLeads] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(removeExpiredConnectedCalls());
    }, 5000);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchActiveAgents();
      } catch (error) {
        console.error('Error fetching active agents:', error);
      } finally {
        setLoadingActiveAgents(false);
      }

      try {
        await fetchQueuedLeads();
      } catch (error) {
        console.error('Error fetching queued leads:', error);
      } finally {
        setLoadingQueuedLeads(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (activeAgents) {
      setActiveAgents(activeAgents);
    }
  }, [activeAgents]);

  useEffect(() => {
    if (queuedLeads) {
      console.log("QuedLeads...", queuedLeads);
      setQueuedLeads(queuedLeads);
      // dispatch(setQueuedLeads(queuedLeads));
    }
  }, [queuedLeads]);

  const handleClick = () => {
    navigate('/sequence');
  };

  const handleViewAllCompanies = () => {
    navigate('/sequence/companies');
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
      Sequence
    </Link>,
    <Typography key="3" color="text.primary">
      {activeLead?.phone}
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title> Contact Details | Auto Caller UI </title>
      </Helmet>

      <Container maxWidth="xl" style={{ border: '0px solid green', height: '80vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
              <Stack direction="row" spacing={1}>
                <Button
                  size="small"
                  startIcon={<img src="/assets/icons/ic_calender.svg" width="20" alt="call" />}
                  onClick={handleViewAllCompanies}
                >
                  See all contacts
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  p: 2,
                  height: '85vh',
                  maxHeight: '85vh',
                  overflowY: 'auto',
                }}
              >
                {activeLead?.company ? (
                  <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" spacing={1}>
                    <Typography variant="h3">{`${activeLead?.company || ''}`}</Typography>
                    <Typography variant="h6">Contact Information</Typography>

                    <Divider style={{ backgroundColor: '#6605B8', border: '1px Solid blue', width: '100%' }} />
                    <Typography variant="body2">
                      {`Name: ${activeLead?.firstName || ''} ${activeLead?.lastName || ''}`}
                    </Typography>
                    <Typography variant="body2">{`Phone Number: ${activeLead?.prospectPhoneNumber || ''}`}</Typography>
                    <Typography variant="body2">{`Email: ${activeLead?.emailAddress || ''}`}</Typography>
                    <Typography variant="body2">{`Designation: ${activeLead?.prospectDesignation || ''}`}</Typography>
                    <Typography variant="body2">
                      {`Linkedin Profile: ${activeLead?.prospectLinkedinProfile || ''}`}
                    </Typography>
                    <Typography variant="body2">{`Time Zone: ${activeLead?.timeZone || ''}`}</Typography>
                  </Stack>
                ) : (
                  <Typography variant="body2">No active lead in the pool</Typography>
                )}

                <Divider />
                {connectedCalls?.length > 0 ? <ConnectedCallsMain connectedCalls={connectedCalls} /> : null}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Section>
              <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                Clients in Queue (Total: {queuedLeads?.length})
              </Typography>
              <GridContainer>
                {loadingQueuedLeads ? (
                  <Typography>Loading...</Typography>
                ) : (
                  queuedLeads?.map((client, index) => (
                    <GridItem key={index}>
                      <Card>
                        <CardContent>
                          <Box display="flex" alignItems="flex-start" gap={2}>
                            <CustomAvatar sx={{ bgcolor: '#103996' }}>
                              <CallIcon sx={{ height: 20, width: 20 }} />
                            </CustomAvatar>
                            <Box>
                              <Typography variant="h6">{`${client?.firstName || ''} ${client?.lastName}` || ''}</Typography>
                              <Typography variant="body2">
                                Company: {client?.company}
                              </Typography>
                              <Typography variant="body2">
                                Phone: {client?.prospectPhoneNumber}
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </GridItem>
                  ))
                )}
              </GridContainer>
            </Section>

          <Section>
            <Typography variant="h6" component="div" sx={{ mb: 2 }}>
              Agents in Pool (Total: {activeAgents?.length})
            </Typography>
            <GridContainer>
              {loadingActiveAgents ? (
                <Typography>Loading...</Typography>
              ) : (
                activeAgents?.map((agent, index) => (
                  <GridItem key={index}>
                    <Card>
                      <CardContent style={{ padding: '10px' }}>
                        <Box display="flex" alignItems="flex-start" gap={2}>
                          <CustomAvatar
                            sx={{
                              bgcolor: agent?.callPollingState?.toLowerCase() === 'active' ? 'green' : 'red',
                            }}
                          >
                            <PersonIcon />
                          </CustomAvatar>
                          <Box>
                            <Typography variant="h6">{agent?.name}</Typography>
                            <Typography variant="body2">
                              Status: <b>{agent?.callPollingState}</b>
                            </Typography>
                            {/* {agent.callPollingState.toLowerCase() === 'busy' && (
                             <Column>
                              <Typography variant="body2">
                                Connected To: <b>{`${agent?.inCallWith?.firstName ?? ''} ${agent?.inCallWith?.lastName ?? ''}`}</b>, - 
                              </Typography>
                              <Typography variant="body2">
                                Company: <b>{agent?.inCallWith?.company ?? ''}</b>
                              </Typography>
                             </Column>
                            )} */}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </GridItem>
                ))
              )}
            </GridContainer>
          </Section>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: '1px dashed black',
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const GridItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  width: '30px',
  height: '30px',
}));

const Column = styled('div')(({ theme }) => ({
  border: '0px solid red',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));
