import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';

export default function DialPad({ sendDMF }) {
    return (
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Button color='info' onClick={() => sendDMF("1")} size='small'> 1 </Button>
                <Button color='info' onClick={() => sendDMF("2")} size='small'> 2 </Button>
                <Button color='info' onClick={() => sendDMF("3")} size='small'> 3 </Button>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Button color='info' onClick={() => sendDMF("4")} size='small'> 4 </Button>
                <Button color='info' onClick={() => sendDMF("5")} size='small'> 5 </Button>
                <Button color='info' onClick={() => sendDMF("6")} size='small'> 6 </Button>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Button color='info' onClick={() => sendDMF("7")} size='small'> 7 </Button>
                <Button color='info' onClick={() => sendDMF("8")} size='small'> 8 </Button>
                <Button color='info' onClick={() => sendDMF("9")} size='small'> 9 </Button>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Button color='info' onClick={() => sendDMF("*")} size='small'> * </Button>
                <Button color='info' onClick={() => sendDMF("0")} size='small'> 0 </Button>
                <Button color='info' onClick={() => sendDMF("#")} size='small'> # </Button>
            </Stack>
        </Stack>
    )
}

DialPad.propTypes = {
    sendDMF: PropTypes.func.isRequired
}