import React from 'react';
import { Dialog, Typography, Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';

function DeleteConfirmationDialog({
    openDelete, 
    handleCloseDelete, 
    handleDeleteUserConfirm
}){
    return (
        <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <div style={{padding:'15px'}}>
          <Typography variant="h6" gutterBottom>
            Are you sure you want to delete this user?
          </Typography>
          <Stack direction="row" alignItems="center" mb={2} spacing={1}>
            <Button variant="contained" color="error" onClick={handleDeleteUserConfirm}>
              Delete
            </Button>
            <Button variant="contained" onClick={handleCloseDelete}>
              Cancel
            </Button>
          </Stack>
        </div>
      </Dialog>
    )
}

DeleteConfirmationDialog.propTypes = {
    openDelete: PropTypes.bool,
    handleCloseDelete: PropTypes.func,
    handleDeleteUserConfirm: PropTypes.func
}

DeleteConfirmationDialog.defaultProps = {
    openDelete: false,
    handleCloseDelete: () => {},
    handleDeleteUserConfirm: () => {}
}

export default DeleteConfirmationDialog;