import { useQuery } from '@apollo/client';
import {
  VonageClient
} from '@vonage/client-sdk';
import { createContext, useEffect, useState } from 'react';
import { GET_USER_TOKEN } from '../graphql/queries/user';

const CallContext = createContext({
  client: null,
  setClient: () => { },
  session: null,
  setSession: () => { },
  error: null,
  setError: () => { },
});

const CallProvider = ({ children }) => {

  const { data } = useQuery(GET_USER_TOKEN);

  const [client, setClient] = useState(() => {
    const client = new VonageClient();
    return client;
  });

  const [error, setError] = useState(null);
  const [session, setSession] = useState(null);

  useEffect(() => {
   if (client && data?.getUserVonageToken) {
    client
    .createSession(data?.getUserVonageToken)
    .then((session) => setSession(session))
    .catch((error) => setError(error));
   }

  }, [client, data?.getUserVonageToken]);


  // useEffect(() => {
  //   if (client) {
  //     const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTYzOTcwMjUsImp0aSI6IjUxYmM1NjkwLTE4NWMtMTFlZi05NjZkLThkMTY3MmRiMzIyMiIsImFwcGxpY2F0aW9uX2lkIjoiZmExNDMxYzMtMjdhMi00NjMwLWJhODAtOWQ1ZTM1MjEyNjkzIiwic3ViIjoiVmVyZGkiLCJleHAiOjE3MTYzOTcwNDc1MTMsImFjbCI6eyJwYXRocyI6eyIvKi9ydGMvKioiOnt9LCIvKi91c2Vycy8qKiI6e30sIi8qL2NvbnZlcnNhdGlvbnMvKioiOnt9LCIvKi9zZXNzaW9ucy8qKiI6e30sIi8qL2RldmljZXMvKioiOnt9LCIvKi9pbWFnZS8qKiI6e30sIi8qL21lZGlhLyoqIjp7fSwiLyovYXBwbGljYXRpb25zLyoqIjp7fSwiLyovcHVzaC8qKiI6e30sIi8qL2tub2NraW5nLyoqIjp7fSwiLyovbGVncy8qKiI6e319fX0.BQIKKiGE5EUMzMsvYF5LqoQQG-07iYy6PH8y1nkyMij5_Emlfeu73AMaJZ3XwMT4QD6g56YLItj3tlkXPtY3tauc_qroRf3pkR4lQkHg6Q2ixoMWQg_y-IyvQ8u2PBChBXJ6cnWFUYoWgWE7YKB_FplOwL3dLG3S5meRilFUzfLb_JLCBO4F4mar4jDZaLVicyJ0A8aQL7Z6ZNzEeekuueAAGYEsUSbRYIeMED-WD2Yme9CTIU28g4rJYzKmAx9wjE8Pn91NDzmnApDjcE6zTXeer8ruecWanj0WWPDJKtYoi1d4j2gpr0OioGIfVPnGkSAMFyO9ZAS8fvEyN83UsA';

  //     client
  //       .createSession(token)
  //       .then((session) => setSession(session))
  //       .catch((error) => setError(error));
  //   }

  // }, [client]);


  return (
    <CallContext.Provider value={{
      client,
      setClient,
      session,
      setSession,
      error,
      setError
    }}>
      {children}
    </CallContext.Provider>
  );
};

export { CallContext, CallProvider };


