import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setActiveAgents, setCallLogs, setQueuedLeads } from '../../redux/twilio.slice';
import { twilioApiUrl } from '../../config/apiConfig';

const useTwilioService = () => {
    const dispatch = useDispatch();
  
    const fetchActiveAgents = async () => {
      try {
        const response = await axios.get(`${twilioApiUrl}/get-active-agents`);
        dispatch(setActiveAgents(response.data));
      } catch (error) {
        console.error('Error fetching active agents:', error);
      }
    };

    const fetchQueuedLeads = async () => {
      try {
        const response = await axios.get(`${twilioApiUrl}/get-queued-leads`);
        dispatch(setQueuedLeads(response.data));
      } catch (error) {
        console.error('Error fetching queued leads:', error);
      }
    };
  
    const fetchCallLogs = async () => {
      try {
        const response = await axios.get(`${twilioApiUrl}/get-call-logs`);
        dispatch(setCallLogs(response.data));
      } catch (error) {
        console.error('Error fetching call logs:', error);
      }
    };

    const fetchTwilioToken = async (userId) => {
      try {
        const response = await axios.post(`${twilioApiUrl}/token`, { identity: userId });
        return response.data.token;
      } catch (error) {
        console.error('Error fetching Twilio token:', error);
        throw error; 
      }
    };
  
    return {
      fetchActiveAgents,
      fetchQueuedLeads,
      fetchCallLogs,
      fetchTwilioToken,
    };
  };

export default useTwilioService;
