import PropTypes from 'prop-types';
import { styled } from '@mui/styles';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
// @mui
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Avatar, Box, Button, CircularProgress } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import { RefreshOutlined } from '@mui/icons-material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// @mui/icons-material
import { useQuery } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Portal from '@mui/material/Portal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// components
import Iconify from '../../components/iconify';
// hooks
import DialPad from '../../components/dial-pad';
import { useBoolean } from '../../hooks/use-boolean';
import { useResponsive } from '../../hooks/use-responsive';
import { formatPhoneNumber } from '../../utils/helperFunctions';
import { GET_ORGANIZATION } from '../../graphql/queries/organization';
import { useTwilioCall } from '../../context/TwilioCallContext';
import useCallTimer from '../../pages/twilio-caller/hooks/useCallTimer';
import useTwilioService from '../../services/twilio/use.twilio.service';
import { truncateText } from '../../utils/textHelper';

// ----------------------------------------------------------------------

const ZINDEX = 1998;

const POSITION = 24;

export default function CallInitiate({ onCloseCompose, defaultRecipient, handleCloseInteraction, activeLead }) {
  const smUp = useResponsive('up', 'sm');
  const messagesEndRef = useRef(null);
  const { data: organization } = useQuery(GET_ORGANIZATION);
  const [phone, setPhone] = useState('');
  const [userOrganization, setUserOrganization] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isTokenLoading, setisTokenLoading] = useState(false);

  // twilio start
  const { user } = useSelector((state) => state.auth);
  const userId = user?._id;
  const { fetchTwilioToken } = useTwilioService();
  const { startTimer, stopTimer, resetTimer, formattedTime, twilioError } = useCallTimer();
  const { handleCall, handleEndCall, callStatus, callingToken, callInstance } = useTwilioCall();

  useEffect(() => {
    if (organization && organization.getOrganization) {
      setUserOrganization(organization.getOrganization);
    }
  }, [organization]);


  const initiateCall = () => {
    const formattedPhone = formatPhoneNumber(phone);
    const leadId = activeLead?._id || null;
    const callDialType = 'MANUAL_DIAL';
    handleCall(formattedPhone, userId, leadId, callDialType);
    startTimer();
  };

  const handleEndCallFxn = () => {
    handleEndCall();
    stopTimer();
    resetTimer();
  };
  

  const fetchToken = async () => {
    if (isTokenLoading) return;

    setisTokenLoading(true);

    try {
      const token = await fetchTwilioToken(userId);
      callingToken.current = token;
      enqueueSnackbar(`Token Refreshed`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to refresh token.', { variant: 'error' });
    } finally {
      setisTokenLoading(false);
    }
  };

  const sendDMF = (number) => {};

  const handleMoveToNext = useCallback(() => {
    
  }, [handleCloseInteraction]);

  // const handleResetTranscription = () => {
  //   setChatCompletionResposes([])
  //   resetTranscription()
  // }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fullScreen = useBoolean();

  useEffect(() => {
    if (defaultRecipient) {
      setPhone(formatPhoneNumber(defaultRecipient.phone, '1'));
    }
  }, [defaultRecipient]);

  // useEffect(() => {
  //   if (defaultRecipient?.phone && phone?.length !== 0 && session) {
  //     handleCall();
  //   }
  // }, [defaultRecipient, session, phone?.length]);

  // useEffect(() => {
  //   scrollToBottom()
  // }, [chatCompletionResponses])

  return (
    <>
      <Portal>
        {(fullScreen.value || !smUp) && <Backdrop open sx={{ zIndex: ZINDEX }} />}
        <Paper
          sx={{
            top: 0,
            right: 0,
            bottom: 0,
            borderRadius: 2,
            display: 'flex',
            position: 'fixed',
            zIndex: ZINDEX + 1,
            m: `${POSITION}px`,
            overflow: 'hidden',
            flexDirection: 'column',
            width: 550,
            boxShadow: (theme) => theme.customShadows.dropdown,
            ...(fullScreen.value && {
              m: 0,
              right: POSITION / 2,
              bottom: POSITION / 2,
              width: `calc(100% - ${POSITION}px)`,
              height: `calc(100% - ${POSITION}px)`,
            }),
          }}
        >
          <Wrapper>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ p: 1 }}
              style={{ maxHeight: '' }}
            >
              <IconButton onClick={fullScreen.onToggle}>
                <Iconify icon={fullScreen ? 'eva:collapse-fill' : 'eva:expand-fill'} />
              </IconButton>
              <IconButton onClick={onCloseCompose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Stack>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                Dailer
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    bgcolor: 'background.neutral',
                    p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
                  }}
                >
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Calling {defaultRecipient?.name} from {truncateText(userOrganization?.name, 20)}
                  </Typography>
                </Stack>
                {/* {error && <Alert severity="error"><pre>{JSON.stringify(error)}</pre></Alert>} */}
                <Stack direction="column" alignItems="center" justifyContent="center" sx={{ p: 2 }} spacing={2}>
                  {!defaultRecipient && (
                    <InputBase
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      sx={{
                        px: 2,
                        height: 48,
                        borderBottom: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
                      }}
                    />
                  )}
                  <Avatar alt={defaultRecipient?.name} sx={{ width: 100, height: 100 }} src={activeLead?.companyLogo} />
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {formatPhoneNumber(phone)}
                  </Typography>

                  <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                    <DialPad sendDMF={sendDMF} />
                    {!callingToken.current && <CircularProgress size={24} />}
                    <Typography variant="h6" color="inherit" sx={{ mb: 1 }}>
                      {formattedTime}
                    </Typography>
                    <Typography variant="caption" sx={{ mb: 1 }}>
                      {callStatus}
                    </Typography>
                    {twilioError ? (
                      <Stack spacing={1}>
                        <Typography color="#282833" fontSize="16px">
                          Click on the button below 👇🏼
                        </Typography>
                        <Button
                          startIcon={<RefreshOutlined />}
                          variant="contained"
                          sx={{ backgroundColor: '#282833' }}
                          onClick={fetchToken}
                          disabled={isTokenLoading}
                        >
                          {isTokenLoading ? 'Fetching...' : 'Refresh Token'}
                        </Button>
                      </Stack>
                    ) : (
                      <Stack direction="row" spacing={2}>
                        {callInstance.current ? (
                          <Button color="error" onClick={handleEndCallFxn} size="small">
                            {' '}
                            End Call{' '}
                          </Button>
                        ) : (
                          <Button color="success" variant="contained" onClick={initiateCall}>
                            {' '}
                            Call Number{' '}
                          </Button>
                        )}
                        {/* {status !== 'RINGING' && status !== 'ANSWERED' &&
                      <Button color='primary' variant='contained' onClick={handleMoveToNext}> Call Next Prospect </Button>
                    } */}
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                Transcription Engine
              </AccordionSummary>
              <AccordionDetails>
                <Card>
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      p: 2,
                      maxHeight: '30vh',
                      overflowY: 'auto',
                    }}
                  >
                    {/* <audio controls src="https://cdn.openai.com/API/docs/audio/echo.wav" /> */}
                    {/* {micRecognizedText && <Typography variant="caption" color='inherit' sx={{ mb: 1 }}>
                    {`Mic: ${micRecognizedText}`}
                  </Typography>}
                  {recognizedText && <Typography variant="caption" color='inherit' sx={{ mb: 1 }}>
                    {`Mic & Speaker: ${recognizedText}`}
                  </Typography>}

                  {speakerOnly && <Typography variant="caption" color='inherit' sx={{ mb: 1 }}>
                    {`Speaker Only: ${speakerOnly}`}
                  </Typography>} */}

                    {/* {chatCompletionResponses?.length > 0 &&
                    <Typography variant='h6'>Ai Says</Typography>} */}
                    {/* {chatCompletionResponses?.map((response, index) => (
                    <Stack key={index} direction='column' alignItems='flex-start' justifyContent='flex-start' spacing={1}>
                      <Typography variant="caption" color='inherit' sx={{ mb: 1, fontWeight: 'bold' }}>
                        {`${response.prompt}`}
                      </Typography>
                      <Typography variant="body2" color='inherit' sx={{ mb: 1 }}>
                        {`${response.response}`}
                      </Typography>
                    </Stack>
                  ))} */}
                    <div ref={messagesEndRef} />
                  </CardContent>
                  {/* <CardActions>
                  <Button color='primary' variant='contained' onClick={() => {
                    startStreaming();
                    setRecognize(true);
                  }} disabled={recognize}> Start Streaming </Button>
                  <Button color='primary' variant='outlined' onClick={() => {
                    stopStreaming();
                    setRecognize(false);
                  }} disabled={!recognize}> Stop Streaming </Button>
                  <Button color='primary' variant='outlined' onClick={handleResetTranscription}> Reset Transcription </Button>
                </CardActions> */}
                </Card>
              </AccordionDetails>
            </Accordion>
          </Wrapper>
        </Paper>
      </Portal>
    </>
  );
}

CallInitiate.propTypes = {
  onCloseCompose: PropTypes.func.isRequired,
  defaultRecipient: PropTypes.object,
  handleCloseInteraction: PropTypes.func,
  activeLead: PropTypes.object,
  contact: PropTypes.object,
};

export const Wrapper = styled(Box)({
  width: '100%',
  height: '100%',
  overflow: 'auto',
});
