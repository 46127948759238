import React, { useEffect } from 'react';
import { Dialog, Typography, Button, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { OPT_AGENT_OUT } from '../../../graphql/queries/lead';

function OptOutConfirmationDailog({ open, handleClose, targetContact }) {
  const [optOutContact, { data, loading, error }] = useMutation(OPT_AGENT_OUT);
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    optOutContact({
      variables: {
        _id: targetContact?._id,
        reasonForOptOut: data?.reasonForOptOut,
      },
    });
  };
  useEffect(() => {
    if (data) {
      // setCompanyContacts((prev) => {
      //   const contactIndex = prev?.findIndex((contact) => contact?._id === data?.optOutContact?._id);
      //   if (contactIndex === -1) return prev;
      //   const previousContact = prev;
      //   previousContact[contactIndex] = data?.optOutContact;
      //   return previousContact;
      // });
      enqueueSnackbar('Contact successfully opted out', { variant: 'success' });
      handleClose();
    }
    if (error) {
      enqueueSnackbar('Error opting out contact', { variant: 'error' });
    }
  }, [data, error, enqueueSnackbar, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form style={{ padding: '15px' }} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" gutterBottom>
          Are you sure you want to opt out {`${targetContact?.firstName} ${targetContact?.lastName}`}?
        </Typography>
        <TextField
          rows={4}
          name="reasonForOptOut"
          id="reasonForOptOut"
          multiline
          fullWidth
          label="Reason for Opting Out"
          {...register('reasonForOptOut', { required: true })}
        />

        <Stack direction="row" alignItems="center" my={2} spacing={1}>
          <LoadingButton variant="contained" color="error" type="submit" loading={loading}>
            Opt out
          </LoadingButton>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
}

OptOutConfirmationDailog.propTypes = {
  targetContact: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setCompanyContacts: PropTypes.func,
};

OptOutConfirmationDailog.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default OptOutConfirmationDailog;
