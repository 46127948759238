import { gql } from '@apollo/client';

export const GET_TRAINING = gql`
    query{
        getModelTraining{
        _id
        objection
        preContext
        response
        }
    }
`;

export const GET_TRAINING_PAGINATED = gql`
  query getModelTrainingPagination($limit: Float!,$skip: Float!){
    getModelTrainingPagination(limit:$limit, skip:$skip){
      _id
      objection
      preContext
      response
    }
  }
`;

export const GET_TRAINING_SEARCH = gql`
  query searchModelTraining($search: String!){
    searchModelTraining(search:$search){
      _id
      objection
      preContext
      response
    }
  }
  `;

export const GET_TRAINING_ANALYTICS = gql`
  query {
    getModelTrainingAnalytics{
      today
      week
      month
    }
  }
`;

export const GET_TRAINING_MODEL_MULTIPLE = gql`
    query{
        getModelTrainingMultiple{
        _id
        objection
        preContext
        response
        }
    }
`;

export const GET_UPLOAD_TRAINING = gql`
    query{
        uploadFineTunedData
    }
`;

export const GET_TRAINING_JOBS = gql`
  query {
    listFineTuningJobs{
      status
      fine_tuned_model
      model
      created_at
      finished_at
      trained_tokens
      object
      training_file
    }
   }`;

export const GET_USER_ORGANIZATION = gql`
  query {
    getOrganization{
      status
      model
      name
      numberOfUsers
      campaign
    }
  }
`;