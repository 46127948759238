import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Link, Stack, TextField } from '@mui/material';
// components
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Iconify from '../../../components/iconify';
import { LOGIN } from '../../../graphql/mutations/auth';
import { setUser } from '../../../redux/auth.slice';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [login, { data, loading, error }] = useMutation(LOGIN);

  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    login({
      variables: { input: data },
    });
  };

  useEffect(() => {
    if (data && data.login) {
      dispatch(setUser(data.login));
      navigate('/sequence', { replace: true });
    }
  }, [data, dispatch, navigate]);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
       {error && <Alert severity="error">{error?.message}</Alert>}
        <TextField name="email" label="Email address" {...register('email', { required: true })} />
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password', { required: true })}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" onClick={()=>navigate('/forgot-password')}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained">
        Login
      </LoadingButton>
    </form>
  );
}
