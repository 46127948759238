import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
// components
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Iconify from '../../../components/iconify';
import { CHANGE_PASSWORD } from '../../../graphql/mutations/auth';

// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [resetPassword, { data, loading, error }] = useMutation(CHANGE_PASSWORD);

  const { handleSubmit, register } = useForm();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const id = urlParams.get('id');
  const {enqueueSnackbar} = useSnackbar();

  const onSubmit = (data) => {
    resetPassword({
      variables: { input: { ...data, token, userId: id } },
    });
  };

  useEffect(() => {
    if (data && data.resetPassword) {
      enqueueSnackbar('Password reset successfully', {variant: 'success'});
      navigate('/login', { replace: true });
    }
  }, [data, dispatch, navigate, enqueueSnackbar]);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ my: 2 }}>
        {error && <Alert severity="error">{error?.message}</Alert>}
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password', { required: true })}
        />

        <TextField
          name="password2"
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password2', { required: true })}
        />
      </Stack>

      <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained">
        Reset Password
      </LoadingButton>
    </form>
  );
}
