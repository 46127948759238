import { Helmet } from 'react-helmet-async';
// @mui
import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import * as React from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// sections
import { useLazyQuery, useQuery } from '@apollo/client';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../components/iconify';
import { GET_AGENT_PERFORMANCE_SUMMARY, GET_TOTAL_LOG_COUNT_FOR_ALL_AGENTS_T } from '../graphql/queries/log';
import { setActiveDateSelection, setDateInterval } from '../redux/service.slice';
import LeaderboardTable from '../sections/@dashboard/leaderboard/LeaderboardTable';
import { LABELS } from '../utils/constants';
import { getToday, isAdmin, removeDays } from '../utils/helperFunctions';

export default function LeaderboardPage() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const today = new Date();
  const twentyHoursAgo = new Date(today.getTime() - 24 * 60 * 60 * 1000);
  const [value, setValue] = React.useState([dayjs(twentyHoursAgo), dayjs(today)]);
  const { user } = useSelector((state) => state.auth);
  const { startDate, endDate } = getToday();

  const { data: getBestPerformingAgentsByDateRange, loading: loadingGetBestPerformingAgentsByDateRange } =
    useQuery(GET_AGENT_PERFORMANCE_SUMMARY);
  const [getAgentLogCountByDateRange, { data, loading }] = useLazyQuery(GET_TOTAL_LOG_COUNT_FOR_ALL_AGENTS_T);
  const { dateInterval, activeDateSelection } = useSelector((state) => state.lead);
  const isStepActive = (step) => activeDateSelection === step;
  
  console.log("getBestPerformingAgentsByDateRange....", getBestPerformingAgentsByDateRange);
  
  const handleClickToday = React.useCallback(() => {
    dispatch(setActiveDateSelection('today'));
    dispatch(setDateInterval({ from: startDate, to: endDate }));
  }, [dispatch, startDate, endDate]);

  const handleClickThisSprint = () => {
    dispatch(setActiveDateSelection('thisSprint'));
    dispatch(setDateInterval({ from: removeDays(new Date(), 14), to: new Date() }));
  };

  const handleClickThisMonth = () => {
    dispatch(setActiveDateSelection('thisMonth'));
    dispatch(setDateInterval({ from: removeDays(new Date(), 30), to: new Date() }));
  };

  const handleClickPreviousMonth = () => {
    dispatch(setActiveDateSelection('previousMonth'));
    dispatch(setDateInterval({ from: removeDays(new Date(), 60), to: removeDays(new Date(), 30) }));
  };

  const handleClickSixMonths = () => {
    dispatch(setActiveDateSelection('sixMonths'));
    dispatch(setDateInterval({ from: removeDays(new Date(), 180), to: new Date() }));
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };


  useEffect(() => {
    handleClickToday();
  }, [handleClickToday]);

  useEffect(() => {
    if (dateInterval?.from && dateInterval?.to)
      getAgentLogCountByDateRange({ variables: { from: dateInterval.from, to: dateInterval.to } });
  }, [dateInterval?.from, dateInterval?.to, getAgentLogCountByDateRange]);


  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Sequence
    </Link>,
    <Typography key="3" color="text.primary">
      Leaderboard
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title> Leaderboard | Auto Caller UI </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          {loadingGetBestPerformingAgentsByDateRange ? (
            <Grid item md={12}>
              <CircularProgress />
            </Grid>
          ) : (isAdmin(user?.role) && getBestPerformingAgentsByDateRange) &&
          <>
            <Grid item xs={12} sm={12} md={12}>
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="h3">{LABELS.Leaderboard}</Typography>
                <Typography variant="caption">{LABELS.LeaderboardDescription}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="caption">Best Performer of Sprint</Typography>
                <Typography variant="h5">
                  {getBestPerformingAgentsByDateRange?.getBestPerformingAgentsByDateRange?.bestPerformerOfSprint}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="caption">Best Performer of Month</Typography>
                <Typography variant="h5">
                  {getBestPerformingAgentsByDateRange?.getBestPerformingAgentsByDateRange?.bestPerformerOfMonth}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="caption">Best Performer of Quarter Year</Typography>
                <Typography variant="h5">
                  {getBestPerformingAgentsByDateRange?.getBestPerformingAgentsByDateRange?.bestPerformerOfQuarter}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="caption">Total Sales Representative</Typography>
                <Typography variant="h5">
                  {getBestPerformingAgentsByDateRange?.getBestPerformingAgentsByDateRange?.totalAgentCount}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
          </>
          }
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" spacing={0} alignItems="flex-start" justifyContent="space-between">
              <ButtonGroup variant="outlined" aria-label="outlined button group" color="inherit">
                <Button sx={{ color: isStepActive('today') ? 'blue' : 'inherit' }} onClick={handleClickToday}>
                  Today
                </Button>
                <Button sx={{ color: isStepActive('thisSprint') ? 'blue' : 'inherit' }} onClick={handleClickThisSprint}>
                  This Sprint
                </Button>
                <Button sx={{ color: isStepActive('thisMonth') ? 'blue' : 'inherit' }} onClick={handleClickThisMonth}>
                  This Month
                </Button>
                <Button
                  sx={{ color: isStepActive('previousMonth') ? 'blue' : 'inherit' }}
                  onClick={handleClickPreviousMonth}
                >
                  Previous Month
                </Button>
                <Button sx={{ color: isStepActive('sixMonths') ? 'blue' : 'inherit' }} onClick={handleClickSixMonths}>
                  6 Months
                </Button>
              </ButtonGroup>
              <Stack direction="row" spacing={2}>
                <Button
                  startIcon={<img src="/assets/icons/ic_calendar_icon.svg" alt="date" width={20} />}
                  variant="outlined"
                  color="inherit"
                  onClick={handleOpenMenu}
                >
                  Select date
                </Button>
                <Button
                  startIcon={<img src="/assets/icons/ic_filter_icon.svg" alt="filter" width={20} />}
                  variant="outlined"
                  color="inherit"
                >
                  Filters
                </Button>
              </Stack>
            </Stack>
          </Grid>
          {loading ? (
            <Grid item md={12}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {data?.getAgentLogCountByDateRangeT?.callRecordings?.map((dt, index) => dt?._id &&
                <Grid item xs={12} sm={12} md={2} key={index}>
                  <Stack direction="column" spacing={0} alignItems="flex-start">
                    <Typography variant="caption">{dt?._id?.toUpperCase()}</Typography>
                    <Typography variant="h5">{dt?.count}</Typography>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <Divider />
              </Grid>
              <LeaderboardTable data={data?.getAgentLogCountByDateRangeT?.agentLogCountByDateRange ? data?.getAgentLogCountByDateRangeT?.agentLogCountByDateRange : []} />
            </>)}
        </Grid>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 'auto',
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              calendars={1}
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
                dispatch(setDateInterval({ from: newValue[0], to: newValue[1] }));
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <DemoContainer sx={{ my: 2 }}>
                    <DemoItem>
                      <Iconify icon="bx:bxs-calendar" width={20} height={20} />
                    </DemoItem>
                    <DemoItem>
                      <input
                        ref={startProps.inputRef}
                        {...startProps.inputProps}
                        placeholder="From"
                        style={{ width: 100 }}
                      />
                    </DemoItem>
                    <DemoItem>
                      <Iconify icon="bx:bxs-right-arrow" width={20} height={20} />
                    </DemoItem>
                    <DemoItem>
                      <input ref={endProps.inputRef} {...endProps.inputProps} placeholder="To" style={{ width: 100 }} />
                    </DemoItem>
                  </DemoContainer>
                </>
              )}
            />
          </LocalizationProvider>
        </Popover>
      </Container>
    </>
  );
}
