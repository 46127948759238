import { gql } from '@apollo/client';

export const GET_ALL_PITCH = gql`
query{
    getPitch{
      _id
      title
      createdAt
      jobTitle
      notes
      sms
      subject
    }
  }
`;

export const CREATE_PITCH = gql`
  mutation createPitch($input: PitchInput!) {
    createPitch(input: $input) {
      _id
      title
      createdAt
      jobTitle
      notes
      sms
      subject
    }
  }
`;

export const GET_PITCH_BY_JOB_TITLE = gql`
  query getPitchByJobTitle($jobTitle:String!){
    getPitchByJobTitle(jobTitle:$jobTitle){
      _id
      title
      createdAt
      jobTitle
      sms
      notes
    }
  }
`;