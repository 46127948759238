import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Stack,
  Button,
  ButtonGroup,
  Popover,
  CircularProgress,
} from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getDateRange, getTodayUTC } from '../utils/helperFunctions';
import { setActiveDateSelection, setDateInterval } from '../redux/analytics.slice';
import Iconify from '../components/iconify';
import CallWidgetSummary from '../sections/@dashboard/call-analytics/CallWidgetSummary';
import AnalyticsMostConnections from '../sections/@dashboard/call-analytics/AnalyticsMostConnections';
import AnalyticsAgentWithMostConnection from '../sections/@dashboard/call-analytics/AnalyticsAgentWithMostConnections';
import ConnectedCallsList from '../sections/@dashboard/call-analytics/ConnectedCallsList';
import useCallSummaryStats from '../hooks/call-analytics/use-call-summary-stats';
import useAgentsWithMostConnections from '../hooks/call-analytics/use-agents-with-most-connection-stats';
import useConnectedCallLogs from '../hooks/call-analytics/use-connected-calllogs';

export default function CallAnalyticsPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const today = new Date();
  const twentyHoursAgo = new Date(today.getTime() - 24 * 60 * 60 * 1000);
  const [value, setValue] = React.useState([dayjs(twentyHoursAgo), dayjs(today)]);
  const isStepActive = (step) => activeDateSelection === step;
  const { startDate, endDate } = getTodayUTC();
  const { dateInterval, activeDateSelection } = useSelector((state) => state.analytics);

  // QUERY
  const { data: summaryData, loading: summaryLoading } = useCallSummaryStats(dateInterval);
  const { data: agentsConnStats, loading: agentsConnStatsLoading } = useAgentsWithMostConnections(dateInterval);
  const {
    data: connectedCallLogs,
    loading: connectedCallLogsLoading,
    getConnectedCallLogs,
  } = useConnectedCallLogs(dateInterval);

  const totalCallStatus =
    summaryData?.totalSuccessfulCalls > 1000 ? 'success' : summaryData?.totalSuccessfulCalls > 600 ? 'average' : 'poor';

  const totalConnectedCallsStatus =
    summaryData?.totalConnectedCallsByAmd > 50
      ? 'success'
      : summaryData?.totalConnectedCallsByAmd > 20
      ? 'average'
      : 'poor';

  const totalVoiceMailCallsStatus =
    summaryData?.totalVoiceMailCalls > 500 ? 'poor' : summaryData?.totalVoiceMailCalls > 200 ? 'average' : 'success';

    useEffect(() => {
      handleClickToday();
    }, [])
    

  const handleClickToday = React.useCallback(() => {
    dispatch(setActiveDateSelection('today'));
    const { startDate, endDate } = getDateRange('today');
    dispatch(setDateInterval({ from: startDate, to: endDate }));
  }, [dispatch, startDate, endDate]);

  const handleClickYesterday = React.useCallback(() => {
    dispatch(setActiveDateSelection('yesterday'));
    const { startDate, endDate } = getDateRange('yesterday');
    dispatch(setDateInterval({ from: startDate, to: endDate }));
  }, [dispatch, startDate, endDate]);

  const handleClickThisWeek = () => {
    dispatch(setActiveDateSelection('thisWeek'));
    const { startDate, endDate } = getDateRange('thisWeek');
    dispatch(setDateInterval({ from: startDate, to: endDate }));
  };

  const handleClickLastWeek = () => {
    dispatch(setActiveDateSelection('lastWeek'));
    const { startDate, endDate } = getDateRange('lastWeek');
    dispatch(setDateInterval({ from: startDate, to: endDate }));
  };

  const handleClickThisMonth = () => {
    dispatch(setActiveDateSelection('thisMonth'));
    const { startDate, endDate } = getDateRange('thisMonth');
    dispatch(setDateInterval({ from: startDate, to: endDate }));
  };

  const handleClickPreviousMonth = () => {
    dispatch(setActiveDateSelection('previousMonth'));
    const { startDate, endDate } = getDateRange('previousMonth');
    dispatch(setDateInterval({ from: startDate, to: endDate }));
  };

  const handleClickSixMonths = () => {
    dispatch(setActiveDateSelection('sixMonths'));
    const { startDate, endDate } = getDateRange('sixMonths');
    dispatch(setDateInterval({ from: startDate, to: endDate }));
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Sequence
    </Link>,
    <Typography key="3" color="text.primary">
      Call Analytics
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title> Call Analytics </title>
      </Helmet>

      <Container maxWidth={'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" spacing={0} alignItems="flex-start" justifyContent="space-between">
              <ButtonGroup variant="outlined" aria-label="outlined button group" color="inherit">
                <Button sx={{ color: isStepActive('today') ? 'blue' : 'inherit' }} onClick={handleClickToday}>
                  Today
                </Button>
                <Button sx={{ color: isStepActive('yesterday') ? 'blue' : 'inherit' }} onClick={handleClickYesterday}>
                  Yesterday
                </Button>
                <Button sx={{ color: isStepActive('thisWeek') ? 'blue' : 'inherit' }} onClick={handleClickThisWeek}>
                  This Week
                </Button>
                <Button sx={{ color: isStepActive('lastWeek') ? 'blue' : 'inherit' }} onClick={handleClickLastWeek}>
                  Last Week
                </Button>
                <Button sx={{ color: isStepActive('thisMonth') ? 'blue' : 'inherit' }} onClick={handleClickThisMonth}>
                  This Month
                </Button>
                <Button
                  sx={{ color: isStepActive('previousMonth') ? 'blue' : 'inherit' }}
                  onClick={handleClickPreviousMonth}
                >
                  Previous Month
                </Button>
                <Button sx={{ color: isStepActive('sixMonths') ? 'blue' : 'inherit' }} onClick={handleClickSixMonths}>
                  6 Months
                </Button>
              </ButtonGroup>
              <Stack direction="row" spacing={2}>
                <Button
                  startIcon={<img src="/assets/icons/ic_calendar_icon.svg" alt="date" width={20} />}
                  variant="outlined"
                  color="inherit"
                  onClick={handleOpenMenu}
                >
                  Select date
                </Button>
                <Button
                  startIcon={<img src="/assets/icons/ic_filter_icon.svg" alt="filter" width={20} />}
                  variant="outlined"
                  color="inherit"
                >
                  Filters
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} md={4}>
            <CallWidgetSummary
              title="Total Successful Calls"
              percent={2.6}
              total={summaryData?.totalSuccessfulCalls || 0}
              loading={summaryLoading}
              status={totalCallStatus}
              chartColor={theme.palette.primary.main}
              chartData={[5, 18, 12, 51, 68, 11, 39, 37, 27, 20]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CallWidgetSummary
              title="Total Connected Calls"
              percent={0.2}
              total={summaryData?.totalConnectedCallsByAmd || 0}
              loading={summaryLoading}
              status={totalConnectedCallsStatus}
              chartColor={'#4FA8FF'}
              chartData={[20, 41, 63, 33, 28, 35, 50, 46, 11, 26]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CallWidgetSummary
              title="Total Voice Mail Calls"
              percent={-0.1}
              total={summaryData?.totalVoiceMailCalls || 0}
              loading={summaryLoading}
              status={totalVoiceMailCallsStatus}
              chartColor={'#FEA989'}
              chartData={[8, 9, 31, 8, 16, 37, 8, 33, 46, 31]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AnalyticsMostConnections />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {agentsConnStatsLoading ? (
              <CircularProgress />
            ) : (
              <AnalyticsAgentWithMostConnection agentsConnStats={agentsConnStats} />
            )}
          </Grid>

          <Grid item xs={12} lg={12}>
            <ConnectedCallsList
              connectedCallLogs={connectedCallLogs}
              loading={connectedCallLogsLoading}
              getConnectedCallLogs={getConnectedCallLogs}
              dateInterval={dateInterval}
            />
          </Grid>

        </Grid>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 'auto',
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              calendars={1}
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
                dispatch(setDateInterval({ from: newValue[0], to: newValue[1] }));
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <DemoContainer sx={{ my: 2 }}>
                    <DemoItem>
                      <Iconify icon="bx:bxs-calendar" width={20} height={20} />
                    </DemoItem>
                    <DemoItem>
                      <input
                        ref={startProps.inputRef}
                        {...startProps.inputProps}
                        placeholder="From"
                        style={{ width: 100 }}
                      />
                    </DemoItem>
                    <DemoItem>
                      <Iconify icon="bx:bxs-right-arrow" width={20} height={20} />
                    </DemoItem>
                    <DemoItem>
                      <input ref={endProps.inputRef} {...endProps.inputProps} placeholder="To" style={{ width: 100 }} />
                    </DemoItem>
                  </DemoContainer>
                </>
              )}
            />
          </LocalizationProvider>
        </Popover>
      </Container>
    </>
  );
}
