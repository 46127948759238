import React from 'react';
import Lottie from 'react-lottie';
import { Box } from '@mui/material';
import animationData from '../../assets/lottie/hand-loading.json';

const LottieLoadingLoader = ({h, w}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    return (
        <Box
            sx={{
                width: w || 50,
                height: h || 50,
                maxWidth: w || 50,
                maxHeight: h || 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Lottie options={defaultOptions} height="100%" width="100%" />
        </Box>
    );
};

export default LottieLoadingLoader;
