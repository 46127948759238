import io from 'socket.io-client';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  styled,
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';
import CallToAction from '@mui/icons-material/CallToAction';
import { useDispatch, useSelector } from 'react-redux';
import useTwilioService from '../../services/twilio/use.twilio.service';
import useTwilio from './hooks/useTwilio';
import {
  addActiveAgent,
  addLeadToQueue,
  addToConnectedCall,
  removeActiveAgent,
  removeExpiredConnectedCalls,
  removeLeadFromQueue,
  setActiveAgents,
  setQueuedLeads,
} from '../../redux/twilio.slice';
import ConnectedCalls from './components/ConnectedCalls';

const CallPoolPlayground = () => {
  const connectionURL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';
  const socket = useRef(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const agentId = user?.twilioAgent?._id;
  const { activeAgents, queuedLeads, connectedCalls } = useSelector((state) => state.twilio);
  const { device, callStatus, setCallStatus, incomingCall, acceptedCall, setAcceptedCall, setIncomingCall } =
    useTwilio(agentId);

    const isAgentInCallPool = (agentId, activeAgents) => {
      return Array.isArray(activeAgents) && activeAgents.some((agent) => agent?._id === agentId);
    };
  const [isInCallPool, setIsInCallPool] = useState(() => isAgentInCallPool(agentId, activeAgents));
  const [callSid, setCallSid] = useState(null);
  const [lead, setLead] = useState(null);
  const { fetchActiveAgents, fetchQueuedLeads } = useTwilioService();
  const [loadingActiveAgents, setLoadingActiveAgents] = useState(true);
  const [loadingQueuedLeads, setLoadingQueuedLeads] = useState(true);

  useEffect(() => {
    if (connectionURL) {
      socket.current = io(connectionURL, { path: '/socket.io', transports: ['websocket', 'polling'] });

      socket.current.on('connect', () => {
        console.log('Connected to socket');
      });

      socket.current.on('disconnect', () => {
        console.log('Disconnected from socket');
      });

      socket.current.on('setCallSid', (data) => {
        console.log('CallId:', data.callSid);
        setCallSid(data.callSid);
      });

      socket.current.on('incomingCall', (data) => {
        console.log('Incoming call:', data);
        setLead(data.lead);
      });

      socket.current.on('activeAgentsUpdate', (agent) => {
        console.log("ActiveAgentUpdate....", agent);
        dispatch(addActiveAgent(agent));
      });

      socket.current.on('queueLead', (lead) => {
        dispatch(addLeadToQueue(lead));
      });

      socket.current.on('callConnected', (data) => {
        console.log("Call__Connected....", data);
        dispatch(addToConnectedCall(data));
      });

      socket.current.on('removeLeadFromQueue', (leadId) => {
        dispatch(removeLeadFromQueue(leadId));
      });

      return () => {
        socket.current.disconnect();
      };
    }

    return () => {};
  }, [connectionURL, device, agentId]);

    useEffect(() => {
      const interval = setInterval(() => {
        dispatch(removeExpiredConnectedCalls());
      }, 5000); 
  
      return () => clearInterval(interval);
    }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching......");
      try {
        await fetchActiveAgents();
      } catch (error) {
        console.error('Error fetching active agents:', error);
      } finally {
        setLoadingActiveAgents(false);
      }

      try {
        await fetchQueuedLeads();
      } catch (error) {
        console.error('Error fetching queued leads:', error);
      } finally {
        setLoadingQueuedLeads(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (activeAgents) {
      setActiveAgents(activeAgents);
    }
  }, [activeAgents]);

  useEffect(() => {
    if (queuedLeads) {
      setQueuedLeads(queuedLeads);
    }
  }, [queuedLeads]);

  const joinCallPool = () => {
    if (socket.current) {
      socket.current.emit('joinChatPool', agentId);
      setIsInCallPool(true);
    }
  };

  const leaveCallPool = () => {
    if (socket.current) {
      socket.current.emit('leaveChatPool', agentId);
      setIsInCallPool(false);
    }
    dispatch(removeActiveAgent(agentId));
  };

  const handleAcceptIncomingCall = () => {
    if (incomingCall) {
      const callSid_ = incomingCall.parameters.CallSid;
      console.log('Call SID[incomingCall]:', incomingCall);
      incomingCall.accept();
      setAcceptedCall(incomingCall);
      setCallStatus('Incoming call accepted');
      setIncomingCall(null);
      socket.current.emit('callAccepted', { callSid: callSid_, agentId, leadId: lead?._id });
    }
  };

  const handleRejectIncomingCall = () => {
    if (incomingCall) {
      const callSid_ = incomingCall.parameters.CallSid;
      incomingCall.reject();
      setCallStatus('Incoming call rejected');
      setIncomingCall(null);
      socket.current.emit('callRejected', { callSid: callSid_, agentId, leadId: lead?._id });
    }
  };

  const handleEndCall = () => {
    if (acceptedCall) {
      acceptedCall.disconnect();
      setCallStatus('Call ended');
      setAcceptedCall(null);
    }
  };

  const handleViewLogs = (event) => {
    event.preventDefault();
    navigate('/sequence/call-pool-logs');
  };

  return (
    <div>
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        CallPool Playground
      </Typography>
      <FlexRow>
      <Controls>
        <Button
          sx={{ mb: 2 }}
          variant="contained"
          color={isInCallPool ? 'error' : 'primary'}
          startIcon={<CallToAction />}
          onClick={isInCallPool ? leaveCallPool : joinCallPool}
        >
          {isInCallPool ? 'Leave Call Pool' : 'Join Call Pool'}
        </Button>
        {acceptedCall && (
          <Button onClick={handleEndCall} variant="contained" color="error">
            End Call
          </Button>
        )}
      </Controls>
      {connectedCalls?.length > 0 ?  <ConnectedCalls connectedCalls={connectedCalls} /> : null}
      <Button variant="contained" color="secondary" onClick={handleViewLogs}>
         View Call Logs
        </Button>
      </FlexRow>
      <Divider sx={{ my: 3, backgroundColor: '#eee' }} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Section>
            <Typography variant="h6" component="div" sx={{ mb: 2 }}>
              Agents in Pool (Total: {activeAgents?.length})
            </Typography>
            <GridContainer>
              {loadingActiveAgents ? (
                <Typography>Loading...</Typography>
              ) : (
                activeAgents?.map((agent, index) => (
                  <GridItem key={index}>
                    <Card>
                      <CardContent style={{ padding: '10px' }}>
                        <Box display="flex" alignItems="flex-start" gap={2}>
                          <CustomAvatar
                            sx={{
                              bgcolor: agent?.callPollingState?.toLowerCase() === 'active' ? 'green' : 'red',
                            }}
                          >
                            <PersonIcon />
                          </CustomAvatar>
                          <Box>
                            <Typography variant="h6">{agent?.name}</Typography>
                            <Typography variant="body2">
                              Status: <b>{agent?.callPollingState}</b>
                            </Typography>
                            {agent.callPollingState.toLowerCase() === 'busy' && (
                             <Column>
                              <Typography variant="body2">
                                Connected To: <b>{agent?.inCallWith?.name}</b>, - 
                              </Typography>
                              <Typography variant="body2">
                                Company: <b>{agent?.inCallWith?.companyName}</b>
                              </Typography>
                             </Column>
                            )}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </GridItem>
                ))
              )}
            </GridContainer>
          </Section>
        </Grid>
        <Grid item xs={12} md={6}>
          <Section>
            <Typography variant="h6" component="div" sx={{ mb: 2 }}>
              Clients in Queue (Total: {queuedLeads?.length})
            </Typography>
            <GridContainer>
              {loadingQueuedLeads ? (
                <Typography>Loading...</Typography>
              ) : (
                queuedLeads?.map((client, index) => (
                  <GridItem key={index}>
                    <Card>
                      <CardContent>
                        <Box display="flex" alignItems="flex-start" gap={2}>
                          <CustomAvatar sx={{ bgcolor: '#103996' }}>
                            <CallIcon sx={{ height: 20, width: 20 }} />
                          </CustomAvatar>
                          <Box>
                            <Typography variant="h6">{client?.lead?.name || client?.name}</Typography>
                            <Typography variant="body2">Company: {client?.lead?.companyName || client?.companyName}</Typography>
                            <Typography variant="body2">Phone: {client?.lead?.phoneNumber || client?.phoneNumber}</Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </GridItem>
                ))
              )}
            </GridContainer>
          </Section>
        </Grid>
      </Grid>
      {/* Dialog for incoming call */}
      <Dialog open={!!incomingCall}>
        <DialogTitle>Incoming Call</DialogTitle>
        <DialogContent>
          {incomingCall && (
            <Typography>
              {lead?.name || 'Unknown'} from {lead?.companyName} is calling.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAcceptIncomingCall} color="primary">
            Accept
          </Button>
          <Button onClick={handleRejectIncomingCall} color="secondary">
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CallPoolPlayground;


const FlexRow = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
}));

const Controls = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '10px',
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: '1px dashed black',
}));

const GridItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  width: '30px',
  height: '30px',
}));

const Column = styled('div')(({ theme }) => ({
  border: '0px solid red',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));